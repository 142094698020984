"use strict";



//tracking data for optimize Web Vitals



(function(){

	var pageFilter = getBreakpoint()=="xs" && !(ntv.urlParams.service=="webview");
	var envFilter = navigator.userAgent.indexOf("Chrome")>-1 && typeof PerformanceObserver==='function' && typeof LayoutShift==='function';
	var testVitals = ntv.urlParams.testVitals?true:false;

	// deactivate permanent measurement
	// pageFilter = false;

	if( envFilter && pageFilter || testVitals){

		var getLsInitiator = function(entry){
			
			
			
			var $elem;
			var $prevElem;
			var $parElem;
			var specElem = "null";
			var specPrevElem = "null";
			var specParElem = "null";
			var spec = "";

			
			
			var getSpecElem = function($el){
				
				
		  	  var sp = $el.prop("nodeName");
			  
				if($el.attr("id")){
					sp += "#" + $el.attr("id").trim();
				}
				if($el.attr("class")){
					sp += "." + $el.attr("class").trim().replace(/ /g, ".");
				}
				
				return sp;
				
			}
			
		
			  var node = entry.sources[0].node;
			
			  if(node.nodeType==1){
				  
				  
		    	  $elem = $(node);
		    	  $prevElem = $elem.prev();
		    	  $parElem = $elem.parent();
		    	  
		    	  specElem = getSpecElem($elem);
		    	  specPrevElem = $prevElem.length>0?getSpecElem($prevElem):"null";
		    	  specParElem = $parElem.length>0?getSpecElem($parElem):"null";
		    	  
		    	  
		    	  // smartclip
		    	  if( spec=="" && $prevElem.length>0 && $prevElem.attr("id")=="sc_intxt" ){
		    		  spec = "smartclip";
		    	  }
		    	  
		
		    	  // topBannerAdDefend
		    	  if( spec=="" && $elem.hasClass("ems-slot--mobile-top") && $elem.hasClass("aobj0") ){
		    		  spec = "topBannerAdDefend";
		    	  }
		    	  
	    		  if( spec=="" && $prevElem.length>0 && $elem.hasClass("article__wrapper") && $prevElem.hasClass("title--dark") && $(".aobj0").length>0 ){
	    			  spec = "topBannerAdDefend";
	    		  }
	    		  
	    		  // neu
	    		  // DIV.topbanner-mobile@@DIV.row==0.19 => ohne scrollen
	    		  // null@@DIV.nFKPEI==0.24
	    		  
		    	  if( spec=="" && $elem.hasClass("topbanner-mobile") && $(".aobj0", $elem).length>0 ){
		    		  spec = "topBannerAdDefend";
		    	  }
		    	  
		    	  
		    	  // topBanner + topBannerAdDefend
		    	  var $elemTmp =  $elem.parents(".topbanner-mobile");
		    	  if( spec=="" && $elemTmp.length>0 ){
		    		  if($(".aobj0", $elemTmp).length>0){
		    			  spec = "topBannerAdDefend";
		    		  }else{
		    			  if($elemTmp.hasClass("topbanner-mobile--locked")){
		    				  spec = "topBannerLocked";
		    			  }else{
		    				  spec = "topBanner";
		    			  }
		    		  }
		    		  
		    		  
		    	  }
	    		  
	    		  

	    		  
	    		  
	    		  // topBanner
	    		  if( spec=="" && $elem.parents(".ems-slot--mobile-top").length>0 ){
	    			  var $parentTopBanner = $elem.parents(".ems-slot--mobile-top");
	    			  if($parentTopBanner.hasClass("ems-slot--locked")){
	    				  spec = "topBannerLocked";
	    			  }else{
	    				  spec = "topBanner";
	    			  }
	    		  }
	    		  
	    		  if( spec=="" && $prevElem.length>0 && $prevElem.hasClass("ems-slot--mobile-top") ){
	    			  
	    			  if($prevElem.hasClass("ems-slot--locked")){
	    				  spec = "topBannerLocked";
	    			  }else{
	    				  spec = "topBanner";
	    			  }
	    		  }
	    		  
	    		  
	    		  // neu
	    		  // DIV.topbanner-mobile.topbanner-mobile--loaded@@DIV.row==0.23 =>  ohne scrollen
	    		  // null@@DIV.ems-slot.ems-slot--mobile.ems-slot--mob==0.28 => mit scrollen
	    		  if( spec=="" && $prevElem.length>0 && $prevElem.hasClass("topbanner-mobile") ){
	    			  
	    			  if($prevElem.hasClass("topbanner-mobile--locked")){
	    				  spec = "topBannerLocked";
	    			  }else{
	    				  spec = "topBanner";
	    			  }
	    		  }
	    		  

	    		  
	    		  
		    	  
		    	  
	    		  // siteBarAd
	    		  if( spec=="" && $elem.hasClass("header__nav__action") && $("#emsSitebarDiv").length>0 ){
	    			  spec = "siteBarAd";
	    		  }
		    	  
		    	  
	    		  // outbrain
		    	  // Annahme: ´Verschiebungen am Seitenende fast immer durch Outbrain, da ems/adDefend zu dem Zeitpunkt meist geladen sein sollte
		    	  // => validieren
		    	  
	    		  if( spec=="" && $elem.hasClass("ems-slot--teaser") ){
	    			  spec = "outbrain";
	    		  }
		    	  
		    	  if( spec=="" && $elem.hasClass("ems-slot") && $("#mobile_10", $elem).length>0 ){
		    		  spec = "outbrain";
		    	  }
		    	  
		    	  if( spec=="" && $elem.hasClass("ems-slot") && $("#teaser_11", $elem).length>0 ){
		    		  spec = "outbrain";
		    	  }
		    	  
	    		  if( spec=="" && $elem.hasClass("footer") ){
	    			  spec = "outbrain";
	    		  }

	    		  if( spec=="" && $elem.parents(".ob_embed").length>0 ){
	    			  spec = "outbrain";
	    		  }
	    		  
	    		  
	    		  // sticky Videoplayer
	    		  if( spec=="" && $elem.parents(".sticky-video-wrapper").length>0 ){
	    			  spec = "stickyVideoplayer";
	    		  }

	    		  
	    		  // articleEmbed
	    		  if( spec=="" && $parElem.length>0 && $parElem.hasClass("article__text") ){
	    			  spec = "articleEmbed";
	    		  }
	    		  // DIV.row@@ARTICLE.article (überwiegend embed, möglicherweise auch smartclip etc.
	    		  if( spec=="" && $prevElem.length>0 && $prevElem.hasClass("article") ){
	    			  spec = "articleEmbed";
	    		  }
	    	  
		    	  
		
		   	  
			  }
			  
			  
			  if(entry.value>1){
				  
				  spec = "invalid@@" + spec + "::" + specElem + "::" + specPrevElem + "::" + specParElem;
				  
			  }else{
			  
				  if(spec==""){
					  spec = specElem + "@@" + specPrevElem + "::" + specParElem;
				  }else{
					  spec += "@@" + specElem + "::" + specPrevElem + "::" + specParElem;
				  }
			  }
				
			  spec +=  "::" + getSpecElem($("body"));
			  
			  if( $(".aobj0").length>0 ){
				  spec +=  "::adDefend";
			  }
			  
			  spec +=  "==" + Math.round(entry.value*100)/100;
			  
			  
			  return spec;
			
		};



	
		try {
			var clsObserver = new PerformanceObserver(function handleCLS(entryList) {
			    var entries = entryList.getEntries() || [];
			    var node = null;
			    var previousSibling = null;
			    var spec = "";
			    var specNode = "";
			    var specSibling = "";
	
	
			    entries.forEach(function(entry) {
			    	node == null;
			    	previousSibling = null;
			    	specNode = "null";
			    	specSibling = "null";
			    	
	
			    	
			        if (!entry.hadRecentInput && entry.value>0.005) {
			    	  
			    	  node = entry.sources[0].node;
			    	  
			    	  if(node){
				    	  
			    		  spec = getLsInitiator(entry);
			    		  
					    	ntvDebug("*** CLS ***");
					        ntvDebug(entry);
					        ntvDebug(spec);
	
					    	if( !testVitals ) {
								/*
								gaEvent({
									  eventCategory: 'cls',
									  eventAction: spec,
									  eventLabel: window.location.href,
									  eventValue: Math.round(entry.value*100),
									  nonInteraction: true
								});
								*/
					    	}
			    		  
			    	  }
			    	  
			    	
			      }
			    });
			}).observe({ type: "layout-shift", buffered: true });
			
	
			var initGA = function(){
				/*
				gaEvent({
					  eventCategory: 'cls',
					  eventAction: 'pi',
					  eventLabel: window.location.href,
					  nonInteraction: true
				});
				*/
			};
			
			if( !testVitals ) {
				if(ntv.consents.ga){
					initGA();
				}else{
					$(window).on('ntvConsentReady', function(){
						initGA();
					});
				}
			}
	
		
		
		}catch (e) {
			ntvDebug("*** error PerformanceObserver ***");
			ntv.Debug(e);
		}
		
	

	}


})();



