"use strict";


if( $("*[data-lazytype]").length > 0 ){


	(function () {
	
	
		var instagramSdkLoad = false;
	
	
		document.addEventListener('lazybeforeunveil', function (e) {
	

				var $el = $(e.target);
	
				if( $el.data("lazytype") && $el.data("lazysrc")){
	
					if(instagramSdkLoad){

						var xhttp = new XMLHttpRequest();
						xhttp.onreadystatechange = function() {
							if (this.readyState == 4) {
								var jsonObj = JSON.parse(this.responseText);
								$el.html(jsonObj.html);
								instgrm.Embeds.process();
							}
						};
						xhttp.open("GET", "https://api.instagram.com/oembed/?url="+$el.data("lazysrc"), true);
						xhttp.send();
	
					}else{

						if( $("#instaSDK").length < 1 ){

							  var s = document.createElement("script");
							  s.id = "instaSDK";
							  s.async = true;
							  s.src = "https://www.instagram.com/embed.js";
							  s.onload = function(){
	
								instagramSdkLoad = true;
								$("*[data-lazytype='instagram']").attr("class", "lazyload");
	
							  };
							  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0] || document.getElementsByTagName('script')[0].parentNode).insertBefore(s,null);
						}
	
	
					}
	
	
	
				}
	
	
		});
	
	})();
	
	
}





