"use strict";

//dispatch event 'resizeReady'
(function () {
  var resizeTimout;
  var handleResize = function (e) {
    clearTimeout(resizeTimout);
    resizeTimout = setTimeout(function () {
      window.dispatchEvent(new CustomEvent('resizeReady', e))
    }, 200);
  }
  window.addEventListener('resize', function (e) {
    handleResize(e);
  });
})();



//dispatch event 'scrollReady'
(function () {
  var scrollTimout;
  var handleScroll = function (e) {
    clearTimeout(scrollTimout);
    scrollTimout = setTimeout(function () {
      window.dispatchEvent(new CustomEvent('scrollReady', e))
    }, 200);
  }
  window.addEventListener('scroll', function (e) {
    handleScroll(e);
  });
})();

