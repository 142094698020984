'use strict';



var videoTeaserPreviewIsEnable = false;




/*
var handlePreview = function(){
	
};
*/


/*
var videoTeaserPreview = function(){
	videoTeaserPreviewIsEnable = true;
	handlePreview();
}
*/


// wenn möglich A: videoTeaserPreview(true) B: videoTeaserPreview(), sonst z. 15-18

var videoTeaserPreview = function(enable){
	

	
	if( typeof(enable) != "undefined" && enable==true){
		
		videoTeaserPreviewIsEnable = true;
	}
	
	
	videoTeaserPreviewManager();

}


var videoTeaserPreviewManager = function(){
	
	
	var eCategory = videoTeaserPreviewIsEnable?"videoTeaserPrev":"videoTeaserPrevOff";
	
	var $teaserContainer = $(".teaser--videoprev");

	
	$teaserContainer.on("click", function(){
		
		
		var $container = $("picture[data-vi_url]", this);

		
		if( $container.data("viewIsFired") ){
		
			if( $("video", this).length && $("video", this).data("isplaying") ){
				
				//console.log("videoTeaserPreview: click und teaser läuft");
		    	gaEvent({
		    		eventCategory: eCategory,
		    		eventAction: "clickOnAnim",
		    		eventLabel: $container.data("teaserHref"),
		    		nonInteraction: true
		    	});

				
			}else{
				
				//console.log("videoTeaserPreview: click und teaser läuft nicht");
		    	gaEvent({
		    		eventCategory: eCategory,
		    		eventAction: "clickOnImg",
		    		eventLabel: $container.data("teaserHref"),
		    		nonInteraction: true
		    	});
			}
		}
		
	});
	
	
	var $prevContainer = $("picture[data-vi_url]", $teaserContainer);


	var addPreview = function($container){
		
		
		//console.log("videoTeaserPreview: videoteaser embed"); 
		
		var viUrl = $container.data("vi_url");
		var containerwidth = $container.width();

		/*
		var width = containerwidth>800?600:containerwidth*0.7;
		var left = (containerwidth-width)/2;
		var top = (containerwidth-width)*9/16/2;
		*/
		
		var width = containerwidth;
		var left = 0;
		var top = 0;


		var $video = $('<video width="0" loop muted style="position: absolute; z-index: 1000; left: ' + left + 'px; top: ' + top + 'px;"><source src="' + viUrl + '" type="video/mp4"></video>');
		$container.prepend($video);
		$video[0].play();

		$video[0].addEventListener("playing", function() {

			if(!$video.data("isplaying")){
				
				//$video.css("width",width + "px");
				

				$video.animate({
					width: width + "px"
				  }, 1000, function() {
	
				});

	
				/*
				$("img",$container).animate({
					opacity: "0.2"
				  }, 1000, function() {
	
				});
				*/
				$video.data("isplaying","true");
				//console.log("videoTeaserPreview: videoteaser läuft");
		    	gaEvent({
		    		eventCategory: eCategory,
		    		eventAction: "isRunning",
		    		eventLabel: $container.data("teaserHref"),
		    		nonInteraction: true
		    	});
			}
			
			
		});
	};
	
	
	var removePreview = function( $container){

		var $video = $("video",  $container);
		
		//$video.css("width","0");
		

		$video.animate({
			width: "0"
		}, 500, function() {
			$video.remove();
		});


		/*
		$("img",$container).animate({
			opacity: "1"
		}, 1000, function() {

	  	});
	  	*/
	}
	
	
	var handlePreview = function(){

		$prevContainer.each(function(){
			
			var $container = $(this);
			var containerTop = $container.offset().top-$(window).scrollTop();
			var viewPortHeight = $(window).height();
			var showPreview = false;
			var teaserHref = $("a", $container.parents(".teaser--videoprev")).attr("href");
			
			$container.data("teaserHref",teaserHref);

			
			if( containerTop < viewPortHeight/2 && containerTop > 0 ){
				showPreview = true;
				
				if(!$container.data("viewIsFired")){
					//console.log("videoTeaserPreview: teaser in viewport");

			    	gaEvent({
			    		eventCategory: eCategory,
			    		eventAction: "inViewport",
			    		eventLabel: $container.data("teaserHref"),
			    		nonInteraction: true
			    	});

				}
				
				$container.data("viewIsFired","true");
			}else{
				showPreview = false;
				$container.removeData("viewIsFired");
			}
			

			
			
			
			if( showPreview && videoTeaserPreviewIsEnable && $("video", $container).length<1 ){
				addPreview($container);
			}
			
			if( !showPreview && $("video", $container).length>0 ){
				removePreview($container);
			}
		});
		
		
	};
	
	
	
	if ( $prevContainer.length>0 ){
		handlePreview();
		$( window ).on('scrollReady resizeReady', handlePreview);
				
	}
	
	
};


//videoTeaserPreviewManager();