'use strict';

var gallery = function() {
	
  var $teaserGalleries = $( '[data-gallery-url]' );
  var $teaserGallerieLinks = $( '.teaser__related--link li' );
  var $galleryPage = $( '.gallery--page' );

  var slideParam = window.getParameterByName( 'slide' );
  var initialSlide = slideParam ? parseInt( slideParam ) : 0;

  var gallerySliderOptions = {
    initialSlide: initialSlide,
    infinite    : false,
    mobileFirst : true,
    nextArrow   : '<button type="button" data-role="none" class="slick--next icon icon--next-round" aria-label="Next" role="button"></button>',
    prevArrow   : '<button type="button" data-role="none" class="slick--prev icon icon--prev-round" aria-label="Previous" role="button"></button>',
    responsive  : [
      {
        breakpoint: 1279,
        settings  : {
          swipe: false
        }
      }
    ]
  };

  $teaserGalleries.on( 'click', 'a', function( e ) {
    e.preventDefault();
  } );

  $teaserGallerieLinks.on( 'click', 'a', function( e ) {
    e.stopPropagation();
  } );

  $teaserGalleries.on( 'click', function( e ) {
    var $this = $( this );
    var url = $this.data( 'gallery-url' );  // Each teaser--gallery has a data-gallery-url attribute containing the url for the ajax call
    var sliderStartIndex = 0;       // Index of the pop-up slider (1 if click on image, current image index if we click on the gallery page slider)

    // This will open the pop-up, it only occurs if the user clicks on a teaser--gallery
    // with the data-gallery-url attribute on it, or on the gallery-page
    // when the user clicks on the full-view button

    // If the user clicked on a teaser--gallery
    if( $this.hasClass( 'teaser--gallery' ) ) {
      fetchSlider( url, sliderStartIndex );
    }
    // If gallery page and user clicked on the fullsize button
    else if( $( e.target ).hasClass( 'gallery__full' ) ) {
      sliderStartIndex = $this.find( '.gallery__slider' )
                              .slick( 'slickCurrentSlide' );

      fetchSlider( url, sliderStartIndex );
    }

  } );
  

 	var track = function($gallery, onlyDataLayer){
	
		var trackingSet = getTrackingParams($gallery.data("trackingSet"));
		var agof_comment_array = trackingSet.agof_comment.split("/");
		var $gallerySlider = $gallery.find(".gallery__slider");
		var index = $gallerySlider.slick("slickCurrentSlide");
		
		var dataLayerObjMeta = {
			page_ivw_level_1: agof_comment_array[0] || "",
			page_ivw_level_2: agof_comment_array[1] || "",
			page_ivw_level_3: agof_comment_array[2] || "",
			page_ivw_level_4: agof_comment_array[3] || "",
			page_ivw_level_5: agof_comment_array[4] || "",
			article_date_published: trackingSet.article_date,
			article_headline: trackingSet.article_kicker + " - " + trackingSet.article_headline,
			article_master_id: trackingSet.article_id,
			article_has_voice: trackingSet.article_has_voice,
			themen_tags: trackingSet.article_tags,
			layout: getBreakpoint(),
			agof_code: trackingSet.agof_code
		};
		
		var dataLayerObjEvent = {
			event: "page_view_gallery_slided",
			eventname: "page_view",
			page_location: trackingSet.url + "?slide=" + index,
			page_title: trackingSet.article_kicker + " - " + trackingSet.article_headline,
			content_group: "Mediathek-Bilderserien",
			content_ressort: trackingSet.section
		};
	
		if (!onlyDataLayer){
			ntvTracking({
				trackIVW: true,
				trackGaView: true,
				trackingSet: trackingSet
			});
			
			if($gallery.data( "trackingSet").intTrackUrl){
				var internalTracking = new Image();
				internalTracking.src = $gallery.data( "trackingSet").intTrackUrl;
			}
		}
		
		ntvDebug("*** track gallery ***");
		ntvDebug(dataLayerObjMeta);
		ntvDebug(dataLayerObjEvent);
		
		dataLayer.push(dataLayerObjMeta);
		dataLayer.push(dataLayerObjEvent);
	};


// Ajax call to fetch the markup for the pop-up
  var fetchSlider = function( url, sliderStartIndex ) {
    $.ajax( {
              url     : url,
              dataType: 'html'
            } )
     .done( function( data ) {
       window.$body
             .addClass( 'fixed' )
             .append( data );

       galleryHandler( '.gallery--popup', sliderStartIndex );
     } )
     .fail( function( jqXHR, textStatus ) {
       console.error( 'Request failed: ' + textStatus );
     } );
  };

// Generates the slider + events
  var galleryHandler = function( gallery, sliderStartIndex ) {

    var $document = $( document );
    var $gallery = $( gallery );
    var $galleryHeader = $gallery.find( '.gallery__header' );
    var $gallerySlider = $gallery.find( '.gallery__slider' );
    var $galleryFigcaptions = $gallerySlider.find( 'figcaption' );
    var $galleryImages = $gallerySlider.find( 'img' );

    // We put the events on the share button
    shareEventHandler( $gallery );

    // Method to initialize the slider
    var initSlider = function() {
    	
      if( !$gallerySlider.hasClass( 'slick-initialized' ) ) {
        // First initialization, we need to create the events
        $gallerySlider.on( 'init', function( evt ) {
                        defineImageMaxheight();
                        sliderLazyImages( $( evt.currentTarget ).closest( '.gallery' ) );
                      } );
                      setTimeout(function() {
                        $(document).find('.slick-list').attr('tabindex', 0).focus();
                      }, 100);
      }

      // We start the pop-up gallery at the same index
      gallerySliderOptions.initialSlide = sliderStartIndex;
      $gallerySlider.slick( gallerySliderOptions );
      checkInit();
    };

    // If images are not loaded, the slider takes a width = 0px value
    // we have to reinitialize it until it takes the good width
    var checkInit = function() {
      if( $gallerySlider.find( '.slick-track' ).width() === 0 ) {
        $gallerySlider.slick( 'unslick' );
        setTimeout( initSlider, 150 );
      }
      else {
    	  
    	  var gSBeforeChangeIndex;
    	  
          //$gallerySlider.on( 'beforeChange', disableFullview )
          $gallerySlider.on( 'beforeChange', function( evt ) {
				// remove the event listeners to disable the full view
				$galleryImages.off( 'click' );
				
				gSBeforeChangeIndex = $gallerySlider.slick("slickCurrentSlide");
			} )
            .on( 'afterChange', function( evt ) {
              enableFullview();
              sliderLazyImages( $( evt.currentTarget ).closest( '.gallery' ) );
              
              if($gallerySlider.slick("slickCurrentSlide")!=gSBeforeChangeIndex){
              	window.dispatchEvent( new CustomEvent( 'galleryslide', { detail: evt.currentTarget } ) );
              	track($gallery);
              }
            } )
            .on( 'breakpoint', function( evt ) {
              sliderLazyImages( $( evt.currentTarget ).closest( '.gallery' ) );
            } );
    	  
        enableFullview();
      }
      
      
      
      
    };

    // Add the event listeners to enable the full view
    var enableFullview = function() {
      $galleryImages.off( 'click' )
                    .on( 'click', function() {
                      // When we click on an image, the header and the footer disapear,
                      // and image becomes full width / height
                      // On second click, they reappear.
                      $gallery.hasClass( 'gallery--full' ) ? exitFullview() : goFullview( $( this ) );
                    } );
    };

    // remove the event listeners to disable the full view
    /*
    var disableFullview = function() {
      $galleryImages.off( 'click' );
    };
    */

    // trigger after the user clicks on the image for a full view.
    var goFullview = function( $currentImg ) {
      // We hide the header and the figcaption
      $gallery.addClass( 'gallery--full' );
      $currentImg.css( { 'max-height': '100vh' } );
      $galleryFigcaptions.each( function() {
        var $this = $( this );
        $this.css( { bottom: $this.outerHeight() * -1 } );
        $galleryHeader.css( { marginTop: $galleryHeader.outerHeight() * -1 } );
      } );
    };

    // Methods of galleryHandler
    // Triggered after the user clicked on the image to exit the full view.
    // We display again the header and the figcaption
    var exitFullview = function() {
      $gallery.removeClass( 'gallery--full' );
      $galleryHeader.css( { marginTop: 0 } );
      $galleryFigcaptions.removeAttr( 'style' );
      defineImageMaxheight();
    };

    // Defines the image max height
    var defineImageMaxheight = function() {
      var maxHeight = $( window ).outerHeight() - $galleryHeader.outerHeight();

      $gallerySlider.find( 'img' )
                    .css( { 'max-height': maxHeight + 'px' } );
    };

    var destroySlider = function() {
      $gallery.remove();
      window.$body.removeClass( 'fixed' );
      $document.off( 'keydown' );
    };

    initSlider();

    // On close, we empty the content inside the gallery container
    $gallery.on( 'click', '.gallery__close', destroySlider );

    $( window ).resize( function() {
      defineImageMaxheight();
    } );

    // close on ESC press, we close the gallery pop-up
    $document.keydown( function( e ) {
      // keycode `27` = escape key
      if( e.keyCode === 27 && $gallery.has( '.slick-initialized' ) && !$gallery.hasClass( 'gallery--page' ) ) {
        destroySlider();
      }
    } );
    
    // off because is not working for layer
    //trackSlider( $gallery );
    
    
    console.log("### initial gallery popup ###");
    track($gallery);
  };

//
  var shareEventHandler = function( $gallery ) {

    var $gallerySlider = $gallery.find( '.gallery__slider' );

    // Returns the formatted uri with the active slide number
    var getGalleryUri = function( $elem ) {
      return $elem.attr( 'href' ) + '?slide=' + $gallerySlider.slick( 'slickCurrentSlide' );
    };

    // Copy to the clipboard the uri with the active slide number
    new Clipboard( '.gallery__share a', {
      text: function( trigger ) {
        return getGalleryUri( $( trigger ) );
      }
    } );

    // Click on the share button
    $gallery.on( 'click', '.gallery__share', function( evt ) {
      evt.preventDefault();
      var $galleryShare = $( evt.currentTarget );

      // We fetch the uri of the gallery page.
      var uri = getGalleryUri( $galleryShare.find( 'a' ) );

      // We display the info box which displays the url inside.
      var $infoBox = $( evt.currentTarget ).siblings( '.gallery__infobox' );
      $infoBox.html( 'Link Kopiert: ' + uri )
              .show();

      // The infobox stays visible till the user leaves the share button
      $galleryShare.on( 'mouseout', function() {
        // after 500ms, the infobox starts to fadeout and disappear
        $infoBox.delay( 500 )
                .fadeOut( 1000, function() {
                  // We remove the mouseout event of the share button
                  $galleryShare.off( 'mouseout' );
                } );

      } )
    } );
  };

// We are on a gallery page, we have to put the events on it for the share button
// and generate the slider
  if( $galleryPage.length > 0 ) {
	
	var gPBeforeChangeIndex;

    $galleryPage.find( '.gallery__slider' ).on( 'beforeChange', function( evt ){
	       			gPBeforeChangeIndex = $galleryPage.find(".gallery__slider").slick("slickCurrentSlide");
    			} )
                .on( 'afterChange', function( evt ) {
						if($galleryPage.find(".gallery__slider").slick("slickCurrentSlide")!=gPBeforeChangeIndex){
                  		window.dispatchEvent( new CustomEvent( 'galleryslide', { detail: evt.currentTarget } ) );
                  		track($galleryPage);
                  	}
                  
                  sliderLazyImages( $galleryPage );
                } )
                .slick( gallerySliderOptions );

    shareEventHandler( $galleryPage );
    sliderLazyImages( $galleryPage );
    
    
    // lt. Patrick: wird schon gemessen, entfällt hier
    /*
	console.log("### initial gallery page ###");
	track($galleryPage, true);
	*/


  }

};



var sliderLazyImages = function ($element) {
	
	  var $activeSlides = [];
	  var $slides = $element.find('.slick-slide');

	  if (!$element.hasClass('gallery') && window.matchMedia('(max-width: 767px)').matches) {
	    // Mobile view and normal slider (not gallery): if the slider is not activated,
	    // we are displaying only the first 5 slides, and need to lazy load them.
	    // If the slider is activated, we need to load only the displayed slide (.slick-center)
	    $activeSlides = $element.hasClass('slider--show-xs') ? $element.find('.slick-center') : $element.find('.teaser:nth-child(-n+5)');
	  } else {
	    // Not mobile view
	    $activeSlides = $element.find('.slick-slide.slick-active'); // active slides of the slider
	  }


	  // We need to preload the previous and next slides
	  var slidesCount = $activeSlides.length; // number of active slides
	  var firstSlideIndex = $activeSlides.eq(0).index(); // index of the first slide active

	  for (var index = 1; index <= slidesCount; index++) {

	    // We ad one previous
	    if (firstSlideIndex - index >= 0) {
	      $activeSlides.push($slides[firstSlideIndex - index]);
	    }
	    // We ad one next
	    if (firstSlideIndex + slidesCount + index - 1 < $slides.length) {
	      $activeSlides.push($slides[firstSlideIndex + slidesCount + index - 1]);
	    }
	  }

	  // We remove the src attribute on all the slides with the src attribute
	  $element.find('.slick-slide img[src]').each(function (index, elem) {
		  if($(elem).data('lazzy')){
		  	$(elem).removeAttr('src');
		  }
	  });
	  /*
	  $element.find('.slick-slide img[src]')
	    .removeAttr('src');
	  */

	  for (var i = 0; i < $activeSlides.length; i++) {

	    var $sourceMedia = $activeSlides.eq(i).find('source');

	    // We search first the source which matches our actual viewport width
	    $sourceMedia.each(function (index, source) {
	      var $source = $(source);
	      var media = $source.attr('media');

	      if (window.matchMedia(media).matches && $source.attr('data-lazzy-srcset')) {
	        $source.attr('srcset', $source.data('lazzy-srcset'));
	        $source.removeAttr('data-lazzy-srcset');
	      }
	    });
	  }

	  // We put the value of the img data-lazzy attribute into the src
	  $activeSlides.each(function (index, elem) {
	    var $img = $(elem).find('img');
	    if($img.data('lazzy')){
	    	$img.attr('src', $img.data('lazzy'));
	    }
	  });

	};
