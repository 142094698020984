
var linklist = function($containers){

	$containers.each(function() {
		
		
		var $container = $(this);
		var $liToggle = $container.find('li:hidden');
		var $button = $container.find('.linklist__button');

		

		$liToggle.addClass('linklist__item-toggle');

		$container.on('click','.linklist__button--more', function(){
			
			$button = $(this);
			$liToggle = $button.parent().find('.linklist__item-toggle');
			
			$liToggle.slideDown({
			      start: function () {
			        $(this).css({
			          display: "flex"
			        })
			      },
			      duration: 200
			});
			
			$('span',$button).text('weniger anzeigen');
			$button.addClass('linklist__button--less');
			$button.removeClass('linklist__button--more');
		});
		
		
		$container.on('click','.linklist__button--less', function(){
			
			$button = $(this);
			$liToggle = $button.parent().find('.linklist__item-toggle');
			
			$liToggle.slideUp({
			      duration: 200
			});
			$('span',$button).text('mehr anzeigen');
			$button.addClass('linklist__button--more');
			$button.removeClass('linklist__button--less');
		});
		
		
		
		/*
	
		var $container = $(this);
		var $liToggle = $container.find('li:hidden');
		var $button = $container.find('.linklist__switch');

		

		$liToggle.addClass('linklist__item-toggle');

		$container.on('click','.linklist__mehr', function(){
			
			$button = $(this);
			$liToggle = $button.parent().find('.linklist__item-toggle');
			
			$liToggle.slideDown({
			      start: function () {
			        $(this).css({
			          display: "flex"
			        })
			      },
			      duration: 200
			});
			
			$('span',$button).text('weniger anzeigen');
			$button.addClass('linklist__weniger');
			$button.removeClass('linklist__mehr');
		});
		
		
		$container.on('click','.linklist__weniger', function(){
			
			$button = $(this);
			$liToggle = $button.parent().find('.linklist__item-toggle');
			
			$liToggle.slideUp({
			      duration: 200
			});
			$('span',$button).text('mehr anzeigen');
			$button.addClass('linklist__mehr');
			$button.removeClass('linklist__weniger');
		});
		
		*/
		
		
		
	});
};