'use strict';

var contact = function () {
  var $contact = $('.contact');
  var $contactButton = $('.contact button');
  var $contactName = $contact.find('#name');
  var $contactEmail = $contact.find('#email');
  var $contactTel = $contact.find('#tel');
  var $contactSelect = $contact.find('#editorial');
  var $contactSelectContainer = $contact.find('select')
  var $contactSubject = $contact.find('#subject');
  var $contactSuccess = $contact.find('.contact__success');
  var $contactTextArea = $contact.find('textarea');

  // Triggered validation on keyup

  $contactName.on('focusout', function () {
    checkValidation($(this), 'length');
  });
  $contactEmail.on('focusout', function () {
    checkValidation($(this), 'mail');
  });
  $contactSelect.on('focusout', function () {
    checkValidation($(this), 'select');
  });
  $contactSubject.on('focusout', function () {
    checkValidation($(this), 'length');
  });
  $contactTextArea.on('focusout', function () {
    checkValidation($(this), 'length');
  });

  // Triggered after validating the form
  // We need to validate the form
  $contactButton.on('click', function (e) {
    e.preventDefault();
    var name = $contactName.val();
    var email = $contactEmail.val();
    var tel = $contactTel.val();
    var subject = $contactSubject.val();
    var textareaVal = $contactTextArea.val();
    var selectedOption = $contactSelect.val();

    if (name.length > 4 && selectedOption !== '---' && validateEmail(email) && subject.length > 4 && textareaVal.length > 4) {
      sendContact(name, email, tel, subject, textareaVal, selectedOption);
    } else {
      checkValidation($contactName, 'length');
      checkValidation($contactEmail, 'mail');
      checkValidation($contactSelect, 'select');
      checkValidation($contactSubject, 'length');
      checkValidation($contactTextArea, 'length');
      return false;
    }
  });


  var checkValidation = function (element, type) {
    if (type === 'length') {
      addValidationBorder(element.val().length < 5, element);
    } else if (type === 'mail') {
      addValidationBorder(!validateEmail(element.val()), element);
    } else if (type === 'select') {
      addValidationBorder(element.val() === '---', $contactSelectContainer);
    }
  };

  var addValidationBorder = function(condition, element) {
    if (condition) {
      element.addClass("form__error");
    } else {
      element.removeClass("form__error");
    }
  };

  var validateEmail = function (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // Ajax call to sent the contact form
  var sendContact = function (name, email, tel, subject, textareaVal, selectedOption) {
    var url = $contact.find('form').attr('action');
    $.ajax({
      type: 'POST',
      url: url,
      data: {
        name: name,
        email: email,
        tel: tel,
        editorial: selectedOption,
        subject: subject,
        text: textareaVal
      },
      success: onContactSuccess
    });
  };

  var onContactSuccess = function () {
    $contact.find('button[type="submit"]').remove();
    //$contactSuccess.append('<div>Vielen Dank für Ihre Nachricht. Aufgrund dr aktuellen Berichterstattung erhalten wir sehr viele Zuschriften. Bitte haben Sie Verständnis, dass wir nicht jede persönlich beantworten können.<br>Auf ntv.de im Coronavirus-Liveticker erhalten Sie immer die neuesten Informationen zum Coronavirus. Unter <a href="https://www.bundesgesundheitsministerium.de/coronavirus.html" target="_blank">https://www.bundesgesundheitsministerium.de/coronavirus.html</a> finden Sie die Angaben zu allen Hotlines zum Coronavirus.<br><br>Viele Grüße und bleiben Sie gesund!</div><br><a href="/">Zurück zur Startseite</a>');
    $contactSuccess.append('<div>Herzlichen Dank für Ihre Nachricht und Ihr Interesse an ntv. Wir freuen uns über jede Rückmeldung zu unserem Angebot und sind dankbar für die Anregungen unserer Zuschauer und User. Aufgrund der aktuellen Berichterstattung erhalten wir sehr viele Zuschriften. Bitte haben Sie Verständnis dafür, dass wir nicht jede persönlich beantworten können. <u>Ihre Vorschläge und Hinweise werden von uns gelesen und umgehend an die verantwortlichen Redaktionen mit der Bitte um Beachtung weitergeleitet.</u><br><br>Alle aktuellen Informationen über den Krieg in der Ukraine erhalten Sie auf <a href="/">www.n-tv.de</a>, der ntv App und natürlich in unserem laufenden Programm.<br><br>Die neuesten Informationen zum Coronavirus finden Sie unter <a href="https://www.n-tv.de/panorama/coronavirus/">Coronavirus Spezial - n-tv.de</a> und in der ntv App im Coronavirus-Liveticker.');
    
    
  };
}
