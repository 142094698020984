'use strict';

var tvProg = function( $tv ) {
	var $tvDays = $tv.find( '.tv__days' );                  // .tv__days
	var $daysList = $tvDays.find( 'a' );                    // list all the days (a tags)
	var $activeDay = $tvDays.find( '.active' );             // active day
	var activeDayPos = $daysList.index( $activeDay );       // index of the ctive day in the list
	var $tvProg = $( '.tv__prog' );                         // .tv__prog
	var $tvProgNow = $tvProg.find( '.tv__prog--now' );      // active prog

	/*
	 * DAYS SLIDER
	 */

	var $daySliderOptions = {
		infinite      : false,
		initialSlide  : activeDayPos - 2,
		mobileFirst   : true,
		nextArrow     : '<button class="slick--next icon icon__next" aria-label="Next"></button>',
		prevArrow     : '<button class="slick--prev icon icon__prev" aria-label="Previous"></button>',
		slidesToScroll: 5,
		slidesToShow  : 5
	};

	$tvDays.slick( $daySliderOptions );

	/*
	 * PROGRAM
	 */

	var fetchProgram = function( e ) {

		// We fetch the date from the ulr present in the href ($activeDay is a <a> tag)
		// Value example: /mediathek/tvprogramm/?date=2018-04-06
		var date = e.currentTarget.getAttribute( 'href' ).split( '=' )[ 1 ];

		// The class .tv__days has a data-baseurl parameter
		// which stores the base url for the ajax request
		// Value example: https://n-tv.de/widget/id20381334?view=next_ajax&date=
		var url = $tvDays.data( 'baseurl' ) + date;

		$.ajax( {
							url: url
						} )
		 .done( function( res ) {
			 $tvProg.html( res )
							.scrollTop( 0 );
		 } )
	};

	// ACTIVE DAY
	$daysList.on( 'click', function( e ) {
		e.preventDefault();
		$daysList.removeClass( 'active' );
		$activeDay = $( this ).addClass( 'active' );
		fetchProgram( e )
	} );

	// We put the active time on the top position of the scrollbar
	$tvProg.scrollTop( $tvProgNow.position().top );

};
