'use strict';

var mobileTopbanner = function(){

	var $container = $("#topbanner-mobile");
	var $adslot = $("#mobile_1", $container);
	var initHeight = $container.height();
	var lastHeight = initHeight;
	var trigger = $container.offset().top + initHeight - $("header.header").height();
	var triggerFull = $container.offset().top - $("header.header").height();


	
	var isContainerInViewport = function(){
		return (trigger - window.pageYOffset > 0);
	};
	
	var isContainerFullInViewport = function(){
		return (triggerFull - window.pageYOffset > 0);
	};
	
	
	var handleBanner = function(){

		if(!isContainerInViewport()){
			if( !$container.hasClass("topbanner-mobile--locked") && $container.height() == initHeight ){
				$container.addClass("topbanner-mobile--locked");
				ntvDebug("*** Topbanner locked ***");
			}
		}
		
		if(isContainerFullInViewport()){
			if( $container.hasClass("topbanner-mobile--locked") ){
				$container.removeClass("topbanner-mobile--locked");
				ntvDebug("*** Topbanner unlocked ***");
				/*
				gaEvent({
				  eventCategory: 'mobileTopBanner',
				  eventAction: 'unlocked',
				  eventLabel: window.location.href,
				  nonInteraction: true
				});
				*/
				$(window).off('scroll', handleBanner);
				ntvDebug("*** Topbanner scrollevent off ***");
			}
		}
	};	

	handleBanner();
	$(window).on('scroll', handleBanner);


	if(typeof ResizeObserver === 'function'){
		
		
		var initGA = function(){
			/*
			gaEvent({
				  eventCategory: 'mobileTopBanner',
				  eventAction: 'pi',
				  eventLabel: window.location.href,
				  nonInteraction: true
			});
			*/
		};
	
		
		if(ntv.consents.ga){
			initGA();
		}else{
			$(window).on('ntvConsentReady', function(){
				initGA();
			});
		}
		
		var resizeObserver = new ResizeObserver( function(){

			if($container.height()>lastHeight){
				$container.css("min-height", $container.height());
				lastHeight = $container.height();
			}
			
			
			var width = $adslot.width();
			var height = $adslot.height();
			var isBannerLoaded = false;
			
		
			if( !isBannerLoaded ){
				if( height > 40 && width > 40 ){
					
					/*
					gaEvent({
						  eventCategory: 'mobileTopBanner',
						  eventAction: $container.hasClass("topbanner-mobile--locked")?"loadLocked":"load",
						  eventLabel: window.location.href,
						  nonInteraction: true
					});
					*/
					isBannerLoaded = true;
					
		 			window.setTimeout(
		 				function () {
		 					var actionHeight = Math.round($adslot.height());
		 					if (actionHeight==0){
		 						actionHeight = 0 + "/" + height;
		 					}
		 					/*
							gaEvent({
								  eventCategory: 'mobileTopBanner',
								  eventAction: 'height-' + actionHeight,
								  eventLabel: window.location.href,
								  nonInteraction: true
							});
							*/
		 				},
		 				500
				 	);
		 			
					//resizeObserver.unobserve($adslot[0]);
					//ntvDebug("*** Topbanner unobserve ***");
					
				}
			}


		});

		resizeObserver.observe($adslot[0]);
		
	}
	

};