var abTestNewsSlider = function(){

	var render = function(){
		
		
		$(".wrapperNewsSliderB").html("");
		
		if(getBreakpoint()=='xs'){
			
			for (var i = 0; i < (dataNewsSlider.length)%3; i++) {
				dataNewsSlider.pop();
			}

		}
		
		var teaser;
		var iconClass = "";
		
		var html = '<section class="bnewsB">';
		html += '<div class="bnews__slidesB">';
		
		for (var i = 0; i < dataNewsSlider.length; i++) {
			
			teaser = dataNewsSlider[i];
			iconClass = "";
			
			if(teaser.type=='bilderserie'){
				iconClass = "teaser--gallery";
			}
			
			if(teaser.type=='video' || teaser.type=='specialvideo'){
				iconClass = "teaser--video";
			}
			
			//console.log( i%3 );
			//if(getBreakpoint()=='xs' && i%3==0 && i<dataNewsSlider.length) console.log("auf");
			html += getBreakpoint()=='xs' && i%3==0 && i<dataNewsSlider.length ?'<div class="bnews__slide-wrapper">':'';
		    html += '<article class="teaser ' + iconClass + '" >';
		    html += '<figure class="teaser__media">';
		    html += '<a href="' + teaser.url + '"  title="' + teaser.title + '">';
		    html += '<picture class="media media--17-6">';
		    html += '<source srcset="' + teaser.imageUrl.basePath + '/1-1/320/' + teaser.imageUrl.name + '" media="(max-width: 767px)">';
		    html += '<source srcset="' + teaser.imageUrl.basePath + '/17-6/320/' + teaser.imageUrl.name + '" media="(min-width: 768px)">';
		    html += '<img alt="selenskyj.JPG" src="' + teaser.imageUrl.basePath + '/17-6/320/' + teaser.imageUrl.name + '">';
		    html += '</picture>';
		    html += '</a>';
		    html += '</figure>';
		    html += '<div class="teaser__content">';
		    html += '<div class="teaser__infos">';
		    html += '<span class="teaser__date">' + teaser.time + '</span>';
		    html += '<span class="teaser__section"><a href="' + teaser.url + '">' + teaser.section + '</a></span>';
		    html += '</div>';
		    html += '<a href="' + teaser.url + '"  title="' + teaser.title + '">';
		    html += '<span class="teaser__headline">' + teaser.title + '</span>';
		    html += '</a>';
		    html += '</div>';
		    html += '</article>';
		    //if(getBreakpoint()=='xs' && (i%3==2 || i+1 == dataNewsSlider.length)) console.log("zu");
		    html += getBreakpoint()=='xs' && (i%3==2 || i+1 == dataNewsSlider.length)?'</div data="close">':'';
		}
		
		
		html += '</div>';
		html += '<div class="bnewsB__more"><a href="https://www.n-tv.de/home/Das_Neueste/">Alle Schlagzeilen anzeigen</a></div>';
		html += '</div>';
		
		
		$(".wrapperNewsSliderB").html(html);
		
		
		var $bnewsSlidesB = $( '.bnews__slidesB' );
		
		
		//if(ntv.pageInfo.section=="Startseite" && $bnewsSlidesB.length>0){
		if($bnewsSlidesB.length>0){

			var $firstSlider = $($bnewsSlidesB[0]);
			var $aMessage;
			var $sliderParent;
			var indexMessage = 1;
			var offsetBottom = $firstSlider.offset().top + $firstSlider.parents(".bnewsB").height() - $("header.header").height();
			//var offsetBottom = $firstSlider.offset().top + 250 - $("header.header").height();

	 
			var isSliderInViewport = function(){
				
				offsetBottom = $firstSlider.offset().top + $firstSlider.parents(".bnewsB").height() - $("header.header").height();

				if( offsetBottom > window.pageYOffset ){
					return true;
				}
				return false;
			}
		  
			var trackSlider = function(action, label){
				
				var obj = {
					'event' : 'page-track',
					'eventcategory' : 'slider',
					'eventaction' : action,
					'eventlabel' : label
				};
				
				ntvDebug("*** track bnewsSlider ***");
				ntvDebug(obj);
		
				dataLayer.push(obj);

			}
		  
		 
		
			$firstSlider.on('init', function(slick) {
				
				if( isSliderInViewport() ){
					$aMessage = $($(".teaser a", $firstSlider)[0]);
					trackSlider('das neueste: displayed message ' + indexMessage, $aMessage.attr("href"));
				}
				
				$(".slick-arrow", $firstSlider.parents(".bnewsB")).on('click', function(){
					trackSlider('das neueste: clicked arrow', $(this).hasClass('slick--prev')?'left':'right');
				});
				$("a",$firstSlider).on('click', function() {
					trackSlider('das neueste: clicked message ' + indexMessage, $(this).attr("href"));
				});
			
			});
			
			$firstSlider.on('swipe', function( evt, slick, direction ) {
					trackSlider('das neueste: swiped', direction);
			});
		
		
			$firstSlider.on('afterChange', function( evt, slick, currentSlide ) {
				if( isSliderInViewport() ){
					indexMessage = currentSlide + 1;
				  	$aMessage = $(".slick-active a", $firstSlider);
				  	trackSlider('das neueste: displayed message ' + indexMessage, $aMessage.attr("href"));
				}
			});
		
		}
		

		if( $bnewsSlidesB.length ) {
			
			$bnewsSlidesB.slick(
					     {
					       //appendArrows: '.bnewsB--slidesB .slider__nav',
					       //appendDots  : '.slider--slidesB .slider__nav',
					       //dots        : true,
					       //infinite    : false,
					       mobileFirst : true,
					       nextArrow   : '<button class="slick--next icon icon__next" aria-label="Next"></button>',
								 prevArrow   : '<button class="slick--prev icon icon__prev" aria-label="Previous"></button>',
					       responsive  : [
					         {
					           breakpoint: 1,
					           settings  : {
					             arrows      : false,
					             centerMode  : true,
					             slidesToShow: 1
					           }
					         },
					         {
					           breakpoint: 767,
					           settings  : {
					             slidesToShow  : 3,
					             slidesToScroll: 3
					           }
					         },
					         {
					           breakpoint: 1279,
					           settings  : {
					             //slidesToShow  : 4.2,
					             slidesToShow  : 4,
					             slidesToScroll: 4,
					             swipe         : false
					           }
					         }
					       ]
					     } );

			}
		
		
			$(".bnews").hide();

	};
	
	
	if( $(".wrapperNewsSliderB").length && window.dataNewsSlider ) {
		
		var wasXs = getBreakpoint()=='xs'?true:false;
		var isXs = getBreakpoint()=='xs'?true:false;
		
		
		window.addEventListener('resizeReady', function(e){
			isXs = getBreakpoint()=='xs'?true:false;
			if(wasXs!=isXs){
				render();
				wasXs = isXs;
			}
		});
		
	
		render();

	}
	
};


$(document).ready(function () {
	
	if(ntv.urlParams.renderB){
		abTestNewsSlider();
	}

});