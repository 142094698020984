'use strict';

var goTop = function ($gotop) {

	var lastScrollTop = $(window).scrollTop();
	var timeout;
	var ressort = ntv.pageInfo.section?ntv.pageInfo.section:'na';
	
	if (ressort=="Startseite"){
		ressort = "home";
	}
	
	
	var triggerTimeout = function () {
		
		timeout = setTimeout(
			function () {
				$gotop.removeClass("gotop--active");
			},
			2000
		);
	}
	

	$gotop.on('click', function (e) {
		e.preventDefault();
		dataLayer.push({
			'event' : 'page-track',
			'eventcategory' : 'navigation',
			'eventaction' : 'jump label',
			'eventlabel' : ressort
		});
		dataLayer.push({
			'event' : 'ga4_event',
			'eventcategory' : 'navigation',
			'eventaction' : 'jump label',
			'eventlabel' : ressort
		});
		$('html, body').animate({
			scrollTop: 0
		}, 200);
	});
	
	
	$gotop.on('mouseenter', function (e) {
		clearTimeout(timeout);
	});

	
	$gotop.on('mouseout', function (e) {
		triggerTimeout();
	});
	
	$(window).on('scroll', 
		function () {
			
			if ( $(window).scrollTop() > $(window).height() && $(window).scrollTop() < lastScrollTop ) {
					$gotop.addClass("gotop--active");
					clearTimeout(timeout);
			}
			lastScrollTop = $(window).scrollTop();
		}
	);


	$(window).on('scrollReady', function(){
		if($gotop.hasClass("gotop--active")){
			triggerTimeout();
		}
	});



};



