'use strict';


/* toDo

- kein Poster, bei nachgeladenen Videos (verhindert flackern)
- keine werbung für pmode und no ad
(preroll: ntv.pageInfo.pmode?false:videoSetup.advertising.playPreroll)
- Livestream läuft nicht
- Sonderlocken preview Redaktion

*/

/* ### Fragen ###


*****

??? aktuell
shortFormLimit: {inSeconds: 179},
noAdsLimit: {inSeconds: 29},

was bei pur?:
fixParams: ntv.consents.info.tcString?["consent=" + ntv.consents.info.tcString]:["opt=out"],

was ist default bei advertising.vdcParameter?

preset?
"Das zu verwendende Preset erhält man vom Foundation-Player Team. Dieses bestimmt u.a., ob Werbeausspielungen aktiviert sind."

*****




- progressive error wegen url syntax
- kein livestreammodus? (angepasste controlls?!)
- wo ist der player live? sind wir die ersten?
- brauche pause/play via javascript => problem, wenn über browser-api?
- generell infos via browser-api, weil viper nicht liefert = problem?
- error durchreichen / erkennung geoblocking / hinweistafel
- lädt vcc: https://bilder-a.akamaihd.net/ip/vcc/vcc.min.js?site=viper => müssen wir die trotzdem laden? (https://bilder-a.akamaihd.net/ip/vcc/vcc.min.js?site=ntv)
- sollen wir den player selber hosten?
- mp4 url's mit params müssen gehen
- Nielsen, heartbeat???
- wo ist doku vom foundation player???

drm fairplay aktuell: ist das relevant? wie machen wir das hier? => fairplay=apple => testen!!!!!!
fairplay: {
	LA_URL: 'https://fairplay.n-tv.de/Fairplay/GetLicense',
	certificateURL: 'https://fairplay.n-tv.de/Certificate/RTLiDe_cert.cer',
	headers: {},
	prepareMessage: function (event, session) {
		return 'spc=' + encodeURIComponent(event.messageBase64Encoded) + '&' + session.contentId;
	},
	prepareContentId: function (contentId) {
		var pattern = 'skd://fairplay.rtl.de?';
		var parameters;
		var idx;

		idx = contentId.indexOf(pattern);
		if (idx > -1) {
			parameters = contentId.substring(idx + pattern.length);
			parameters = parameters.replace(/variantid/gi, 'variantId');
			return parameters;
		} else {
			return '';
		}
	}
}

*/

var startNtvVideoplayerViper = function( $container ) {

	var videoSetup = $container.data( 'player' ).setup;

	var videoConfig = {
	  "advertising": {
	    "share": videoSetup.advertising.adCall.category,
	    "slots": [
	      {
	        "type": "preRoll",
	        "url": "https://ad-ipd.sxp.smartclip.net/select"
	      }
	    ],
	    "vdcParameter": window.$AC?$AC.getSXPKeys():''
	  },
	  "consent": ntv.consents.info.tcString?ntv.consents.info.tcString:'',
	  "doAutopause": false,
	  "doAutoplay": true,
	"drm": {
	  "fairplay": {
	    "address": "https://fairplay.n-tv.de/Fairplay/GetLicense",
	    "certificate": "https://fairplay.n-tv.de/Certificate/RTLiDe_cert.cer"
	  },
	  "playready": {
	    "address": "https://playready-core.n-tv.de/playready/api/RightsManager.asmx"
	  },
	  "widevine": {
	    "address": "https://widevine.n-tv.de/index/proxy"
	  }
	},
	  "media": {
	    "duration": videoSetup.source.length*1000,
	    "headline": videoSetup.source.title,
	    "id": 'id_' + videoSetup.source.videoId,
	    "manifests": {}
	  },
	  "preset": "default-advertising",
	  "tenant": "ntv"
	};
	
	if(videoSetup.source.poster && videoSetup.source.poster!="") videoConfig.media.poster=videoSetup.source.poster;

	if(videoSetup.source.hls){
		videoConfig.media.manifests.hlsfairplayhd = [
			{
		          "priority": "main",
		          "url": videoSetup.source.hls
			}
		];
	}
	
	if(videoSetup.source.dash){
		videoConfig.media.manifests.dashhd = [
			{
		          "priority": "main",
		          "url": videoSetup.source.dash
			}
		];
	}
	
	/*
	if(videoSetup.source.progressive){
		videoConfig.media.manifests.progressive = [
			{
		          "priority": "main",
		          "url": videoSetup.source.progressive
			}
		];
	}
	*/
	
	ntvDebug("*** videoConfig ***");
	ntvDebug(videoConfig);
	
	$container.html( '' );
	
	var $viperWrapper = $('<div class="viper" id="video-viper"></div>');
	
	$container.prepend($viperWrapper);
	
	var contentStarted = false;
	var sessionStarted = false;
	var sessionEnd = false;
	
	$viperWrapper[0].addEventListener("timeUpdate", function(e){
		
		if(!sessionStarted){
			console.log("sessionStarted");
			sessionStarted = true;
		}
		
		if(!contentStarted && !e.detail.isAd){
			console.log("contentStarted");
			contentStarted = true;
			
			var video = $("video", $viperWrapper)[0];
			
	    	video.onended = function(){
    			console.log("sessionEnd");
    			sessionEnd = true;
    			
    			
    			 if( $container.data('playlist') && $container.data('playlist').ajaxUrl && $container.data('playlist').videoIds.length > 0 ){
    				 
    					var playlistSetup = $container.data('playlist');
    					 
    					var ajaxUrl = playlistSetup.ajaxUrl + "&ctxArt=" + playlistSetup.videoIds[0];
    				 	
    				 	$container.data('playlist').videoIds.shift();

    					$.ajax({
    						url: ajaxUrl,
    						type: "GET"
    					})
    					.done( function( data, textStatus, jqXHR ) {
    						$(".ajax-wrapper", ".article--video").html(data);
    						window.dispatchEvent( new CustomEvent('videoplayerNewVideo') );
    						startNtvVideoplayerViper( $("#playerwrapper") );

    					})
    					.fail( function( jqXHR, textStatus, errorThrown ) {
    						//ntv.log( jqXHR, textStatus, errorThrown );
    					});

    				 }
    			
    			
			}
			
		}
		
	});

	viper.createVideoPlayer(document.querySelector("#video-viper")).load(videoConfig);
};