"use strict";

const browserPush = function () {
  const usrAg = navigator.userAgent;
  const browserpushDeniedChrome = $(
    '<div class="backdrop"><div class="browserpush__modal"> <div class="browserpush__modal--close"><img src="' +
    resourceBaseUrl +
    '/adaptive/images/icons/icon__close.svg" /></div><img src="' +
    resourceBaseUrl +
    '/adaptive/webpush/unblock-chrome-german.gif" /></div></div>'
  );
  const browserpushDeniedFirefox = $(
    '<div class="backdrop"><div class="browserpush__modal"> <div class="browserpush__modal--close"><img src="' +
    resourceBaseUrl +
    '/adaptive/images/icons/icon__close.svg" /></div><img src="' +
    resourceBaseUrl +
    '/adaptive/webpush/unblock-FF-german.gif" /></div></div>'
  );
  const askModal = $(
    '<div class="backdrop"><div class="browserpush__modal ask"><div class="browserpush__ask__logo"><img src="' +
    resourceBaseUrl +
    '/adaptive/images/logo.svg" width=100 height=62/></div><div class="browserpush__ask"><span>Möchten Sie künftig direkt per Browser-Benachrichtigung über die wichtigsten Nachrichten informiert werden?</span><div class="browserpush__ask__buttons"><button class="button button--secondary denied">Nein</button><button class="button accepted">Ja</button></div></div></div>'
  );
  var askDenied = localStorage.getItem("askDenied");
  var deniedDate = new Date();
  var lastDeniedDate = new Date(localStorage.getItem("lastDeniedDate"));
  const waitHrsToAskAgain = 672; //wait hours to ask again
  const archivedArticles = 10; //show max. archived articles
  var bell = document.querySelector(".header__nav__push");
  var clickCount = localStorage.getItem("clickCount");
  const clickNumber = 7; //how many clicks until ask
  const clickCounter = function () {
    if (clickCount === null) {
      clickCount = 0;
    }
    clickCount++;
    localStorage.setItem("clickCount", clickCount);
    if (localStorage.getItem("clickCount") == clickNumber) {
      askForPermission();
    }
  };
  const firstAsk = function () {
    window.addEventListener("click", clickCounter);
    if (localStorage.getItem("clickCount") == clickNumber) {
      askForPermission();
    }
  };

  var getNewNotifications = function () {
    pushDelivery.setPayloadHandler(function (payload) {
      bell.classList.add("active");
    });
  };

  var promises = function () {
    pushDelivery.init().then(function () {
      Promise.all([
        pushDelivery.getSubscribedTags(),
        pushDelivery.getTags(),
        pushDelivery.getArchive(),
      ]).then(function (values) {
        var subscribedTags = values[0];
        var tagList = values[1];
        var archive = values[2];
        getNewNotifications();
        if (tagList.length > 0) {
          renderTags(tagList[0].tags, subscribedTags);
        }
        if (archive.length > 0) {
          if (archive.length > archivedArticles) {
            renderArchive(archive.splice(0, archivedArticles));
          } else {
            renderArchive(archive);
          }
        } else {
          renderNoArchive();
        }
      });
    });
  };

  /* init push */
  window.onmessage = function (event) {
    if (event.data !== "pushDeliveryReady") {
      return;
    }

    if (window.location.pathname == "/browserpush/") {
      if (Notification.permission === "default") {
        askForPermission();
      } else if (Notification.permission === "denied") {
        if (usrAg.indexOf("Chrome") > -1) {
          $("body").append(browserpushDeniedChrome);
        } else {
          $("body").append(browserpushDeniedFirefox);
        }
      }
    }

    if (Notification.permission === "granted") {
      promises();
    } else if (Notification.permission === "default") {
      if (!askDenied) {
        firstAsk();
      } else if (askDenied === "true") {
        if (checkAskAgain() === true) {
          askForPermission();
        }
      }
    }
  };

  var renderTags = function (tags, subscribedTags) {
    var subscribedTagIds = subscribedTags.map(function (subscribedTag) {
      return subscribedTag.id;
    });

    var channels = document.querySelector(".browserpush__channel ul");

    tags.forEach(function (tag) {
      var checkedAttr =
        subscribedTagIds.indexOf(tag.id) !== -1 ? "checked" : "";
      var listItem = document.createElement("li");
      listItem.innerHTML =
        "<input " +
        checkedAttr +
        ' type="checkbox" id="' +
        tag.id +
        '"/><label for="' +
        tag.id +
        '">' +
        tag.name +
        "</label>";

      if (channels) {
        channels.appendChild(listItem);
      }
    });
  };

  var renderArchive = function (archive) {
    var pushHistory = document.querySelector(".browserpush__history");

    archive.forEach(function (push) {
      var article = document.createElement("article");
      article.classList.add("teaser", "teaser--inline");
      var pushImg;
      push.data.messageImageUrl
        ? (pushImg = push.data.messageImageUrl)
        : (pushImg = "");
      var imgAlt;
      pushImg ? (imgAlt = push.data.headline) : (imgAlt = "");

      article.innerHTML =
        "<figure class='teaser__media'><a href='" +
        push.data.url +
        "' title='" +
        push.data.headline +
        "'><picture class='media media--16-9'><img alt='" +
        imgAlt +
        "' src='" +
        pushImg +
        "'></picture></a></figure><div class='teaser__content'><div class='teaser__infos'><span class='teaser__section'>" +
        push.date.toLocaleString([], {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) +
        "</span></div><a href='" +
        push.data.url +
        "'><h2><span class='teaser__kicker'>" +
        push.data.channelname +
        "</span><span class='teaser__headline'>" +
        push.data.headline +
        "</span></h2></a></div>";

      if (pushHistory) {
        pushHistory.appendChild(article);
      }
    });
  };

  var renderNoArchive = function () {
    var pushHistory = document.querySelector(".browserpush__history");
    var noArchive = document.createElement("article");
    noArchive.innerHTML =
      "Zukünftig finden Sie hier die eingehenden Push-Nachrichten.";
    if (pushHistory) {
      pushHistory.appendChild(noArchive);
    }
  };

  $(".browserpush__channel").on("change", "input", function () {
    var id = $(this).attr("id");
    var that = $(this);

    if (that.attr("disabled")) {
      return;
    }

    that.attr("disabled", true);

    if (!that.prop("checked")) {
      pushDelivery.unsubscribeFrom([id]).then(function (e) {
        that.removeAttr("disabled");
        console.log(e);
      });
    } else {
      pushDelivery.subscribeTo([id]).then(function (e) {
        that.removeAttr("disabled");
        console.log(e);
      });
    }
  });

  const askForPermission = function () {
    $("body").append(askModal);
    $(".accepted").on("click", function () {
      fetch("https://metrics.n-tv.de/event?eventType=push-opt-in&platform=desktop");
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          promises();
          window.location.href = "/browserpush";
        }
        $(askModal).remove();
      });
    });
    $(".denied").on("click", function () {
      fetch("https://metrics.n-tv.de/event?eventType=push-opt-out&platform=desktop");
      localStorage.setItem("askDenied", true);
      localStorage.setItem("lastDeniedDate", deniedDate);
      $(askModal).remove();
    });
    window.removeEventListener("click", clickCounter);
  };

  const checkAskAgain = function () {
    var currentDate = new Date();
    var milsecElapsed = currentDate - lastDeniedDate;
    var hrsElapsed = milsecElapsed / 1000 / 60 / 60;
    if (hrsElapsed > waitHrsToAskAgain) {
      return false;
    } else {
      return false;
    }
  };

  $(".header__nav__push").on("click", function () {
    bell.classList.remove("active");

    if (Notification.permission === "granted") {
      window.location.href = "/browserpush/";
    } else if (Notification.permission === "default") {
      askForPermission();
    } else {
      if (usrAg.indexOf("Chrome") > -1) {
        $("body").append(browserpushDeniedChrome);
      } else {
        $("body").append(browserpushDeniedFirefox);
      }
    }
  });

  $(document).on("click", ".browserpush__modal--close", function () {
    $(browserpushDeniedChrome || browserpushDeniedFirefox).remove();
  });
};
