'use strict';

var renderLotto = function($container){
	
	
	// https://api.lotto24.de/v1/drawinfo/
	// https://api.lotto24.de/v1/drawinfo/aggregated/6aus49,eurojackpot
	


	/*
	var data = [
		{
			"lottery":"6aus49",
			"lastDraw":{
				"drawIdentifier":"2022-06-11",
				"lottery":"6aus49",
				"drawDate":"2022-06-11T19:25:00+02:00",
				"drawDateUtc":"2022-06-11T17:25:00Z",
				"drawResult":{
					"numbers":[11,17,22,25,29,44],
					"superNumber":8
				},
				"currency":"EUR",
				"quotas":{},
				"nonMonetaryQuotas":{},
				"winners":{},
				"totalStake":null
			},
			"nextDraw":{
				"drawIdentifier":"2022-06-15",
				"lottery":"6aus49",
				"drawDate":"2022-06-15T18:25:00+02:00",
				"drawDateUtc":"2022-06-15T16:25:00Z",
				"cutofftime":"2022-06-15T17:45:00+02:00",
				"timeZone":"Europe/Berlin",
				"jackpot":{
					"drawIdentifier":"2022-06-15",
					"lottery":"6aus49",
					"drawDate":"2022-06-15T18:25:00+02:00",
					"currency":"EUR",
					"jackpots":{
						"WC_1":"17000000.00",
						"WC_2":"1000000.00"
					}
				}
			}
		},
		{"lottery":"eurojackpot","lastDraw":{"drawIdentifier":"2022-06-10","lottery":"eurojackpot","drawDate":"2022-06-10T20:00:00+03:00","drawDateUtc":"2022-06-10T17:00:00Z","drawResult":{"numbers":[3,21,23,28,46],"euroNumbers":[10,12]},"currency":"EUR","quotas":{"WC_1":"0.00","WC_2":"1666398.60","WC_3":"452396.10","WC_4":"22144.80","WC_5":"389.80","WC_6":"289.50","WC_7":"97.00","WC_8":"44.60","WC_9":"25.60","WC_10":"18.00","WC_11":"18.00","WC_12":"12.70"},"nonMonetaryQuotas":{},"winners":{"WC_1":0,"WC_2":1,"WC_3":3,"WC_4":7,"WC_5":497,"WC_6":736,"WC_7":1597,"WC_8":11076,"WC_9":21557,"WC_10":72397,"WC_11":58290,"WC_12":309332},"totalStake":"38753458.00"},"nextDraw":{"drawIdentifier":"2022-06-14","lottery":"eurojackpot","drawDate":"2022-06-14T20:00:00+03:00","drawDateUtc":"2022-06-14T17:00:00Z","cutofftime":"2022-06-14T19:35:00+03:00","timeZone":"Europe/Helsinki","jackpot":{"drawIdentifier":"2022-06-14","lottery":"eurojackpot","drawDate":"2022-06-14T20:00:00+03:00","currency":"EUR","jackpots":{"WC_1":"30000000.00"}}}}];
		*/
		
		

	
	
	$.ajax({
	      dataType: 'json',
	      url: 'https://api.lotto24.de/v1/drawinfo/aggregated/6aus49,eurojackpot',
	      success: function (data) {
	    	  ntvDebug("*** Lotto ajax ***");
	    	  ntvDebug(data);
	    	  render(data);
	      }
	});
	
	
	
	var render = function(data){
		
		if (data.length<2) return;
		if (data[0].lottery!="6aus49") return;
		if (data[1].lottery!="eurojackpot") return;

		var lotto = [];
		var actualDate = new Date(ntv.pageInfo.renderTimeStamp*1000);
		var lottery_1;
		var lottery_2;
		var cutoffDateLotto = new Date(data[0].nextDraw.cutofftime);
		var cutoffDateEuro = new Date(data[1].nextDraw.cutofftime);
		// default Eurojackpot
		var actualLotIndex = 1;
		
		ntvDebug("*** Lotto render ***");
		
		ntvDebug(actualDate);
		ntvDebug(cutoffDateLotto);
		ntvDebug(cutoffDateEuro);
		
		
		// conditions Lotto
		// Dienstag
		if( actualDate.getDay()==2 && !(cutoffDateEuro.getDay()==2 && actualDate<cutoffDateEuro) ){
			actualLotIndex=0;
		}
		
		// Mittwoch
		if( actualDate.getDay()==3 && (cutoffDateLotto.getDay()==3 && actualDate<cutoffDateLotto) ){
			actualLotIndex=0;
		}
		
		
		// Freitag
		if( actualDate.getDay()==5 && !(cutoffDateEuro.getDay()==5 && actualDate<cutoffDateEuro) ){
			actualLotIndex=0;
		}
		
		// Samstag
		if( actualDate.getDay()==6 && (cutoffDateLotto.getDay()==6 && actualDate<cutoffDateLotto) ){
			actualLotIndex=0;
		}
		
		ntvDebug(actualLotIndex);
		
		
		lotto.push(data[actualLotIndex]);
		lotto.push(data[actualLotIndex==1?0:1]);
		
		
	
  	  
	  ntvDebug(lotto);

		
		
		if (lotto.length<2) return;
		
		
		$( $container ).each(function( index ) {
			
			var $thisContainer = $(this);
			var containerId = "lotto-" + index;
			var $slider = $(".lotto__slides", $thisContainer);
			
			$thisContainer.attr("id", containerId);
			
		
			$( ".lotto__lottery", $thisContainer ).each(function( index ) {
				
			
				var $lottery = $(this);
				var lottery_data = lotto[index];
				var lottery_name = lottery_data.lottery;
				var lastDrawDate = new Date(lottery_data.lastDraw.drawDate);
				var result = lottery_data.lastDraw.drawResult;
				var html_numbers = "";
				
			
				$lottery.addClass("lotto__lottery--" + lottery_name);
				$(".lotto__logo", $lottery).attr("src",resourceBaseUrl + "/adaptive/images/logo_" + lottery_name + ".png");
				
				
				if(lottery_data.nextDraw.jackpot && lottery_data.nextDraw.jackpot.jackpots && lottery_data.nextDraw.jackpot.jackpots.WC_1){
					$(".lotto__jackpot__value", $lottery).html(parseInt(lottery_data.nextDraw.jackpot.jackpots.WC_1)/1000000 + " Mio. €");
				}else{
					$(".lotto__jackpot__value", $lottery).html("wird ermittelt");
					$(".lotto__jackpot__value", $lottery).addClass("lotto__jackpot__value--status");
				}
				
				
				$(".lotto__numbers span", $lottery).html("Gewinnzahlen vom " + lastDrawDate.getDate() + "." + (lastDrawDate.getMonth()+1) + "." + lastDrawDate.getFullYear());
				
				
				for (var i = 0; i < result.numbers.length; i++) {
					html_numbers += '<li>' + result.numbers[i] + '</li>';
		
				}
				
				if(lottery_name=='6aus49'){
					html_numbers += '<li>' + result.superNumber + '</li>';
					$(".lotto__jackpot__button", $lottery).attr("href", "https://lotto.n-tv.de/lotto-6-aus-49?partnerId=1NTCOO0001&advertisementId=1HOMEPA000HOWIDGLOTTOSTATIHT51000ZEITMIFRSA");
					//$(".lotto__numbers a", $lottery).attr("href", "https://lotto.n-tv.de/lotto-6-aus-49/zahlen-quoten");
				}
				if(lottery_name=='eurojackpot'){
					for (i = 0; i < result.euroNumbers.length; i++) {
						html_numbers += '<li>' + result.euroNumbers[i] + '</li>';
		
					}
					$(".lotto__jackpot__button", $lottery).attr("href", "https://lotto.n-tv.de/eurojackpot?partnerId=1NTCOO0001&advertisementId=1HOMEPA000HOWIDGEJPSTATISCHT52000ZEITDOFR00");
					//$(".lotto__numbers a", $lottery).attr("href", "https://lotto.n-tv.de/eurojackpot/zahlen-quoten");
				}
				
				$(".lotto__numbers ul", $lottery).html(html_numbers);
				
		
				
				$thisContainer.removeClass("lotto--hide");
				
			});
			
			
			$slider.on('init', function(slick) {
				$(".lotto__lottery", $thisContainer).removeClass("lotto__lottery--hidden");
			});
			
			
			var sliderId = $slider.attr("id");

			if( !$slider.hasClass( 'slick-initialized' ) ){
			   $slider.slick(
			     {
			    	    arrows     : false,
			    	 	appendArrows   : '[id="' + containerId + '"] .lotto__slider__controls',
			    	 	appendDots   : '[id="' + containerId + '"] .lotto__slider__controls',
			    	    dots       : true,
			    	    infinite   : false,
			    	    mobileFirst: true,
			            responsive   : [
			                {
			                  breakpoint: 767,
			                  settings  : {
			                    arrows   : true,
			                    nextArrow: '<button class="slick--next icon icon__next" aria-label="Next"></button>',
			                    prevArrow: '<button class="slick--prev icon icon__prev" aria-label="Previous"></button>'
			                  }
			                },
			                {
			                  breakpoint: 1279,
			                  settings  : {
			                    arrows   : true,
			                    nextArrow: '<button class="slick--next icon icon__next" aria-label="Next"></button>',
			                    prevArrow: '<button class="slick--prev icon icon__prev" aria-label="Previous"></button>',
			                    swipe: false
			                  }
			                }
			              ]
			     } );
			}
			
			
		});
		
		
		
		
		

	
	
	};
	
/* save HTML

<div class="lotto lotto--hide sidebar__group phide">
	<div class="teaser__infos"><span class="teaser__ad">ANZEIGE</span></div>
	<div class="lotto__slider">
		<div class="lotto__slides">

					<div class="lotto__lottery">
						<div class="lotto__header">
							<img class="lotto__logo" src="" />
							<img src="https://www.n-tv.de/resources/59615448/adaptive/images/logo.svg" />
						</div>
						<div class="lotto__jackpot">
							<span>im Jackpot:</span>
							<span class="lotto__jackpot__value"></span>
							<a class="lotto__jackpot__button" target="_blank" href="">Jetzt spielen</a>
						</div>
						<div class="lotto__numbers">
							<span></span>
							<ul></ul>
							<a href="https://lotto.n-tv.de/lottozahlen-quoten?partnerId=1NTCOO0001&advertisementId=1HOMEPA000HOWIDG0000000000HT500000000000000" target="_blank">Zahlen & Quoten</a>
						</div>
						<div class="lotto__footer">
						 	<span>Chance: 1:140 Mio. Ab 18. Glücksspielsucht.</span>
						 	<span>Hilfe unter <a href="https://www.buwei.de/" target="_blank">buwei.de</a>. Whitelisted.</span>
						 	<span>Ein Angebot von LOTTO24</span>
						</div>
					</div>

					<div class="lotto__lottery lotto__lottery--hidden">
						<div class="lotto__header">
							<img class="lotto__logo" src="" />
							<img src="https://www.n-tv.de/resources/59615448/adaptive/images/logo.svg" />
						</div>
						<div class="lotto__jackpot">
							<span>im Jackpot:</span>
							<span class="lotto__jackpot__value"></span>
							<a class="lotto__jackpot__button" target="_blank"  href="">Jetzt spielen</a>
						</div>
						<div class="lotto__numbers">
							<span></span>
							<ul></ul>
							<a href="https://lotto.n-tv.de/lottozahlen-quoten?partnerId=1NTCOO0001&advertisementId=1HOMEPA000HOWIDG0000000000HT500000000000000" target="_blank">Zahlen & Quoten</a>
						</div>
						<div class="lotto__footer">
						 	<span>Chance: 1:140 Mio. Ab 18. Glücksspielsucht.</span>
						 	<span>Hilfe unter <a href="https://www.buwei.de/" target="_blank">buwei.de</a>. Whitelisted.</span>
						 	<span>Ein Angebot von LOTTO24</span>
						</div>
					</div>

		</div>
	</div>
	<div class="lotto__slider__controls"></div>

</div>

 */
	
};