'use strict';


var datenschutzerklaerung = function(){
	
	//clickhander für consentlayer in der Datenschutzerklärung
	/*
	$(".article__text a").each(function(){
		
		var $a = $(this);
		if( $a.attr("href").indexOf("?callPrCenter")>-1 ){
			$a.on("click", function () {
				window._sp_.loadPrivacyManagerModal(152368);
				return false;
			});
		}
		
		if( $a.attr("href").indexOf("?callTrustpid")>-1 ){
			$a.on("click", function () {
				if(window.TrustpidAPI){
					TrustpidAPI.showConsentManager();
				}
				return false;
			});
		}
		
	});
	*/
	
	
	$( "a[href*='?callPrCenter']" ).on("click", function () {
		//window._sp_.loadPrivacyManagerModal(152368);
		window._sp_.gdpr.loadPrivacyManagerModal(152368); return false;
		return false;
	});
	
	$( "a[href*='?callTrustpid']" ).on("click", function () {
		if(window.TrustpidAPI){
			TrustpidAPI.showConsentManager();
		}
		return false;
	});
	
	
	// Privacy Link für PUR-User ausblenden
	if( ntv.pageInfo.pmode ){
		$( "p:contains('Sie können Einstellungen zu Datenverarbeitungen')" ).hide();
	}
	
	
};