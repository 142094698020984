'use strict';

var stockTable = function( $stockTable ) {

  var getData = function( $target ) {
    var url = $target.data( 'ajax-url' );

    $.ajax( {
              url     : url,
              dataType: 'html'
            } )
     .done( function( markup ) {
       var $markup = $( markup );
       initClickEvents( $markup );
       $target.parents( '.stock-table' ).html( $markup );
     } )
     .fail( function( jqXHR, textStatus ) {
       console.error( 'Request failed: ' + textStatus );
     } );
  };

  var initClickEvents = function( $markup ) {
    $markup.on( 'click', 'button[data-ajax-url]', function( e ) {
      var $target = $( e.currentTarget );
//      console.log( 'data: ', url );
      getData( $target );
    } );
  };

  initClickEvents( $stockTable );

};
