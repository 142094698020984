'use strict';



var $nlLoggedIn = $(".nl-loggedin");
var $nlLoggedInInfo = $(".nl-loggedin__info",$nlLoggedIn);
var $nlWrapper = $(".nl-loggedin__wrapper", $nlLoggedIn);
var $nlChannel = $(".nl-loggedin__abo", $nlLoggedIn);

var renderNewsletter = function(newsletterData){
	
	console.log("+++ renderNewsletter +++");
	
	$nlWrapper.html('');
	
	var renderChannel = function(data){
		
		var html = "";
		
		html += '<div class="nl-loggedin__abo" data-channelid="' + data.id + '">';
		html += '<article class="nl-loggedin__teaser">';
		html += '<figure class="teaser__media">';
		html += '<picture class="media media--16-9">';
		html += '<img src="' + data.imageUrl + '" alt="" />';
		html += '</picture>';
		html += '</figure>';
		html += '<div class="teaser__content">';
		html += '<h2><span class="teaser__headline">' + data.title + '</span></h2>';
		html += '<p class="teaser__text">' + data.description + '</p>';
		html += '</div>';
		html += '</article>';
		html += data.subscribed?'<div><button class="button button--secondary nl-loggedin__button--abo">Abbestellen</button></div>':'<div><button class="button nl-loggedin__button--noabo">Abonnieren</button></div>';
		html += '<div><button class="button button--icon nl-loggedin__button--eval"></button></div>';
		html += '</div>';
		
		$nlWrapper.append(html);
	};
	
	
	for (var i = 0; i < newsletterData.length; i++) {
		renderChannel(newsletterData[i]);
	}
	
	
	$nlChannel = $(".nl-loggedin__abo", $nlLoggedIn);
	
    $nlChannel.each(function () {
		var $this = $(this);
		var channelId = $this.data("channelid");

		$(".nl-loggedin__button--abo", $this).on("click", function(){
			authIframe.contentWindow.postMessage({auth: {unsubscribeNewsletter: channelId}}, 'https://www.n-tv.de');
			$nlChannel.addClass("nl-loggedin__abo--wait");
			$this.removeClass("nl-loggedin__abo--wait");
			$this.addClass("nl-loggedin__abo--eval");
		});
		
		$(".nl-loggedin__button--noabo", $this).on("click", function(){
			authIframe.contentWindow.postMessage({auth: {subscribeNewsletter: channelId}}, 'https://www.n-tv.de');
			$nlChannel.addClass("nl-loggedin__abo--wait");
			$this.removeClass("nl-loggedin__abo--wait");
			$this.addClass("nl-loggedin__abo--eval");
		});

	});

	
};



window.addEventListener('message', function (e) {
	
	if ( e.origin=="https://www.n-tv.de" && e.data && e.data.nlService ) {
		
		console.log("+++ data +++");
		console.log(e.data);

		
		$nlLoggedIn.removeClass("nl-loggedin--load");
		$nlLoggedInInfo.removeClass("nl-loggedin__info--error");
		$nlLoggedInInfo.removeClass("nl-loggedin__info--success");
		
		if ($nlLoggedIn.length > 0) {
			
			// toDo: error wenn kein e.data.success oder error hinweis im json @Ursel: wie sieht das aus?
			if(e.data.nlService.success){
			
				renderNewsletter(e.data.nlService.data.areas.main[0].model.channels);
				
				if(e.data.nlService.type == 'subscribe'){
					$nlLoggedInInfo.addClass("nl-loggedin__info--success");
					$nlLoggedInInfo.html("Newsletter erfolgreich abonniert");
				}
				if(e.data.nlService.type == 'unsubscribe'){
					$nlLoggedInInfo.addClass("nl-loggedin__info--success");
					$nlLoggedInInfo.html("Newsletter erfolgreich abbestellt");
				}
				
			}else{
			
				$nlChannel.removeClass("nl-loggedin__abo--wait");
				$nlChannel.removeClass("nl-loggedin__abo--eval");
				
				
				$nlLoggedInInfo.addClass("nl-loggedin__info--error");
				
				if(e.data.nlService.type == 'getChannel') $nlLoggedInInfo.html("Beim Abruf der Daten ist ein Fehler aufgetreten");
				if(e.data.nlService.type == 'subscribe') $nlLoggedInInfo.html("Beim abonnieren ist ein Fehler aufgetreten");
				if(e.data.nlService.type == 'unsubscribe') $nlLoggedInInfo.html("Beim abmelden ist ein Fehler aufgetreten");
				
			}

		}
		
		
	}

	if ( e.origin=="https://www.n-tv.de" && e.data && e.data.auth ) {
		
		var authData = e.data.auth;
	
		if ($nlLoggedIn.length > 0) {
			if(authData.loggedIn){
				/*
				$nlLoggedIn.removeClass("nl-loggedin--load");
				$nlChannel.addClass("nl-loggedin__abo--eval");
				
				*/
				authIframe.contentWindow.postMessage({auth: {getNewsletterSubscriptions: true}}, 'https://www.n-tv.de');
			}else{
				$(".newsletter--hidden").removeClass("newsletter--hidden");
				$(".newsletter__group--na a.newsletter__login").attr("href", authData.liUrl?authData.liUrl:'');
				$nlLoggedIn.hide();
			}
		}
	}

});

/*
var $nlLoggedIn = $(".nl-loggedin");

var renderNewsletter = function(newsletterData){
	
	var renderChannel = function(data){
		
		var html = "";
		
		html += '<div class="nl-loggedin__abo" data-channelid="' + data.id + '">';
		html += '<article class="nl-loggedin__teaser">';
		html += '<figure class="teaser__media">';
		html += '<picture class="media media--16-9">';
		html += '<img src="' + data.image + '" alt="" />';
		html += '</picture>';
		html += '</figure>';
		html += '<div class="teaser__content">';
		html += '<h2><span class="teaser__headline">' + data.title + '</span></h2>';
		html += '<p class="teaser__text">' + data.description + '</p>';
		html += '</div>';
		html += '</article>';
		html += '<div><button class="button nl-loggedin__button--noabo">Abonnieren</button></div>';
		html += '<div><button class="button button--icon nl-loggedin__button--eval"></button></div>';
		html += '<div><button class="button button--secondary nl-loggedin__button--abo">Abbestellen</button></div>';
		html += '</div>';
		
		$nlLoggedIn.append(html);
	};
	
	
	for (var i = 0; i < newsletterData.length; i++) {
		renderChannel(newsletterData[i]);
	}
	
};

if ($nlLoggedIn.length > 0) {
	
	var newsletterData = [{
		title: "title",
		description: "description",
		image: "https://bilder2.n-tv.de/img/incoming/origs20314501/6840898109-w343-h192/n-tv-newsletter-bnews.jpg",
		id: "6"
	}];
	
	renderNewsletter(newsletterData);
}



var $nlChannel = $(".nl-loggedin__abo", $nlLoggedIn);
var nlHandlerBinded = false;
	
var handleNlLoggedIn = function(data){
	
	var $nlLoggedInInfo = $(".nl-loggedin__info",$nlLoggedIn);

	
	if ($nlLoggedIn.length < 1) {
		return;
	}
	
	console.log("+++ handleNlLoggedIn +++");
	console.log(data);
	
	var removeClasses = function(){
		$nlLoggedIn.removeClass("nl-loggedin--load");
		$nlLoggedInInfo.removeClass("nl-loggedin__info--load nl-loggedin__info--success nl-loggedin__info--error");
	};
	
	
	var isSubscribed = function(subscriptions, id){
		
		for (var i = 0; i < subscriptions.length; i++) {
			if(subscriptions[i].id === id){
				return true;
			}
		}
		
		return false;
		
	}

	var renderNlStatus = function(result){
		
		$nlChannel.removeClass("nl-loggedin__abo--eval");

		if(result.success){

			if(result.subscriptions){
				
				console.log(result);
				
			    $nlChannel.each(function () {
					var $this = $(this);
					if(isSubscribed(result.subscriptions, $this.data("channelid"))){
						$this.addClass("nl-loggedin__abo--abo");
					}else{
						$this.addClass("nl-loggedin__abo--noabo");
					}

				});
			}else{
				$nlChannel.addClass("nl-loggedin__abo--noabo");
			}

			
		}else{
			
			//$nlChannel.addClass("nl-loggedin__abo--wait");
			
			$nlChannel.removeClass("nl-loggedin__abo--wait");
			$nlChannel.removeClass("nl-loggedin__abo--eval");
			
			$nlLoggedInInfo.addClass("nl-loggedin__info--error");
			$nlLoggedInInfo.html("Beim Abruf der Daten ist ein Fehler aufgetreten");
		}
		
	};
	
	
	if(!nlHandlerBinded){
		
	    $nlChannel.each(function () {
			var $this = $(this);
			var channelId = $this.data("channelid");

			$(".nl-loggedin__button--abo", $this).on("click", function(){
				authIframe.contentWindow.postMessage({auth: {unsubscribeNewsletter: channelId}}, 'https://www.n-tv.de');
				$nlChannel.addClass("nl-loggedin__abo--wait");
				$this.removeClass("nl-loggedin__abo--wait");
				$this.addClass("nl-loggedin__abo--eval");
			});
			
			$(".nl-loggedin__button--noabo", $this).on("click", function(){
				authIframe.contentWindow.postMessage({auth: {subscribeNewsletter: channelId}}, 'https://www.n-tv.de');
				$nlChannel.addClass("nl-loggedin__abo--wait");
				$this.removeClass("nl-loggedin__abo--wait");
				$this.addClass("nl-loggedin__abo--eval");
			});

		});

		
		nlHandlerBinded = true;
	}
	
	removeClasses();
	
	if(data.nlSubscriptions){
		renderNlStatus(data.nlSubscriptions);
	}
	
	
	if (data.nlSubscribe){
		
		var $nlSubscribed = $("[data-channelid='" + data.nlSubscribe.id + "']", $nlLoggedIn);
		
		$nlChannel.removeClass("nl-loggedin__abo--wait");
		$nlChannel.removeClass("nl-loggedin__abo--eval");
		
		if(data.nlSubscribe.success){

			$nlSubscribed.removeClass("nl-loggedin__abo--noabo");
			$nlSubscribed.addClass("nl-loggedin__abo--abo");
			
			$nlLoggedInInfo.addClass("nl-loggedin__info--success");
			$nlLoggedInInfo.html("Newsletter erfolgreich abonniert");
		}else{
			
			$nlChannel.removeClass("nl-loggedin__abo--wait");
			$nlChannel.removeClass("nl-loggedin__abo--eval");
			
			$nlLoggedInInfo.addClass("nl-loggedin__info--error");
			$nlLoggedInInfo.html("Beim abonnieren ist ein Fehler aufgetreten");
			
		}
	}
	
	if (data.nlUnsubscribe){
		
		var $nlUnsubscribed = $("[data-channelid='" + data.nlUnsubscribe.id + "']", $nlLoggedIn);
		
		$nlChannel.removeClass("nl-loggedin__abo--wait");
		$nlChannel.removeClass("nl-loggedin__abo--eval");
		
		if(data.nlUnsubscribe.success){

			$nlUnsubscribed.removeClass("nl-loggedin__abo--abo");
			$nlUnsubscribed.addClass("nl-loggedin__abo--noabo");
			
			$nlLoggedInInfo.addClass("nl-loggedin__info--success");
			$nlLoggedInInfo.html("Newsletter erfolgreich abbestellt");
		}else{
			
		
			$nlLoggedInInfo.addClass("nl-loggedin__info--error");
			$nlLoggedInInfo.html("Beim abmelden ist ein Fehler aufgetreten");
			
		}
	}

};



window.addEventListener('message', function (e) {
	
	if ( e.origin=="https://www.n-tv.de" && (e.data && e.data.nlSubscriptions || e.data && e.data.nlSubscribe || e.data.nlUnsubscribe) ) {
		handleNlLoggedIn(e.data);
	}
	
	if ( e.origin=="https://www.n-tv.de" && e.data && e.data.auth ) {
		
		var authData = e.data.auth;
	
		if ($nlLoggedIn.length > 0) {
			if(authData.loggedIn){
				$nlLoggedIn.removeClass("nl-loggedin--load");
				$nlChannel.addClass("nl-loggedin__abo--eval");
				authIframe.contentWindow.postMessage({auth: {getNewsletterSubscriptions: true}}, 'https://www.n-tv.de');
			}else{
				$(".newsletter--hidden").removeClass("newsletter--hidden");
				$(".newsletter__group--na a.newsletter__login").attr("href", authData.liUrl?authData.liUrl:'');
				$nlLoggedIn.hide();
			}
		}
	}
});

*/