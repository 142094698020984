'use strict';


var initImageSlider = function($slider) {

	   var $sliderContainer = $slider.parents( '.slider' );
	   var sliderId = $slider.data('slider_id');
	   
	   $sliderContainer.attr( 'data-slider_container_id', sliderId );
	   
	   
	   if( !$slider.hasClass( 'slick-initialized' ) ) {
		   
		   
		   
		   $slider.on('init',function(){
			   //alert("slick ready");
			   
		   });
		   
		   $slider.slick(
		     {
		       //appendArrows: '[data-slider_container_id="' + sliderId + '"] .slider__nav',
		       appendDots  : '[data-slider_container_id="' + sliderId + '"] .slider__nav',
		       dots        : true,
		       infinite    : false,
		       mobileFirst : true,
		       nextArrow   : '<button type="button" data-role="none" class="slick--next icon icon--next-round" aria-label="Next" role="button"></button>',
		       prevArrow   : '<button type="button" data-role="none" class="slick--prev icon icon--prev-round" aria-label="Previous" role="button"></button>',
		       responsive  : [
		         {
		             breakpoint: 1279,
		             settings  : {
		               swipe: false
		             }
		         }

		       ]
		     } );
	   
	   }
};
