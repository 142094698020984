'use strict';

// https://toughengineer.github.io/demo/slider-styler/slider-styler.html

function prepareRangeSlider($rangeSlider){
	$rangeSlider.each(function(){
		
		var $this = $(this);

		$this.css('--value', $this.val());
		$this.css('--min', $this.attr("min")?$this.attr("min"):'0');
		$this.css('--max', $this.attr("max")?$this.attr("max"):'100');
		$this.on("input", function(){
			$this.css('--value', $this.val());
		});

	});
}