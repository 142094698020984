'use strict';



// polyfill the CustomEvent() constructor functionality in Internet Explorer 9 and higher
(function () {

	  if ( typeof window.CustomEvent === "function" ) return false;

	  function CustomEvent ( event, params ) {
	    params = params || { bubbles: false, cancelable: false, detail: null };
	    var evt = document.createEvent( 'CustomEvent' );
	    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
	    return evt;
	   }

	  window.CustomEvent = CustomEvent;
})();