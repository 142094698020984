'use strict';

var authCom = function(){
	

	var authIframe = document.createElement('iframe');
	
	//location.origin als url param => in handler.html origin gesetzt
	authIframe.setAttribute( 'src', 'https://www.n-tv.de/auth/handler.html?cc=2&pa_origin=' + location.origin + (ntv.urlParams.testAuth=="true"?"&testAuth=true":"") );
	authIframe.setAttribute('id', 'auth-handler');
	authIframe.style.width = "0";
	authIframe.style.height = "0";
	$("body").append(authIframe);
	window.authIframe = authIframe;
	

	
	
	var $headerAccount = $(".header-account");
	var $aboManage = $(".abo-manage__render");
	var $aboSelect = $(".abo-select");
	var $aboAd = $(".abo-ad");
	
	
	var staticLi = 	'<li><a href="https://www.n-tv.de/newsletter/">Newsletter abonnieren / verwalten</a></li><li><a href="https://serviceportal.n-tv.de/s/faq" target="_blank">Hilfe & FAQs</a></li>';
	
	var renderLoggedOut = function(data){
		
		var html = '';
		html += '<div class="header-account__login">';
		html += 	'<a href="' + (data.liUrl?data.liUrl:'') + '" class="button" target="_blank">Einloggen</a>';
		html += '</div>';
		html += '<div class="header-account__create">';
		html += 	'<a href="https://sso.guj.de/ntv_web/user/signup" target="_blank"><span>Neu hier? </span>Konto erstellen</a>';
		html += '</div>';
		html += '<ul>';
		html += 	'<li class="header-account__abo-buy"><a href="/23831248">PUR-Abo einrichten</a></li>';
		html += staticLi;
		html += '</ul>';
		
		$headerAccount.html(html);
	};
	
	
	var renderLoggedIn = function(data){
		
		var html = '';
		html += '<div class="header-account__info">';
		html += 	'<span class="header-account__name">' + (data.email?data.email:'') + '</span>';
		html += 	'<span class="header-account__abo">' + (data.abo && data.abo.name?data.abo.name:'Kein aktives Abo') + '</span>';
		html += '</div>';
		html += '<ul>';
		if(data.abo && data.abo.name){
			html += '<li><a href="/23831245">Abo verwalten</a></li>';
		}else{
			html += '<li class="header-account__abo-buy"><a href="/23831248">PUR-Abo einrichten</a></li>';
		}
		html += 	'<li><a href="https://sso.guj.de/ntv_web/user/account/overview" target="_blank">Konto verwalten</a></li>';
		html += staticLi;
		html += '</ul>';
		html += '<div class="header-account__logout"><a href="" target="_blank">Ausloggen</a></div>';
		$headerAccount.html(html);
	};
	
	
	

	
	
	var renderAboManage = function($container, data){
		
		var render = {};
		var renderFunctionName = '';
		
		render.renderDefault = function(){
			var html = '';
			var infoHtml = "";
			var aboName = data.abo.name;
			
			if(data.abo.timeEnd){
				infoHtml = '<span class="abo-manage__info abo-manage__info--canceled">gekündigt zum ' + new Date(data.abo.timeEnd).toLocaleString('de-de', {  day: '2-digit', month: '2-digit', year: 'numeric' }) + '</span>';
			}else{
			
				if(aboName=="ntv PUR-Abo"){
					infoHtml = '<span class="abo-manage__info">2,99 EUR pro Monat</span>';
				}
				if(aboName=="Kombi PUR-Abo"){
					infoHtml = '<span class="abo-manage__info">4,99 EUR pro Monat</span>';
				}
				
			}
			
			
			html += '<span class="abo-manage__name">' + aboName + '</span>';
			html += infoHtml;
			html += '<ul>';
			html += 	'<li><a href="https://serviceportal.n-tv.de/s/zahlungsdaten" target="_blank">Zahlungsdaten ändern</a></li>';
			if(aboName=="ntv PUR-Abo"){
				html += 	'<li><a href="https://shop.n-tv.de/de_DE/kombi-pur/kombi-pur-abo/4000018.html" target="_blank">Abo upgraden</a></li>';
			}
			html += 	'<li><a href="https://serviceportal.n-tv.de/s/kontakt?contactReason=K%C3%BCndigung&DPV_Kuendigung__c=mit%20Anschrift" target="_blank">Abo Kündigung beantragen</a></li>';
			html += '</ul>';
			return html;
		};
		
		
		render.renderNoAbo = function(){
			var html = '';
			html += '<span class="abo-manage__name abo-manage__name--small">Sie haben kein aktives Abo</span>';
			html += '<ul>';
			html += 	'<li><a href="/23831248">PUR-Abo einrichten</a></li>';
			html += '</ul>';
			return html;
		};
		
		render.renderNoLogin = function(){
			var html = '';
			html += '<span class="abo-manage__info">Loggen Sie sich ein, um Ihr Abo zu verwalten:</span>';
			html += '<a href="' + (data.liUrl?data.liUrl:'') + '" class="button" target="_blank">Einloggen</a>';
			html += '<div class="abo-manage__account-create">Neu hier? <a href="https://sso.guj.de/ntv_web/user/signup " target="_blank">Konto erstellen</a></div>';
			return html;
		};
		
		
		if(data.loggedIn){
			
			if(data.abo && data.abo.name){
				renderFunctionName = "renderDefault";
			}else{
				renderFunctionName = "renderNoAbo";
			}
			
		}else{
			
			renderFunctionName = "renderNoLogin";
			
		}
		
		if( render[renderFunctionName] ){
			$container.removeClass("abo-manage__render--loading");
			$aboManage.html(render[renderFunctionName]());
		}
		


	};
	
	
	
		
	$(".header__nav__account > a").on( 'click', function(e) {
		
		e.preventDefault();
		
		var $account_tab = $(this).parent(".header__nav__account");

		if ( $account_tab.hasClass("header__nav__account--open") ){
			$account_tab.removeClass("header__nav__account--open");
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'subscription',
				'eventaction': 'login_layer_close'
			});
			dataLayer.push({
				'event': 'ga4_event' ,
				'eventcategory': 'subscription',
				'eventaction': 'login_layer_close'
			});
		}else{
			$(".header__nav__search--open").removeClass("header__nav__search--open");
			$account_tab.addClass("header__nav__account--open");
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'subscription',
				'eventaction': 'login_layer_open'
			});
			dataLayer.push({
				'event': 'ga4_event' ,
				'eventcategory': 'subscription',
				'eventaction': 'login_layer_open'
			});
		}
	});
	
	$("ul a",$headerAccount).on( 'click', function( e ) {
		var label = $(this).html();
		dataLayer.push({
			'event': 'page-track' ,
			'eventcategory': 'subscription',
			'eventaction': 'login_layer_click',
			'eventlabel': label
		});
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'subscription',
			'eventaction': 'login_layer_click',
			'eventlabel': label
		});
	});
	

	window.addEventListener('message', function (e) {
		
		if ( e.origin=="https://www.n-tv.de" && e.data && e.data.auth ) {
			
			var authData = e.data.auth;

			ntvDebug(" *** auth parent get message *** ");
			ntvDebug(e.data);
			
			$headerAccount.removeClass("header-account--loading");
			
			if(authData.loggedIn){
				$(".header__nav__account").addClass("header__nav__account--loggedin");
				renderLoggedIn(authData);

				$(".header-account__logout a",$headerAccount).on( 'click', function( e ) {
					e.preventDefault();
					authIframe.contentWindow.postMessage({auth: {doLogout: true}}, 'https://www.n-tv.de');
					$headerAccount.html("");
					$headerAccount.addClass("header-account--loading");

				});
			}else{
				$(".header__nav__account").removeClass("header__nav__account--loggedin");
				document.cookie = "_ntv_laurl=" + encodeURIComponent(document.location.href) + "; path=/; domain=n-tv.de";
				renderLoggedOut(authData);
			}
			
			$(".header-account__login .button",$headerAccount).on( 'click', function( e ) {
				dataLayer.push({
					'event': 'page-track' ,
					'eventcategory': 'subscription',
					'eventaction': 'login_layer_click',
					'eventlabel': 'Ich habe bereits ein Abo'
				});
				dataLayer.push({
					'event': 'ga4_event' ,
					'eventcategory': 'subscription',
					'eventaction': 'login_layer_click',
					'eventlabel': 'Ich habe bereits ein Abo'
				});
			});
			
			$(".header-account__abo-buy a",$headerAccount).on( 'click', function( e ) {
				dataLayer.push({
					'event': 'page-track' ,
					'eventcategory': 'subscription',
					'eventaction': 'login_layer_click',
					'eventlabel': 'PUR-Abo kaufen'
				});
				dataLayer.push({
					'event': 'ga4_event' ,
					'eventcategory': 'subscription',
					'eventaction': 'login_layer_click',
					'eventlabel': 'PUR-Abo kaufen'
				});
			});
			
			
			
			
			//toDo
			
			if ($aboManage.length > 0) {
				renderAboManage($aboManage, authData);
			}
			




			if( authData.loggedIn && !authData.offerTrial){
				
				if ($aboSelect.length > 0 && $(".abo-select__trialnote", $aboSelect.length > 0)) {
						
					    $(".abo-teaser", $aboSelect).each(function () {
    						var $this = $(this);
    						var $img = $("img", $this);
    						var $a = $("a", $this);
    						$img.attr("src", $img.attr("data-srcnotrial"));
    						$a.attr("href", $a.attr("data-hrefnotrial"));
    					});
    					$(".abo-select__trialnote", $aboSelect).hide();
				}
				
				if ($aboAd.length > 0) {
						$aboAd.hide();
				}
			}else{
				
				if ($aboAd.length > 0) {
					dataLayer.push({
						'event': 'page-track' ,
						'eventcategory': 'ntv_pur_subscription',
						'eventaction': '30_day_trial_campaign',
						'eventlabel': 'notification_appeared',
						'nonInteraction': true
					});
					dataLayer.push({
						'event': 'ga4_event' ,
						'eventcategory': 'ntv_pur_subscription',
						'eventaction': '30_day_trial_campaign',
						'eventlabel': 'notification_appeared'
					});
					$(".button",$aboAd).on( 'click', function( e ) {
						dataLayer.push({
							'event': 'page-track',
							'eventcategory': 'ntv_pur_subscription',
							'eventaction': '30_day_trial_campaign',
							'eventlabel': 'button_call_to_action_clicked'
						});
						dataLayer.push({
							'event': 'ga4_event',
							'eventcategory': 'ntv_pur_subscription',
							'eventaction': '30_day_trial_campaign',
							'eventlabel': 'button_call_to_action_clicked'
						});
					});
				}
				
			}
			if ($aboSelect.length > 0){
				var aboParam = (getBreakpoint()=='xs' || getBreakpoint()=='sm')?'&onwewe=0606':'&onwewe=0605';
				
			    $(".abo-teaser", $aboSelect).each(function () {
					var $a = $("a", this);
					$a.attr("href", $a.attr("href")+aboParam);
					$a.on("click", function () {
						
						var label = "click_add-to-cart_ntv-pur-abo";
						if($a.parents(".abo-teaser").hasClass("abo-teaser--kombi")){
							label = "click_add-to-cart_kombi-pur-abo";
						}
						dataLayer.push({
							'event': 'page-track' ,
							'eventcategory': 'ntv_pur_subscription',
							'eventaction': 'page_subscription-overview',
							'eventlabel': label
						});
						dataLayer.push({
							'event': 'ga4_event' ,
							'eventcategory': 'ntv_pur_subscription',
							'eventaction': 'page_subscription-overview',
							'eventlabel': label
						});
					});
				});
			}
	    
		}
		
	});
};

