'use strict';


var USFauthorizedUrl  = "";
var USFajaxBaseUrl    = "https://kursdaten.n-tv.de/teleboerse/webelements/";
var USFcheckCode      = "bXLweuvf8jBavae";
var USFajaxCustomerId = "3077";
var USFajaxConfig     = new Array;





var vwdEmbed = function( $vwdContainer ) {


	ntvDebug("*** vwdEmbed ***");
	ntvDebug(new Date().getTime());

	var $ajaxContainers = $vwdContainer.find( '[data-ajax_url]' );
	
	if( ntv.chartPage && ntv.chartPage.instrumentCategory.code == 'FUND' && !ntv.chartPage.instrumentCategory.fundsservice ){
		$ajaxContainers = $vwdContainer.find( '[data-ajax_url]:not([data-fundsservice])' );
	}
	



	var embed = function( $ajaxContainer, index ) {

		var ajaxUrl = $ajaxContainer.data( 'ajax_url' );


		if( $ajaxContainer.data( 'vwd_params' ) ) {
			switch( $ajaxContainer.data( 'vwd_params' ) ) {
				case 'lastSelected':
					if( getCookie( 'lastChartPages' ) ) ajaxUrl += '&cookie=' + getCookie( 'lastChartPages' );
					break;
				case 'search':
					ajaxUrl += window.location.search;
					break;
			}
		}
		else {
			if( ntv.chartPage && ntv.chartPage.instrumentId ) ajaxUrl += '&i=' + ntv.chartPage.instrumentId;
		}

		ntvDebug( '*** vwd ajaxUrl ***' );
		ntvDebug( ajaxUrl );
		ntvDebug(new Date().getTime());


		var ajax = $.ajax( {
			type: 'get',
			dataType: 'html',
			url: ajaxUrl
		} );
		ajax.done( function( data, textStatus, jqXHR ) {

			// quick fix for chart performance, remove if it fixed by vwd
			//data = data.replace ('"dataGrouping":{"enabled":false}', '"dataGrouping":{"enabled":true}');
			
			$ajaxContainer.html( data );
			//$vwdContainer.show();
			$vwdContainer.css("visibility","visible");

		} );

		ajax.always(function() {

			if( index==0){


				if($ajaxContainer.data("force_chart")){

					ntvDebug("*** force_chart ***");
					ntvDebug(new Date().getTime());

					var checkCount = 0;

					var checkChart = function(){

						if($("svg", $ajaxContainer).length>0 || checkCount > 20 ){
							clearInterval(checkInterval);

							$ajaxContainers.each( function(i) {
					    		if(i>0){
					    			embed( $(this), i );
					    		}
					    	} );
						}

						checkCount += 1;

					};

					var checkInterval = window.setInterval(checkChart,100);

				}else{

					$ajaxContainers.each( function(ind) {
			    		if(ind>0){
			    			embed( $(this), ind );
			    		}
			    	} );

				}

			}


		});
		ajax.fail( function( jqXHR, textStatus, errorThrown ) {
			//console.log( jqXHR, textStatus, errorThrown );

		} );
	}



	var startEmbed = function(){
		$ajaxContainers.each( function(index) {
    		if(index==0){
    			embed( $(this), index );
    		}
    	} );
	}




	/*

	var $pushFrame = $("body").find( '[data-force_pushframe]' );

	if( $pushFrame.length>0 && $pushFrame.attr('data-pushframe_loaded')=='false' ){

		$pushFrame.on("load", function(){
			startEmbed();
		});

	}else{
		startEmbed();
	}
	
	*/
	
	startEmbed();
	
	
	if( ntv.chartPage && ntv.chartPage.instrumentCategory.code == 'FUND' && !ntv.chartPage.instrumentCategory.fundsservice ){
	
		$vwdContainer.append('<div style="margin-bottom: 10px; font-size: 1.4rem;">Dieser Fonds nimmt nicht am <a href="https://www.vwd.com/fundsservice.html" target="_blank" style="color: #be0028;">vwd fonds service</a> teil.</div>');
		
	}

};








var writeLastChartPagesCookie = function( arbitrageId ) {

	var lastChartPages = getCookie( 'lastChartPages' );
	var lastChartPagesArray = [];

	if( lastChartPages ) {
		lastChartPagesArray = lastChartPages.split( ',' );
	}

	var indexOfId = lastChartPagesArray.indexOf( arbitrageId );

	if( indexOfId > -1 ) {
		lastChartPagesArray.splice( indexOfId, 1 );
	}

	lastChartPagesArray.unshift( arbitrageId );

	lastChartPages = (lastChartPagesArray.slice( 0, 6 )).toString();

	ntvDebug( '*** vwd writeLastChartPagesCookie ***' );
	ntvDebug( lastChartPages );

	var cookieExpireTime = 3600 * 24 * 60 * 60 * 1000 + (new Date()).getTime();
	var cookieExpireDate = new Date( cookieExpireTime );

	document.cookie = 'lastChartPages=' + lastChartPages + '; expires=' + cookieExpireDate + '; path=/; domain=' + (document.location.host).split( '.' )[ 1 ] + '.de';

};


