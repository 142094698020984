'use strict';

var boerseNav = function () {

$(".teaser-cert-filter").each(function () {

    var $nav = $(".nav > li > a", this),
        $dataWrapper = $(".data-wrapper", this),
        activeClass = "active";

    // load new data
    function load(trigger, e) {

        var url = $(trigger).data("href");

        $.ajax({
            url: url,
            success: function (data) {
                $dataWrapper
                    .html(data)
                    .find(".img.icon")
            }
        })
    }

    $nav.on("click", function (e) {
        e.preventDefault();

        var idx = $nav.index(this);

        // set active top-level-nav element
        $nav
            .removeClass(activeClass)
            .eq(idx)
            .addClass(activeClass);

        var $subs = $(this).next("ul").find("a");

        if ($subs.length > 0 && !$subs.hasClass(activeClass)) {
            // trigger click on first sub-link if 
            // there are sublinks
            // none is active
            $subs
                .eq(0)
                .trigger("click");
        }
        else if ($subs.length > 0) {
            // trigger click on active sublink
            $subs
                .filter("." + activeClass)
                .trigger("click");
        }
        else {
            // no sublinks. load data from this href
            load(this, e);
        }
    })

    $nav.each(function () {
        var $subs = $(this).next("ul").find("a");

        // add sib-navi click eventhandler
        $subs.on("click", function (e) {
            e.preventDefault();

            var idx = $subs.index(this);

            $subs
                .removeClass(activeClass)
                .eq(idx)
                .addClass(activeClass);

            // laod data from this href
            load(this, e);
        });
    });
    
   });
}
