'use strict';



var embedSocialVendors = function(ntvSocialVendor){
	
	ntvDebug("*** embedSocialVendors ***");
	
	var $elems = $("[data-extcontent_type]");
	
	if(typeof ntvSocialVendor !== 'undefined'){
		var $elems = $("[data-extcontent_type=" + ntvSocialVendor + "]");
	}
	
	
	// external content
	$elems.each(function () {

		var $container = $(this);

		if(ntv.consents[$container.data("extcontent_type")] || ntv.urlParams.token){
			
			if ( $container.hasClass("extcontent__note") ){
				
				$container.css("min-height", $container.outerHeight() + "px");
				$container.html("");
				$container.removeClass("extcontent__note");
				$container.addClass("extcontent__loading");
	
			}
			
			
			if( typeof(window["renderExtContent_"+$container.data("extcontent_type")]) === "function" ){
				window["renderExtContent_"+$container.data("extcontent_type")]($container);
			}
		}else{
			$container.addClass("extcontent__note");
			renderExtContent_noConsent($container);
		}
	});
	
	
};

var storeSocialConsent = function(ntvSocialVendor){
	
	if(ntv.pageInfo.pmode){
		// sessioncookie
	    document.cookie = ntvSocialVendorsCookie[ntvSocialVendor] + "=true; path=/; domain=n-tv.de";
	}else{
	
		__tcfapi('postCustomConsent', 2, function (tcData, success) {
			
	    	ntvDebug("*** __tcfapi postCustomConsent  ***");
	    	ntvDebug(tcData);
			
	    }, [ ntvSocialVendors[ntvSocialVendor] ], socialPurposeIds, []);
		
	}


	ntv.consents[ntvSocialVendor] = true;
	embedSocialVendors(ntvSocialVendor);
	
};



var extContentConfig = {
		
		'facebookpost': {
			'name': 'Facebook',
			'icon_url': resourceBaseUrl + '/adaptive/images/icons/icon__fb_brandcolor.svg'
		},
		'facebookvideo': {
			'name': 'Facebook',
			'icon_url': resourceBaseUrl + '/adaptive/images/icons/icon__fb_brandcolor.svg'
		},
		'instagram': {
			'name': 'Instagram',
			'icon_url': resourceBaseUrl + '/adaptive/images/icons/icon__instagram_brandcolor.svg'
		},
		'tiktok': {
			'name': 'TikTok',
			'icon_url': resourceBaseUrl + '/adaptive/images/icons/icon__tiktok_brandcolor.svg'
		},
		'twitter': {
			'name': 'Twitter',
			'icon_url': resourceBaseUrl + '/adaptive/images/icons/icon__twitter__grey.svg'
		},
		'youtube': {
			'name': 'YouTube',
			'icon_url': resourceBaseUrl + '/adaptive/images/icons/icon__youtube_brandcolor.svg'
		}
};


var renderExtContent_noConsent = function($container){
	
	var html = '<img src="' + extContentConfig[$container.data("extcontent_type")].icon_url + '">';
	html += '<strong>Empfohlener Inhalt</strong>';
	
	if(ntv.pageInfo.pmode){
		html += '<p>Auf Grund ihrer Privatsphären-Einstellungen können wir an dieser Stelle keine Inhalt der externen Plattform ' + extContentConfig[$container.data("extcontent_type")].name + ' anzeigen. Um diesen (und weitere) Inhalt(e) für Ihre laufende Sitzung anzuzeigen, klicken Sie bitte auf "Inhalte von ' + extContentConfig[$container.data("extcontent_type")].name + ' anzeigen". Ihre Zustimmung wird bis zum Schließen des Browsers mit einem Cookie gespeichert und danach verworfen. Im Falle einer Zustimmung erfolgt evtl. ein Datentransfer und eine Datenverarbeitung außerhalb des EWR (Art. 49 (1) (a) DSGVO, Drittlandtransfer), wo das hohe europäische Datenschutzniveau nicht besteht. Sie stimmen diesem Drittlandtransfer ebenfalls zu.</p>';
	}else{
		html += '<p>Aufgrund Ihrer Privacy Einstellungen können wir an dieser Stelle keinen Inhalt der externen Plattform ' + extContentConfig[$container.data("extcontent_type")].name + ' anzeigen. Sie können sich den Inhalt mit einem Click wieder anzeigen lassen und im <a href="https://www.n-tv.de/15745191">Privacy Center</a> weitere Einstellungen anpassen.</p>';
	}
	
	html += '<button class="button">Inhalt von ' + extContentConfig[$container.data("extcontent_type")].name +' anzeigen</button>';

	$container.html(html);
	
	$('button', $container).on('click', function() {
		storeSocialConsent($container.data("extcontent_type"));
		return false;
	});
	$('a', $container).on('click', function() {
		_sp_.loadPrivacyManagerModal(152368);
		return false;
	});
	
};


var renderExtContent_tiktok = function($container){

	if( $container.data("extcontent_url") ){

		var script_src = "//www.tiktok.com/embed.js";
		if( $( "script[src='" + script_src + "']" ).length < 1 ){
			loadScript(script_src);
		}
		
		$.ajax({
			url: $container.data("extcontent_url")
		})
		.done( function( data ) {
				console.log(data);
				if(data.html){
					$container.html(data.html);
				}
		});
		
	}

};

var renderExtContent_twitter = function($container){

	if( $container.data("extcontent_url") ){
		
		$container.html('<blockquote class="twitter-tweet"><a href="' + $container.data("extcontent_url") + '"></a></blockquote>');

		var script_src = "//platform.twitter.com/widgets.js";
		if( $( "script[src='" + script_src + "']" ).length < 1 ){
			loadScript(script_src);
		}
	}

};


var renderExtContent_facebookpost = function($container){

	if( $container.data("extcontent_url") ){

			$container.html('<div id="fb-root"></div><div class="fb-post" data-href="' + $container.data("extcontent_url") + '" style="width: 350px;"></div>');
			
			var script_src = "//connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v2.3";
			if( $( "script[src='" + script_src + "']" ).length < 1 ){
				loadScript(script_src);
			}
	}
};


var renderExtContent_facebookvideo = function($container){

	if( $container.data("extcontent_url") ){
		
		$container.html('<div id="fb-root"></div><div class="fb-video" data-href="' + $container.data("extcontent_url") + '" style="width: 350px;"></div>');
		
		var script_src = "//connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v2.3";
		if( $( "script[src='" + script_src + "']" ).length < 1 ){
			loadScript(script_src);
		}
	}
};


var renderExtContent_instagram = function($container){

	if( $container.data("extcontent_url") ){
		
		$.ajax({
			//url: $container.data("extcontent_url") + "&access_token=286044938272486|c754c2048e6866bb656805d5066ad612"
			url: $container.data("extcontent_url")
		})
		.done( function( data ) {
				$container.html(data.html);
		});
	}
};



/*
var renderExtContent_instagram = function($container){

	if( $container.data("extcontent_url") ){
		
		$container.html('<blockquote class="instagram-media" ' + ($container.data("instgrm_caption")=='show'?'data-instgrm-captioned ':'') + 'data-instgrm-permalink="' + $container.data("extcontent_url") + '?utm_source=ig_embed&amp;utm_campaign=loading" data-instgrm-version="13" style="background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);"></blockquote>');
		
		var script_src = "https://www.instagram.com/embed.js";
		if( $( "script[src='" + script_src + "']" ).length < 1 ){
			loadScript(script_src);
		}
	}
};
*/


var renderExtContent_youtube = function($container){

	if( $container.data("extcontent_url") ){
		
		$container.html('<div class="extcontent__framewrapper"><iframe src="' + $container.data("extcontent_url") + '" frameborder="0" allowfullscreen></iframe></div>');

	}
};




/*

var renderExtContent_default = function($container){
	
	var extContentFallback = {
			"extcontent--youtube": "<a>schalten Sie youTube an!</a>"
	}
	
	var $container;
	var html_noconsent = "<a>schalten Sie youTube an!</a>";
	var html;

		console.log($container.html());
    	
	   	if(ntv.consents.ga){
	
	   		html = $container.html();
	   		html = html.replace(/<!--start_embed/g, "");
	   		html = html.replace(/end_embed-->/g, "");
	   		
	   		 
		}else{
			

			html = html_noconsent;
			
		}
	   	
	   	$container.html(html);
};

*/