'use strict';

var getFitImgWidth = function($img){

    var image_sizes = [
    	146, //5kb
    	320, //16kb
    	470, //32kb => neu!!!
    	750, //66kb
    	1136, //115kb
    	1536 //200kb
    ];

    var img_width = $img.width();
    var fit_width = 0;

    for (var i = 0; i < image_sizes.length; i++) {
    	if(image_sizes[i]>=img_width || i==image_sizes.length-1){
    		fit_width = image_sizes[i];
    		break;
    	}
    }

    return fit_width;
}

var getFitImgRatio = function($img){
	return $img.data("ratio_" + getBreakpoint())?$img.data("ratio_" + getBreakpoint()):$img.data("ratio");
}


var fitImgSrc = function($img){

    var fit_ratio = getFitImgRatio($img);
    var fit_width = getFitImgWidth($img);

    if( fit_width > 0 ){
    	$img.attr("data-src", $img.data("base") + "/" + fit_ratio + "/" + fit_width + "/" + $img.data("name"));
    	$img.data("actualWidth", fit_width);
    	$img.data("actualRatio", fit_ratio);
    }
}


/*
document.addEventListener('lazybeforeunveil', function(e){
	var $img = $(e.target);
	if( $img.data("base") && $img.data("name") ){
		fitImgSrc($img);
	}
});
*/


window.addEventListener('resizeReady', function(e){
	$('img.lazyloaded[data-base][data-name]').each( function(){
		var $img = $(this);
		// reset lazyload if we need a higher resolution or an other ratio
		if( getFitImgWidth($img) > $img.data("actualWidth") || getFitImgRatio($img) != $img.data("actualRatio") ){
			ntvDebug("*** reset image by resize ***");
			ntvDebug( $img.data("name"));
			$img.removeClass("lazyloaded");
			$img.addClass("lazyload");
		}
	});
});