'use strict';

var getEmsSiteType = function getEmsSiteType() {
	var siteType = 'mobile';
	var siteTypeMap = {
		xs: 'mobile',
		sm: 'tablet',
		md: 'desktop',
		lg: 'desktop'
	};

	if( siteTypeMap[ getBreakpoint() ] ) {
		siteType = siteTypeMap[ getBreakpoint() ];
	}
	return siteType;
};


var getEmsSize = function (){
	switch(getBreakpoint()) {
		case "xs":
			return "ntv_size_s";
			break;
		case "sm":
			return "ntv_size_m";
			break;
		case "md":
			return "ntv_size_l";
			break;
		case "lg":
			return "ntv_size_xl";
			break;
		default:
			return "";
	}
};
