"use strict";

//activated debug-logging if url-param ntvDebug
if (typeof ntv !== 'undefined') {
  if (ntv.urlParams.ntvDebug && window.localStorage) localStorage.setItem('ntvDebug', 'true');
}


/* Returns the actual viewport width */
if (!window.getVw) {
  window.getVw = function () {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  };
}

// function to get parameter from the url
if (!window.getParameterByName) {
  window.getParameterByName = function (name /*, url*/) {
    name = name.replace(/[\[\]]/g, '\\$&');

    //		if( !url ) {
    var url = window.location.href;
    //		}

    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);

    if (!results) {
      return null;
    } else if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };
}



// function to load script
window.loadScript = function(src){
	
	  var s = document.createElement("script");
	  s.async = true;
	  s.src = src;
	  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0] || document.getElementsByTagName('script')[0].parentNode).insertBefore(s,null);	
	
	
};


/* Returns the orientation (portrait/landscape) of actual viewport */
window.getOrientation = function () {
	return window.matchMedia("(orientation: portrait)").matches?"portrait":"landscape";
};



