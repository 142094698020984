'use strict';

var author = function( $author ) {

	/* Author box is seen:
	 *   - XS: on top of the content + under the header when sticky
	 *   - SM, Md and LG: in the sidebar (not visible on XS)
	 * The authorbox is pagebuilt only in the sidebar, so we need to clone it in the top of the content
	 * and under the navigation when its becomes sticky (the bar appears under).
	 */

	/* OPEN / CLOSE SITCKY AUTHOR-BOX */

	// STICKY AUTHOR-BOX - We clone the author box from the sidebar into the content and the header
	$author.clone()
				 .prependTo( '.content' )
				 .clone()
				 .appendTo( '.header' )
				 .on( 'click', '.icon', function() {
					 // content of the sticky author-box slides up and down when we click on the icon
					 $( '.header .author__content' ).slideToggle( 'fast' );
					 // the icon has to turn too
					 $( this ).toggleClass( 'icon--flipped' );
				 } );

	// At first we hide the content of the sticky author-box, it appears only when we click on the icon
	$( '.header' ).find( '.author__content' )
								.hide();

	/* Scroll direction detection
	 * On mobile, when we scroll down the author-box disappears under the nav. It reappears if we scroll up.
	 * And in any case, if a scroll event happens, the content of the sticky author-box slides up
	 * For Tablet and desktop, the author box is
	 */
	var lastScrollTop = 0;
	var $sidebar = $( '.sidebar--author' );
	var $headerAuthor = $( '.header .author' );
	var $headerAuthorContent = $headerAuthor.find( '.author__content' );

	var onScroll = function() {

		var st = $( this ).scrollTop();

		// We check in which direction the scroll had been made
		if( st > lastScrollTop ) {
			// Scroll down
			if( !$headerAuthor.hasClass( 'author--hidden' ) ) {
				$headerAuthor.addClass( 'author--hidden' );
			}
		}
		else {
			// Scroll up
			if( $headerAuthor.hasClass( 'author--hidden' ) ) {
				$headerAuthor.removeClass( 'author--hidden' );
				$headerAuthorContent.hide();
			}
		}

		// We close the author infos if we scroll
		if( $headerAuthorContent.is( ':visible' ) ) {
			$headerAuthorContent.slideUp( 'fast' );
		}
		lastScrollTop = st;

		/* Author box must never go under the content's bottom */
		var contentH = $sidebar.parents( '.row' ).find( '.content' ).innerHeight(); // Content height
		var $author = $sidebar.find( '.author' ); // Author-box element
		var scrollTop = $( window ).scrollTop();

		// If the height of the box > visible part of the content,
		// it means the author box will go under the content, and probably overlays what's under;
		// As we don't want this, we push up the author box that it never goes under the content
		var diff = contentH - scrollTop - $author.innerHeight();
		if( diff < 0 ) {
			// author box is lower than the content, we push it up of the difference
			// $author.css( { 'margin-top': diff } )
		}
		else {
			// content is still
			$author.removeAttr( 'style' );
		}

	};

	// We trigger it only if we are on an author page
	if( $sidebar.length > 0 ) {
		$( window ).scroll( onScroll );
		onScroll();
	}

};
