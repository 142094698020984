'use strict';

var menu = function() {
  var $nav = $( '.header__nav' );
  var $lvl1 = $( '.header__nav__lvl1 .scrollbar > ul > li' );   // Nav first level
  var $lvl2Element = $nav.find( '.header__nav__lvl2 > li' );    // Nav second level elements (li)
  var $lvl3Element = $nav.find( '.header__nav__lvl3 > li' );    // Nav third level elements (li)
  var $headerSearch = $( '.header__nav__search' );              // Search button (li)
  var lvl1ActiveH = 0;                                          // Stores the height of the menu
  var scrollbar = null;                                         // Will contain the instance of PerfectScrollbar
  var $scrollbar = $( '.scrollbar' );                           // Custom scrollbar class
  var lvl2Active;												//Shows if a lvl2 Element is actively changing the submenus height
  var moveDelayLvl1 = null;
  var moveDelayLvl2 = null;
  var delay = 100;                                              // Delay before closing nav/ change nav element


 /*
  if(ntv.urlParams.navDelay){
	  delay = parseInt(ntv.urlParams.navDelay);
  }
*/


  /*
   * SEARCH BUTTON
   */


  $headerSearch.find( 'a' ).on( 'click', function(e) {
	  
	  	e.preventDefault();
		
		var $search_tab = $(this).parent(".header__nav__search");

		if ( $search_tab.hasClass("header__nav__search--open") ){
			$search_tab.removeClass("header__nav__search--open");

		}else{
			$(".header__nav__account--open").removeClass("header__nav__account--open");
			$search_tab.addClass("header__nav__search--open");
			$headerSearch.find( "input[type='text']" ).focus();
		}

	});
  
  
  /*
  $headerSearch.find( 'a' ).on( 'click', function( e ) {
    e.preventDefault();
    $headerSearch.addClass( 'visible' );
    $headerSearch.find( "input[type='text']" ).focus();
  } );

  $headerSearch.find( '.icon__close' ).on( 'click', function() {
    $headerSearch.removeClass( 'visible' );
  } );
  */

 $( '.header__nav__submenus' ).mousemove( function(){
	  clearTimeout( moveDelayLvl2 );
	  moveDelayLvl2 = setTimeout( function(){
		  submenu();
	  }, delay );
 });

 $( '.scrollbar' ).mousemove(function(){
	  clearTimeout( moveDelayLvl1 );
	  var localDelay = delay;
	  if($( '.li--active' ).length == 0 ){
		  localDelay = 0;
	  }else{
		  localDelay = delay;
	  }
	  moveDelayLvl1 = setTimeout( function(){
		  lvl1Menu();
	  }, localDelay );
});

 var lvl1Menu = function (){
	 var hoverElement = $( 'li :hover' ).parent( 'li' );

	 if(hoverElement.length > 0){
		 $( '.li--active' ).removeClass( 'li--active' );
		 hoverElement.addClass( 'li--active' );
	 }
	 else{
		 $( '.li--active' ).removeClass( 'li--active' );
	 }
 };

 var submenu = function(){
 	 var isBurger = (getBreakpoint() == "sm" || getBreakpoint() == "xs");
	 var hoverElement = $( 'li :hover' ).children( '.header__nav__element' ).parent().last();
	 if( hoverElement.length > 0 ){

		 if( hoverElement != undefined && hoverElement.parent()[ 0 ].classList.contains( 'header__nav__lvl2' ) ){
			  $( '.header__nav__submenus' ).removeAttr( 'style' );
			  $( '.li--lvl2--active' ).removeClass( 'li--lvl2--active' );
			  $( '.li--lvl3--active' ).removeClass( 'li--lvl3--active' );
			  var $lvl2 = $( hoverElement ).parent();
			  var lvl2Padding = $lvl2.innerHeight() - $lvl2.height() + parseInt( $lvl2.css("margin-top") );
			  $( hoverElement ).addClass( 'li--lvl2--active' );
			  var lvl3Children = $( '.li--lvl2--active > .header__nav__lvl3 > li' );
			  if( lvl3Children.length > 0 ){
		  		if( lvl3Children.length * ( lvl3Children.height() + 1 ) + lvl2Padding > hoverElement.parents('.header__nav__submenus').height() && !isBurger ){
		  			$( '.header__nav__submenus' ).height( lvl3Children.length * ( lvl3Children.height() + 1 ) + lvl2Padding );
		  		}
		      }
			  return;
		 }
		 if( hoverElement != undefined && hoverElement.parent()[ 0 ].classList.contains( 'header__nav__lvl3' ) ){
			$( '.header__nav__submenus' ).removeAttr( 'style' );
			$( '.li--lvl3--active' ).removeClass( 'li--lvl3--active' );
			$( hoverElement ).addClass('li--lvl3--active');
			 var $lvl2 = $( hoverElement ).parents( '.header__nav__lvl2' );
			 var lvl2Padding = $lvl2.innerHeight() - $lvl2.height() + parseInt( $lvl2.css("margin-top") );
			 var lvl3Children = $( '.li--lvl2--active > .header__nav__lvl3 > li' );
			 var lvl4Children = $( '.li--lvl3--active > .header__nav__lvl4 > li' );

			 if(lvl3Children.length * ( lvl3Children.height() + 1 ) + lvl2Padding > hoverElement.parents('.header__nav__submenus').height() && !isBurger ){
				 $( '.header__nav__submenus' ).height( lvl3Children.length * ( lvl3Children.height() + 1 ) + lvl2Padding );
			 }
			 if( lvl4Children.length > 0 ){
	     		if( lvl4Children.length * ( lvl4Children.height() + 1 ) + lvl2Padding > hoverElement.parents('.header__nav__submenus').height() && !isBurger ){
	     			$( '.header__nav__submenus' ).height( lvl4Children.length * ( lvl4Children.height() + 1 ) + lvl2Padding );
	     		}
	         }
			 return;
		 }
	 }
	 else{
		 $( '.header__nav__submenus' ).removeAttr( 'style' );
		 $( '.li--lvl2--active' ).removeClass( 'li--lvl2--active' );
		 $( '.li--lvl3--active' ).removeClass( 'li--lvl3--active' );
	 }

 };
  /*
   * STICKY HEADER
   */


 /*
  var stickyHeaderHandler = function() {
    var headerHeight = $( '.header' ).height();
    var pageScrollTop = $( 'html' ).scrollTop();


    var containerTop = getVw() < 1280 ? 0 : window.$body.find( '.container' ).offset().top;

    if( pageScrollTop > headerHeight + 200 ) {
      if( !window.$body.hasClass( 'sticky' ) ) {
        window.$body.addClass( 'sticky' );
        $nav.removeClass( 'header__nav--open' )
            .find( '.active' )
            .removeClass( 'active ' );
      }
    }
    else if( pageScrollTop <= containerTop && window.$body.hasClass( 'sticky' ) ) {
      window.$body.removeClass( 'sticky' );
    }
  };


	  
  if( !ntv.urlParams.testHeaderSticky ){
	  if ( getBreakpoint() == 'lg' || getBreakpoint() == 'md' ){
		  $( window ).scroll( stickyHeaderHandler );
		  stickyHeaderHandler();
	  }
  }
  */
  


  /*
   * NAV for xs and sm (click on burger)
   */
  $nav.on( 'click', '.header__burger', function( e ) {
        e.preventDefault();

        $( this ).parent()
                 .toggleClass( 'header__nav--open' )
                 .find( '.active' )
                 .removeClass( 'active' );

        // We need to create or update the scrollbar for XS and SM
        // Or destroy it for MD and LG
        resizeHandler();
      } )
      .find( '.header__nav__element' )
      .on( 'click', function( e ) {
//        console.log( e.target );
        var $elem = $( e.target );

        // A click can be on the 'a' tag, or on the span inside
        // We leave 'a' tags redirecting to their link
        if( !$elem.is( 'a' ) && !$elem.parent().is( 'a' ) ) {
          // The menu has no 'a' 'tag, we check if it has an icon (if so, it has submenus)
          if( $elem.closest( '.header__nav__element' ).find( '.icon' ).length ) {
            // Menu has submenus, we open or close them
            var $liElement = $elem.closest( 'li' );
            if( $liElement.hasClass( 'active' ) ) {
              // click on the currently opened element:
              // we remove the active class from it, and to all its submenus too
              $liElement.removeClass( 'active' )
                        .find( '.active' )
                        .removeClass( 'active' );
            }
            else {
              $liElement.addClass( 'active' )
                        .siblings()
                        .removeClass( 'active' );
            }

            // We need to create or update the scrollbar for XS and SM
            // Or destroy it for MD and LG
            resizeHandler();
          }
        }
      } );

  /*
   * Scrollbar on XS and SM when menu height > vh
   */
  var resizeHandler = function() {
    // We need to update the scrollbar of the menu
    if( scrollbar && $scrollbar.hasClass( 'ps' ) ) {
      scrollbar.update();
    }

    if( window.innerWidth < 1280 ) {                  // XS or SM
      if( $scrollbar.length > 0 && $scrollbar.find( '.ps__rail-y' ).length < 1 ) {
        // Scrollbar has not been initialized yet, we do it
        scrollbar = new PerfectScrollbar( '.scrollbar', {
          suppressScrollX: true
        } );
      }
    }
    else {                                             // MD or LG
      if( $scrollbar.hasClass( 'ps' ) ) {
        scrollbar.destroy();
      }
    }

  };
  $( window ).resize( resizeHandler );
  resizeHandler();

};


