'use strict';

var infographicSwitch = function(){
	
	var renderSwitch = function(e){

		var container = (e.currentTarget).closest(".infografik--switch"),
			select = container.querySelector("select"),
			options = select.querySelectorAll("option"),
			optionsCount = options.length,
			selectedIndex = 0,
			switchToIndex = 0,
			iframe = container.querySelector("iframe"),
			prev = container.querySelector(".infografik__prev"),
			next = container.querySelector(".infografik__next"),
			eventTarget = e.currentTarget;
		


		
		if(eventTarget===select){
			iframe.src = select.value;
		}

		if(eventTarget===next || eventTarget===prev){
			
			selectedIndex = select.selectedIndex;
			
			if(eventTarget===next){
				switchToIndex = selectedIndex<optionsCount-1?(selectedIndex+1):0;
			}else{
				switchToIndex = selectedIndex>0?(selectedIndex-1):optionsCount-1;
			}

			options[selectedIndex].removeAttribute('selected');
			options[switchToIndex].setAttribute('selected', 'selected');
			iframe.src = select.value;
		}
		
		if( eventTarget.tagName==='LI' && eventTarget.getAttribute('data-src') ){
			iframe.src = eventTarget.getAttribute('data-src') ;

			for (var i = 0; i < tabs.length; i++) {
				tabs[i].classList.remove("infografik__select__active");
			}

			eventTarget.classList.add("infografik__select__active");
		}
	};
	

	// wird gebraucht, weil sonst iframes mit der gleichen src in der Seite sind und das resize der iframes nicht mehr funktioniert
	var prepareGraphicDouble = function(){
		
		var graphicsDouble = document.querySelectorAll(".infografik--double"),
			graphicDouble = null,
			selectContainer = null,
			iframes = null,
			iframe = null;

		for (var i = 0; i < graphicsDouble.length; i++) {
			
			graphicDouble = graphicsDouble[i];
			selectContainer = graphicDouble.querySelector(".infografik__select");
			iframes = graphicDouble.querySelectorAll("iframe");
			
			
			if( getComputedStyle(selectContainer).display!=='hidden' && getComputedStyle(selectContainer).display!=='none' ){
				for (var i = 0; i < iframes.length; i++) {
					
					iframe = iframes[i];
					
					if( !(iframe.getAttribute('data-src') && iframe.getAttribute('data-src')!=='') ){
						iframe.setAttribute('data-src', iframe.src);
					}
					
					if(i>0){
						iframe.src = '';
					}
				}
			}else{
				for (var i = 0; i < iframes.length; i++) {
					
					iframe = iframes[i];
					
					if( iframe.getAttribute('data-src') && iframe.getAttribute('data-src')!=='' ){
						iframe.src = iframe.getAttribute('data-src');
					}
				}
			}
		}
	}
	
	var selectFields = document.querySelectorAll(".infografik--switch select"),
		tabs = document.querySelectorAll(".infografik--switch li"),
		buttons = document.querySelectorAll(".infografik--switch button");
	
	
	if(document.querySelector(".infografik--double")){
		
		prepareGraphicDouble();
		
		window.addEventListener("resize", prepareGraphicDouble);
		
		
	}

	for (var i = 0; i < selectFields.length; i++) {
		selectFields[i].addEventListener ("change", function (e) {
			renderSwitch(e);
		});
	}
	
	for (var i = 0; i < buttons.length; i++) {
		buttons[i].addEventListener ("click", function (e) {
			renderSwitch(e);
		});
	}
	
	for (var i = 0; i < tabs.length; i++) {
		tabs[i].addEventListener ("click", function (e) {
			renderSwitch(e);
		});
	}
};