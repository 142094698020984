'use strict';

var quiz = function( $quiz ) {
  var $answerButtons;

  var getAnswerButtons = function() {
    $answerButtons = $quiz.find( 'button[type="submit"]' );
    $answerButtons.on( 'click', function( evt ) {
      evt.preventDefault();
      var $button = $( evt.currentTarget );
      var value = $button.attr( 'value' );
      fetchQuiz( value );
    } )
  };

  var fetchQuiz = function( value ) {
    var url = $quiz.find( 'form' ).attr( 'action' );
    var $inputValues = $quiz.find( 'input[type="hidden"]' );
    var data = {};

    // We don't have a value on "next question"
    if( value ) {
      data.answer = value;
    }

    // We add the values of the hidden input
    $inputValues.each(function (index, elem) {
      data[elem.name] = elem.value;
    });

    $.ajax({
      type: 'POST',
      url: url,
      data: data,
      dataType: 'html'
    })
      .done(function (res) {
        onFetchSuccess(res);

      $quiz.find( '.quiz__evaluation__btn' ).on( 'click', function() {
        $('.quiz__evaluation__btn').toggleClass('open');
      });

      })
      .fail(function (jqXHR, textStatus) {
        console.error('Request failed: ' + textStatus);
      });
  };

  var onFetchSuccess = function( res ) {
    $quiz.html( res );
    getAnswerButtons();
  };

  getAnswerButtons();
};
