'use strict';

var deviceDetection = function () {
  var $shareContainer = $('.article__share');  // container for share icons
  var $breakingNews = $('.bnews__more'); // container für breakingNews
  var isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  var isAndroid = /(android)/i.test(navigator.userAgent);
  var isMobile = isiOS || isAndroid;
  var teaserAppBanner = $('.teaser__appbanner');

  // only shows Element when mobile device, use the id of the element
  var showOnMobile = function ($element, $mobileDevice, $display, $breaking) {
    var display = $display ? $display : 'block';
    var isBreaking = $breaking ? $breaking : false;
    if (isBreaking) {
    var elements = $('.bnews__alert').last().find($element);
    } else {
      var elements = document.getElementsByClassName($element);
    }
    if (elements.length > 0) {
    for (var i = 0; i < elements.length; i++) {
        if ($mobileDevice) {
      elements[i].style.display = display;
        } else {
          elements[i].style.display = 'none';
        }
      }
    }
  };

  // only show whatsapp on mobile
  /*
  if ($shareContainer.length > 0) {
    showOnMobile('share-whatsapp', isMobile);
  }
  */

  // breaking News only
  if ($breakingNews.length > 0) {
    showOnMobile('.bnews__more__newsletter', !isMobile, 'flex', true);
    showOnMobile('.bnews__more__apps', !isMobile, 'flex', true);
    showOnMobile('.bnews__more__mobile', isMobile, 'flex', true);
    showOnMobile('.bnews__more__ios', isiOS, 'flex', true);
    showOnMobile('.bnews__more__android', isAndroid, 'flex', true);
  }

  if (teaserAppBanner) {
    if (isiOS) {
      teaserAppBanner.attr('href', 'https://app.adjust.com/o7yy0ak');
    }

    if (isAndroid) {
      teaserAppBanner.attr('href', 'https://app.adjust.com/v3qlopx');
    }
  }
};
