'use strict';

var article = function( $article ) {
  var $window = $( window );                // Window object
  var $metawrapper = $( '.metawrapper' );   // metawrapper element
  var $container = $( '.container' );       // container element
  var $footer = $( '.footer' );             // footer element
  var socialTopPos = 0;                     // top position of the social buttons when sticked on the left

  /*
   * ARTICLE SHARE CONTAINER (floats on the left when scrolling on the article)
   */
  var checkArticlePos = function() {
    var articlePos = $article.find( '.row' ).offset().top;
    var metawrapperWidth = $metawrapper.innerWidth();
    var $socialButtons = $article.find( '.article__share' );
    var socialButtonsHeight = $socialButtons.outerHeight();

    //if( getVw() > 767 ) {
    if( getBreakpoint() == 'sm' || getBreakpoint() == 'lg' ) {
      var leftPos = 'calc( 50% - ' + metawrapperWidth / 2 + 'px + ' + $container.css( 'padding-left' ) + ' )';

      // We test if the footer is now overlapping with the social media buttons
      // If it does, we need to force the buttons to go up of the overlapping value
      var posTest = $footer.offset().top < $window.scrollTop() + articlePos + socialButtonsHeight;
      socialTopPos = posTest ? $footer.offset().top - $window.scrollTop() - socialButtonsHeight : articlePos;

      $socialButtons.css( { position: 'fixed', top: socialTopPos, left: leftPos } );
    }
    else if( $socialButtons.has( '[style]' ) ) {
      $socialButtons.removeAttr( 'style' );
    }

  };

  // .day has .article__text inside it, the floating social share works only on articles, not day.
//  if( !$( '.day' ).length && !!$( '.article__text' ).children().length && !$article.hasClass('ems-native__article') ) {
//		checkArticlePos();
//		$window.scroll( checkArticlePos );
//    $window.on( 'scroll resize', checkArticlePos );
//  }

//	$( window ).resize( checkArticlePos );

  /*
   * image fullscreen
   */

// $elem is the image the users clicked on to see it full width
// $overlay is the overlay created after the click on the picture
// This function injects the url in the overlay
  var injectContent = function( $elem, $overlay ) {
    // We need to fetch the url of the a tag
    var url = $elem.data( 'img' );

    // then we inject in the div.overlay the correct image sizes
    $overlay.find( 'img' ).attr( 'src', url );
  };

  $( '.article__aside--img-ctb, .article--img-ctb' ).on( 'click', 'picture', function( e ) {
    e.preventDefault();

    // We remove the scrollbar from the body
    window.$body.addClass( 'fixed' );

    // we create the container for the fullscreen image
    var $overlay = $( '<div class="overlay"><figure><picture><img alt=""></picture></figure></div>' );
    window.$body.append( $overlay );

    // On click on the overlay, we close it
    $overlay.on( 'click', function() {
      $( this ).remove();
      window.$body.removeClass( 'fixed' );
    } );

    injectContent( $( this ).closest( 'div' ), $overlay );
  } );

  /*
   * print
   */
  $( '.article__share__print' ).on( 'click', function( e ) {
    e.preventDefault();
    window.print();
  } );

  /*
   * trigger audioplayer article
   */

  var $audioWrapper = $( '.article__header' );
  var $audioIcon = $( '.article__audioicon', $audioWrapper );
  var $audio = $( 'audio', $audioWrapper );

  $audioIcon.on( 'click', function( e ) {
      if( $audioWrapper.hasClass( 'article__header--audioActive' ) ) {


      	$audioWrapper.removeClass( 'article__header--audioActive' );
      	$audioIcon.html("Artikel anhören");
      	$(".auplayer", $audioWrapper)[0].dispatchEvent( new CustomEvent('articleAudioClose') );
      	/*
        if( $audio.length > 0 ) {
          ( $audio[ 0 ] ).pause();
        }
        */
      }else{
      
      	$audioWrapper.addClass( 'article__header--audioActive' );
      	$audioIcon.html("Player schließen");
      	$(".auplayer", $audioWrapper)[0].dispatchEvent( new CustomEvent('articleAudioPlay') );
      	/*
          if( $audio.length > 0 ) {
          	( $audio[ 0 ] ).play();
          }
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'inline',
				'eventaction': 'listen to article',
				'eventlabel': $("source",$audio).attr("src")
			});
		*/
      }
   });

};



var handleArticleShare = function($container){
	
	
	console.log($container);
	
	var $shareAction = $(".article__share-action", $container);
	var $shareMain = $(".article__share-main", $container);
	
	var $overlay = $('<div class="article__share-overlay"><div class="article__share-overlaydisp"><div class="article__share-overlayhead"><span>Artikel teilen</span><span class="article__share-overlayclosed"></span></div><div class="article__share-main"></div></div></div>');
	$(".article__share-main", $overlay).html($shareMain.html());
	$(".article__share__copy span", $overlay).attr("data-clipboard-text", $(".article__share__copy img", $overlay).attr("data-clipboard-text"));
	

	
	$shareAction.on("click",function(){
		$("body").append($overlay);
		clipboardHandler('.article__share-overlay .article__share__copy img');
		clipboardHandler('.article__share-overlay .article__share__copy span');

		
		$(".article__share-overlayclosed", $overlay).on("click",function(e){
				$overlay.remove();
		});

		$overlay.on("click",function(e){
			if(e.target === $overlay[0]){
				$overlay.remove();
			}
		});
		
	});
	
	
	
}

if ( $(".article__share-wrapper").length > 0 ) {
	handleArticleShare($(".article__share-wrapper"))
}
