'use strict';


var addSparweltTeaser = function( $container ){
	var sparweltURL = "https://api.sparwelt.de/hinge/graphql?query=query%20widgetSlider%20%7B%0A%20%20slider%28id%3A%20%22%2Fhinge%2Fsliders%2F24511%22%29%20%7B%20%20%20%20%20%20%20%20%20%20%20%20%0A%20%20%20%20id%0A%20%20%20%20sliderItems%20%7B%0A%20%20%20%20%20%20edges%20%7B%0A%20%20%20%20%20%20%20%20node%20%7B%0A%20%20%20%20%20%20%20%20%20%20id%0A%20%20%20%20%20%20%20%20%20%20title%0A%20%20%20%20%20%20%20%20%20%20url%0A%20%20%20%20%20%20%20%20%20%20dateEnd%0A%20%20%20%20%20%20%20%20%20%20dateStart%0A%20%20%20%20%20%20%20%20%20%20widgetTitle%0A%20%20%20%20%20%20%20%20%20%20widgetImage%0A%20%20%20%20%20%20%20%20%20%20provider%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20image%0A%20%20%20%20%20%20%20%20%20%20%20%20title%0A%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D"	
	$.get( sparweltURL, function( data ) {
		var $wrapper = $('.sparwelt__wrapper', $container)
		if(data && data.data && data.data.slider && data.data.slider.sliderItems && data.data.slider.sliderItems.edges){
			
			var edges = data.data.slider.sliderItems.edges.splice(0,6);
			
			if(ntv.urlParams.testSparweltData){
				edges = testData.data.slider.sliderItems.edges.splice(0,6);
			}
			$container.append(sparweltTemplate(edges, 3))	;		
			sparweltAnimation();
		}		
	});
};

var sparweltTemplate = function(data, rowLength){
	var template = '<span class="sparwelt__title" > Gutscheine </span>';
	template += '<div class="sparwelt__angebot">Anzeige / Ein Service von <a href="https://gutscheine.n-tv.de/internal/impressum" target="_blank"><img class="sparwelt__logo" src="' + resourceBaseUrl + '/adaptive/images/sparwelt/sw_logo_new.svg" alt=""></a></div>';
    template += '<div class="sparwelt__wrapper__wrapper">'
	template += '<div class="sparwelt__wrapper">';
	template += '<div class="sparwelt__row">';

	$.each(data, function( index, value ) {
		var partner = value.node;
		var imgWidth = getBreakpoint()=='sm' || getBreakpoint()=='md'?60:90;

		template += '<a class="sparwelt__teaser-neu" target="_blank" href="' + partner.url + '" target="_blank">';
		template +=		'<div class ="sparwelt__info">';
		template +=			'<img src="https://ntv-cdn-assets.imgix.net/' + partner.provider.image + '?w=' + imgWidth + '" alt="' + partner.provider.title + '" />';
		template +=			'<span class="teaser__kicker">' + partner.title + '</span>';
		template +=		'</div>';
		template +=		'<div class="sparwelt__img" style="background-image: url(https://ntv-cdn-assets.imgix.net/' + partner.widgetImage + ');"></div>';
		template +=	'</a>';

		if((index + 1)%rowLength == 0 && index + 1 < data.length ){
			template += '</div><div class="sparwelt__row">';
		}
	});
	template += '</div>';
	template += '</div>';
    template += '</div>';
	template += '<a class="button button--secondary" href="https://gutscheine.n-tv.de" target="_blank">Weitere Gutscheine anzeigen</a>';
	return template;
};

var sparweltAnimation = function () {
	var counter = 1;
	if(getBreakpoint()!="xs"){
		setInterval(function(){
			if(counter >= $('.sparwelt__row').length){
				$('.sparwelt__wrapper').animate({
					left: 0
				});
				counter = 1;
			}
			else{
				$('.sparwelt__wrapper').animate({
					left: -$('.sparwelt__wrapper').width()
				});
				counter++;
			}
		}, 5000);
	}
	$(window).on("resize", function(){
		$('.sparwelt__teaser-neu').css({
			width : $('.sparwelt-neu').width() - 5
		});
		$('.sparwelt__wrapper').css({
			left: 0
		});
	});
	$('.sparwelt__teaser-neu').css({
		width : $('.sparwelt-neu').width() - 5
	});

};


/*
var addSparweltTeaser = function( $container ){

if(ntv.urlParams.testSparwelt){
	$container.addClass("sparwelt-neu");
	$container.removeClass("sparwelt");
	addSparweltTeaser_new($('.sparwelt-neu'));
	return;
	
}

var spUrl = "https://www.sparwelt.de/hinge/graphql?query={partnerListing(id:%22/hinge/partner_listings/29%22){partnerListingHasProviders{edges{node{partnerProvider{slug%20image%20title}}}}}}"

$.get( spUrl, function( data ) {

	if( data.data && data.data.partnerListing && data.data.partnerListing.partnerListingHasProviders && data.data.partnerListing.partnerListingHasProviders.edges ){
		
		
        var edges = data.data.partnerListing.partnerListingHasProviders.edges;
        if( getBreakpoint() == "xs" ){
            edges.splice( 3 );
        }
        else if( getBreakpoint() == "sm" ){
            edges.splice( 3 );
        }else{
        	edges.splice( 5 );
        }

        $container.append( '<span class="sparwelt__title" > Gutscheine <span class="sparwelt__title__anzeige">ANZEIGE</span></span>' );
        
        $.each(edges, function( index, value ) {
            var partner = value.node.partnerProvider;
            $container.append( '<a class="sparwelt__teaser" target="_blank" href="https://gutscheine.n-tv.de/gutscheine/' + partner.slug + '?utm_source=n-tv&utm_medium=widget" title="' + partner.title + '"><div class ="sparwelt__image"><img src="https://ntv-cdn-assets.imgix.net/' + partner.image + '?w=225" /></div><div class="sparwelt__text"><span>' + partner.title + ' Gutschein</span></div></a>');
        });

        $container.append( ' <div class ="sparwelt__angebot"><a href="https://www.sparwelt.de/" target="_blank" rel="nofollow">Ein Angebot von <img src="' + resourceBaseUrl + '/adaptive/images/sparwelt/sw_logo.svg" /></a></div>' );

	}
	
	
});

};


*/


var testData = {
	"data": {
		"slider": {
			"id":"\/hinge\/sliders\/24511",
			"sliderItems": {
				"edges":[
					{
						"node":{
							"id":"\/hinge\/slider_items\/24514",
							"title":"12% Rabatt",
							"url":"https:\/\/gutscheine.n-tv.de\/gutscheine\/acer#!\/gutschein\/2825085",
							"dateEnd":null,
							"dateStart":"2020-06-04T00:00:00+02:00",
							"widgetTitle":"12% Rabatt",
							"widgetImage":"uploads\/syndication\/5ed8d331ba0ba.jpeg",
							"provider": {
								"image":"uploads\/provider\/5bcd890251740.jpeg",
								"title":"acer"
							}
						}
					},
					{
						"node":{
							"id":"\/hinge\/slider_items\/24514",
							"title":"12% Rabatt",
							"url":"https:\/\/gutscheine.n-tv.de\/gutscheine\/acer#!\/gutschein\/2825085",
							"dateEnd":null,
							"dateStart":"2020-06-04T00:00:00+02:00",
							"widgetTitle":"12% Rabatt",
							"widgetImage":"uploads\/syndication\/5ed8d331ba0ba.jpeg",
							"provider": {
								"image":"uploads\/provider\/5bcd890251740.jpeg",
								"title":"acer"
							}
						}
					},
					{
						"node":{
							"id":"\/hinge\/slider_items\/24514",
							"title":"12% Rabatt",
							"url":"https:\/\/gutscheine.n-tv.de\/gutscheine\/acer#!\/gutschein\/2825085",
							"dateEnd":null,
							"dateStart":"2020-06-04T00:00:00+02:00",
							"widgetTitle":"12% Rabatt",
							"widgetImage":"uploads\/syndication\/5ed8d331ba0ba.jpeg",
							"provider": {
								"image":"uploads\/provider\/5bcd890251740.jpeg",
								"title":"acer"
							}
						}
					},
					{
						"node":{
							"id":"\/hinge\/slider_items\/24514",
							"title":"12% Rabatt",
							"url":"https:\/\/gutscheine.n-tv.de\/gutscheine\/acer#!\/gutschein\/2825085",
							"dateEnd":null,
							"dateStart":"2020-06-04T00:00:00+02:00",
							"widgetTitle":"12% Rabatt",
							"widgetImage":"uploads\/syndication\/5ed8d331ba0ba.jpeg",
							"provider": {
								"image":"uploads\/provider\/5bcd890251740.jpeg",
								"title":"acer"
							}
						}
					},
					{
						"node":{
							"id":"\/hinge\/slider_items\/24514",
							"title":"12% Rabatt",
							"url":"https:\/\/gutscheine.n-tv.de\/gutscheine\/acer#!\/gutschein\/2825085",
							"dateEnd":null,
							"dateStart":"2020-06-04T00:00:00+02:00",
							"widgetTitle":"12% Rabatt",
							"widgetImage":"uploads\/syndication\/5ed8d331ba0ba.jpeg",
							"provider": {
								"image":"uploads\/provider\/5bcd890251740.jpeg",
								"title":"acer"
							}
						}
					},
					{
						"node":{
							"id":"\/hinge\/slider_items\/24514",
							"title":"12% Rabatt",
							"url":"https:\/\/gutscheine.n-tv.de\/gutscheine\/acer#!\/gutschein\/2825085",
							"dateEnd":null,
							"dateStart":"2020-06-04T00:00:00+02:00",
							"widgetTitle":"12% Rabatt",
							"widgetImage":"uploads\/syndication\/5ed8d331ba0ba.jpeg",
							"provider": {
								"image":"uploads\/provider\/5bcd890251740.jpeg",
								"title":"acer"
							}
						}
					}
				]
			}
		}
	}
};