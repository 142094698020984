'use strict';

var stickyVideoHandlerViper = function($container){

		var $vplayer = $(".vplayer", $container);
		var $ajaxWrapper = $(".article--video .ajax-wrapper");
		var isPopUp = false;
		var $button = $("<div class='vplayer__close' >X</div>");
		var isArticle = $(".vplayer--article", $container).length > 0?true:false;
		var eCategory = isArticle?'stickyVideoArticle':'stickyVideo';
		var $txt = $('');
		var $kicker = $('');
		var $headline = $('');

		var getTrigger = function(){
			var trigger = $container.offset().top;
			if ( !isArticle ) {
				trigger += $("#playerwrapper").height()/2;
			}
			return trigger;
		}

		if(!isArticle){

			$txt = $('<div class="vplayer__text"><h2><span class="article__kicker">' + $( "#playerwrapper" ).data("player").setup.source.kicker +'</span><span class="article__headline">' + $( "#playerwrapper" ).data("player").setup.source.headline + '</span></h2></div>');
			$kicker = $(".article__kicker", $txt);
			$headline = $(".article__headline", $txt);
		}

		var playerScroll = function(){
		    if(!isPopUp){
		    	isPopUp = true;
		    	$container.css("height", $container.height() + 'px');
		    	$vplayer.addClass('vplayer--sticky');
		    	$vplayer.prepend($button);
		    	$vplayer.append($txt);
		    	//$ajaxWrapper.css("visibility", "hidden");

		    	gaEvent({
		    		eventCategory: eCategory,
		    		eventAction: "start",
		    		eventLabel: window.location.href,
		    		eventValue: $( "#playerwrapper" ).data("player").setup.source.videoId,
		    		nonInteraction: true
		    	});

		    }
		};

		var playerUnscroll = function(eAction){
		    if(isPopUp){
		    	isPopUp = false;
		    	$container.css("height", 'auto');
		    	$vplayer.removeClass('vplayer--sticky');
		    	$button.remove();
		    	$txt.remove();
		    	//$ajaxWrapper.css("visibility", "visible");

		    	if( typeof(eAction) != "undefined"){


			    	gaEvent({
			    		eventCategory: eCategory,
			    		eventAction: eAction,
			    		eventLabel: window.location.href,
			    		eventValue: $( "#playerwrapper" ).data("player").setup.source.videoId,
			    		nonInteraction: true
			    	});
		    	}
		    }
		};

		if(isArticle){
			$(window).on('videoplayerSessionEnd', function(){
				playerUnscroll("closeByVideoEnd"); //unscrolls on video end
			});
		}

		if(!isArticle){
			$(window).on('videoplayerNewVideo', function(){
				$kicker.html( $( "#playerwrapper" ).data("player").setup.source.kicker );
				$headline.html( $( "#playerwrapper" ).data("player").setup.source.headline );
			});
		}

		$(document).on('click', '.vplayer__close', function(){ //logik fÃ¼r den close button
			playerUnscroll("closeByButton");
			// *** ntvVideoplayer.pause();
		});


		var handleSticky = function(){

		    // ***if( getTrigger() < window.pageYOffset && ntvVideoplayer != undefined && ntvVideoplayer.isPlaying() ){
		    if( getTrigger() < window.pageYOffset ){
		    	playerScroll();
		    // ****}else if(ntvVideoplayer != undefined){
		    }else{
		        playerUnscroll("closeByScroll");
		    }
		};


			$(window).on('scroll',function(){

				if($container.css("position")!="sticky"){
					handleSticky();
				}
			});

			$(window).on('resize',function(){

				if($container.css("position")!="sticky"){
					handleSticky();
				}else{
					playerUnscroll();
				}
			});


};