'use strict';

// toDo refactoring

var newContentNote = function(){
	
	
	if(ntv.urlParams.disableContentNote && window.localStorage){
		localStorage.setItem('disableContentNote', 'true');
	}
	
	if(ntv.urlParams.enableContentNote && window.localStorage){
		localStorage.removeItem('disableContentNote');
	}
	

	var $container = $(".newcontent-note");
	
	
	if ( !(window.localStorage && localStorage.disableContentNote == 'true') && $container.length == 1 && ntv.urlParams.service!="webview" && (getBreakpoint()=="md" || getBreakpoint()=="lg") ) {
		
		
		var ajaxUrl = $container.attr("data-src");
		var $ajaxContainer = $(".newcontent-note__ajaxwrapper", $container);
	
		var docIsVisible = true;
		var docTitle = $("title").html();
		//var urlFavicon = $("#favicon").attr("href");
		var timerInactivity;
		var timeout = ntv.urlParams.timeout?ntv.urlParams.timeout:60000;
		var countInactivity = 0;
		var countEnd = 30;
		var videoPlayerIsRunning = false;
		var lastTimeStamp = parseInt(ntv.pageInfo.renderTimeStamp);
		var actualTimeStamp;
		var $html = "";
		
		
		document.addEventListener('visibilitychange', function (event) {
		    if (document.hidden) {
		    	docIsVisible = false;
		    } else {
		    	docIsVisible = true;
		    }
		    handleDocTitle();
		});
		
		var handleDocTitle = function(){
			
			if(!docIsVisible && $container.is(":visible")){
				//$("title").html("neuer Beitrag");
				//$("#favicon").attr("href", resourceBaseUrl+"/adaptive/images/favicon-ani.gif");
			}else{
				//$("title").html(docTitle);
				//$("#favicon").attr("href", urlFavicon);
			}
			
		};
		
		
		
		$(".newcontent-note__close", $container).on( 'click', function( e ) {
			/*
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'inactivity',
				'eventaction': 'overlay: click close icon',
				'eventlabel': 'minute '+ countInactivity,
				'eventvalue': countInactivity
			});
			*/
			$ajaxContainer.html("");
			$container.hide();
			resetTimerInactivity();
		});
		
		$(".newcontent-note__disable", $container).on( 'click', function( e ) {
			/*
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'inactivity',
				'eventaction': 'overlay: click disable',
				'eventlabel': 'minute '+ countInactivity,
				'eventvalue': countInactivity
			});
			*/
			localStorage.setItem('disableContentNote', 'true');
			$ajaxContainer.html("");
			$container.hide();
			resetTimerInactivity();
		});
		
		var handleInactivity = function(){
			countInactivity += 1;
			
			if( countInactivity > 1 && countInactivity <= countEnd ){
				
				ntvDebug("*** inaktiv " + countInactivity);
				
				/*
				dataLayer.push({
					'event': 'page-track' ,
					'eventcategory': 'inactivity',
					'eventaction': 'inactivity ongoing',
					'eventlabel': 'minute '+ countInactivity,
					'eventvalue': countInactivity,
					'nonInteractionHit': 'True'
				});
				*/
				
				
				if(countInactivity > 7 && countInactivity % 4 == 0){
					
					ntvDebug("*** inaktiv ajax");
					
					$.ajax({
						url: ajaxUrl,
						type: "GET"
					})
					.done( function( data, textStatus, jqXHR ) {
						
						$html = "<div>" + data + "</div>";
						actualTimeStamp = parseInt($("article", $html).attr("data-timestamp"));
						
						ntvDebug("*** inaktiv lastTimeStamp " + lastTimeStamp);
						ntvDebug("*** inaktiv actualTimeStamp " + actualTimeStamp);
						
						
						if(actualTimeStamp!="undefined" && actualTimeStamp!="NaN" && actualTimeStamp>lastTimeStamp){
							
							if( !$container.is(":visible") ){
								/*
								dataLayer.push({
									'event': 'page-track',
									'eventcategory': 'inactivity',
									'eventaction': 'overlay: appeared',
									'eventlabel': 'minute '+ countInactivity,
									'eventvalue': countInactivity,
									'nonInteractionHit': 'True'
								});
								*/
							}
							
							
							$ajaxContainer.html($html);
							$container.show();
							lastTimeStamp = actualTimeStamp;
							handleDocTitle();
							
							$("a", $container).on( 'click', function( e ) {
								
								var $this = $(this);
								var href = $this.attr("href");


								/*
								dataLayer.push({
									'event': 'page-track' ,
									'eventcategory': 'inactivity',
									'eventaction': href=='/'?'overlay: click link to homepage':'overlay: click recommended article',
									'eventlabel': 'minute '+ countInactivity,
									'eventvalue': countInactivity
								});
								*/

							});
							
						}
					})
					.fail( function( jqXHR, textStatus, errorThrown ) {
						//ntv.log( jqXHR, textStatus, errorThrown );
					});
					
				}
				
			}
			
			clearTimeout(timerInactivity);
			if( countInactivity <= countEnd+1 ){
				timerInactivity = window.setTimeout(handleInactivity, timeout);
			}else{
				//ntvDebug("*** inaktiv close");
				//$ajaxContainer.html("");
				//$container.hide();
			}
		};
		
		
		
		var resetTimerInactivity = function(){
			clearTimeout(timerInactivity);
			if( countInactivity > 1 && countInactivity <= countEnd ){
	
				ntvDebug("*** inaktiv end" + countInactivity);
				
				/*
				dataLayer.push({
					'event': 'page-track',
					'eventcategory': 'inactivity',
					'eventaction': 'activity resumed',
					'eventlabel': 'minute '+ countInactivity,
					'eventvalue': countInactivity,
					'nonInteractionHit': 'True'
				});
				*/
			}
			countInactivity = 0;
			if( ! (ntvVideoplayer && ntvVideoplayer.player && ntvVideoplayer.player.isPlaying()) ){
				timerInactivity = window.setTimeout(handleInactivity, timeout);
			}
		
		}
		
		$(window).on('scrollReady galleryslide videoplayerPlaying videoplayerSessionEnd videoplayerPaused', resetTimerInactivity);

		resetTimerInactivity();

	}

};