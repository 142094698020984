"use strict";

var getTrackingParams = function (set) {

  var trackingSet = $.extend(
    true,
    {},
    ntv.trackingSet,
    set
  );
  var trackingParams = getBreakpoint() == "xs" ? trackingSet.xs : trackingSet.sm_lg;
  $.each(trackingSet, function (key, value) {
    if (key != 'xs' && key != 'sm_lg') {
      trackingParams[key] = value;
    }
  });
  return trackingParams;
};


var ntvTracking = function (conf) {

  var trackingParams = getTrackingParams(conf.trackingSet);

  ntvDebug("*** trackingParams ***");
  ntvDebug(trackingParams);

  if (conf.trackIVW) {
	  
	  
	  
	  
	/*  
    var iam_data = {
      "st": trackingParams.agof_offer,
      "cp": trackingParams.agof_code,
      "sv": getBreakpoint() == "xs" ? "mo" : "i2",
      "co": trackingParams.agof_comment,
      "sc": "yes"
    }

    ntvDebug("*** iam_data ***");
    ntvDebug(iam_data);

    if (window.iom && ntv.consents.infonline) {
      iom.c(iam_data, 1);
    }
    */
    
    if (window.IOMm) {
    	
  		IOMm('pageview', {
  			cp: trackingParams.agof_code,
  			co: trackingParams.agof_comment });
    	
  		// BFE-Modul???
  		// IOMm('3p', 'qds');
  		// agof fragebogen: https://www.infonline.de/download/agof-service-center-integration-guide-frabo/
  		
    }
    
    
  }

  if (conf.trackGaEvent) {
    //if privacy
  }

  if (conf.trackGaView) {
    var comPathArray = (trackingParams.agof_comment).split("/");
    var kicker_headline = "";

    if (trackingParams.article_kicker || trackingParams.article_headline) {
      kicker_headline = trackingParams.article_kicker + " - " + trackingParams.article_headline;
    }

    var dimensions = {
      dimension1: trackingParams.app_type,
      dimension2: comPathArray[0] ? comPathArray[0] : "",
      dimension3: comPathArray[1] ? comPathArray[1] : "",
      dimension4: comPathArray[2] ? comPathArray[2] : "",
      dimension5: comPathArray[3] ? comPathArray[3] : "",
      dimension6: comPathArray[4] ? comPathArray[4] : "",
      dimension7: comPathArray[5] ? comPathArray[5] : "",
      dimension9: document.referrer,
      dimension11: trackingParams.article_date ? trackingParams.article_date : "",
      dimension12: kicker_headline
    }

    ntvDebug("*** analytics dimensions  ***");
    ntvDebug(dimensions);

    if (ntv.consents.ga && window.ga) {
      ga('send', 'pageview', trackingParams.url ? trackingParams.url : location.pathname, dimensions);
    }
  }
};


// tracking for gallery
/* is in gallery.js
window.addEventListener('galleryslide', function (e) {
  var $gallery = $(e.detail).parents(".gallery");
  ntvTracking({
    trackIVW: true,
    trackGaView: true,
    trackingSet: $gallery.data("trackingSet")
  });
});
*/


//track google-analytics event for given set
var gaEvent = function (set) {
  if (ntv.consents.ga && window.ga) {
    set.hitType = 'event';
    ga('send', set);
    ntvDebug("*** gaEvent ***");
    ntvDebug(set);
  }
};


//special trackings


var trackArticleScroll = function () {


  var maximalScrolled = window.innerHeight;
  var timeStart = new Date().getTime();
  var lastMaxScroll = 0;

  /*
  $( window ).ready( function(){
      timeStart = new Date().getTime();
      submitToAnalytics();
  });
  */

  var submitToAnalytics = function () {
    var timeOnSite = new Date().getTime() - timeStart;

    timeOnSite = timeOnSite / 1000;

    timeOnSite = parseInt(timeOnSite);

    if (isNaN(timeOnSite)) {
      timeOnSite = 0;
    }


    gaEvent({
      eventCategory: 'scrollArticle',
      eventAction: lastMaxScroll,
      eventLabel: window.location.href,
      eventValue: timeOnSite,
      nonInteraction: true
    });

	dataLayer.push({
		'event': 'ga4_event' ,
		'eventcategory': 'scrollArticle',
		'eventaction': lastMaxScroll,
		'eventlabel': window.location.href,
      	'eventvalue': timeOnSite
	});


    ntvDebug("*** trackArticleScroll  ***");
    ntvDebug("Gelesen: " + lastMaxScroll + "%, Zeit: " + timeOnSite + "sek");
  };


  var evalScroll = function () {


    var scrollpoint = (window.pageYOffset || 0) + window.innerHeight;
    if (maximalScrolled < scrollpoint) {
      maximalScrolled = scrollpoint;
    }

    var scrollPercentage;

    var articleStart = $(".article__text").offset().top;
    var height = $('.article__text').height();
    var articleEnd = articleStart + height;
    var intervall = [100, 90, 75, 50, 25, 10, 0];


    if (maximalScrolled > articleEnd) {
      maximalScrolled = articleEnd;
    }

    maximalScrolled = maximalScrolled - articleStart;

    scrollPercentage = Math.round((maximalScrolled / height) * 100);

    for (var i in intervall) {

      if (scrollPercentage >= intervall[i]) {
        scrollPercentage = intervall[i];
        if (scrollPercentage > lastMaxScroll) {
          if (intervall.indexOf(lastMaxScroll) > (intervall.indexOf(scrollPercentage) + 1)) {
            var correction = intervall.slice((intervall.indexOf(scrollPercentage) + 1), (intervall.indexOf(lastMaxScroll)));
            correction.reverse();
            for (var c in correction) {
              lastMaxScroll = correction[c];
              submitToAnalytics();
            }
          }

          lastMaxScroll = scrollPercentage;
          submitToAnalytics();
        }
        return;
      }
    }
  };


  submitToAnalytics();
  evalScroll();

  $(window).on('scrollReady resizeReady', evalScroll);
};


// tracking rate for gallery
// off because is not working for layer
var trackSlider = function (gallery) {

  /*
    var $gallery = gallery;
    var lastMaxSlider = 0;
    var sliderLength = $gallery.find( '.slick-slide' ).length;
    var trackingSet = $gallery.data("trackingSet");
    var url = trackingSet.url?trackingSet.url:location.pathname


    var submitToAnalytics = function () {


        gaEvent({
      eventCategory: 'Bilderserien',
      eventAction: lastMaxSlider,
      eventLabel: url,
      nonInteraction: true
    });

      ntvDebug("*** track Bilderserie ***");
      ntvDebug( url );
        ntvDebug( lastMaxSlider + '%');
    }

    var evalSlider = function(){

        var currentSlider = parseInt( $gallery.find( '.slick-active' )[ 0 ].attributes[ 'data-slick-index' ].value ) + 1;

        var intervall = [ 100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0 ];

        var sliderPercentage = Math.round( ( currentSlider / sliderLength ) * 100 );

       for ( var i in intervall ){

        if( sliderPercentage >= intervall[ i ] ){
            sliderPercentage = intervall[ i ];
            if( sliderPercentage > lastMaxSlider ){
                if( intervall.indexOf( lastMaxSlider ) > (intervall.indexOf( sliderPercentage ) + 1 )){

                    var correction = intervall.slice( ( intervall.indexOf( sliderPercentage ) + 1),(  intervall.indexOf( lastMaxSlider ) ) );
                    correction = correction.reverse();
                    for( var c in correction ){
                        lastMaxSlider = correction[ c ];
                        submitToAnalytics();
                    }
                }

                lastMaxSlider = sliderPercentage;
                submitToAnalytics();
            }
            return;
        }

       }
    }


    submitToAnalytics();
    evalSlider();


    $( window ).on('galleryslide', evalSlider);

    */

};


// tracking menu
var trackMenu = function () {
	
	
	if(getBreakpoint()=="xs"){
		
		var xsHeaderLabel = "";
		var $xsHeaderLi = "";
		
		
		$(".header__nav__action li").on("click", function () {
			
			$xsHeaderLi = $(this);
			
			if($xsHeaderLi.hasClass("header__nav__search")){
				xsHeaderLabel = "Suche";
			}
			if($xsHeaderLi.hasClass("header__nav__video")){
				xsHeaderLabel = "Video";
			}
			if($xsHeaderLi.hasClass("header__nav__live")){
				xsHeaderLabel = "Live";
			}
			
			
			if(xsHeaderLabel!=""){
			    gaEvent({
			        eventCategory: 'MobileHeader',
			        eventAction: "Click",
			        eventLabel: xsHeaderLabel
			    });
			    
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'MobileHeader',
			'eventaction': 'Click',
			'eventlabel': xsHeaderLabel
		});
			}
			
		});
		
	}


  $(".header__nav__element a").on("click", function () {

    var $this = $(this);
    var action = "na";
    var label = "na";


    var $parent = $($this.parents("*[class^='header__nav__lvl']").get(0));


    if ($parent.length) {

      action = "level" + $parent.attr("class").match(/[0-9]/)[0];
      label = $("span", this).length ? $("span", this).text() : $(this).text();

    }


    gaEvent({
      eventCategory: 'navigation',
      eventAction: action,
      eventLabel: label
    });

	dataLayer.push({
		'event': 'ga4_event' ,
		'eventcategory': 'navigation',
		'eventaction': action,
		'eventlabel': label
	});

  });


  $("#header-search").on("submit", function () {

    gaEvent({
      eventCategory: 'navigation',
      eventAction: "level1",
      eventLabel: "Suche"
    });

	dataLayer.push({
		'event': 'ga4_event' ,
		'eventcategory': 'navigation',
		'eventaction': 'level1',
		'eventlabel': 'Suche'
	});
  });


  $(".header__nav__live a").on("click", function () {

    gaEvent({
      eventCategory: 'navigation',
      eventAction: "level1",
      eventLabel: "Live"
    });

	dataLayer.push({
		'event': 'ga4_event' ,
		'eventcategory': 'navigation',
		'eventaction': 'level1',
		'eventlabel': 'Live'
	});
  });


  $(".header__nav__teasers article.teaser a").on("click", function () {

    gaEvent({
      eventCategory: 'navigation',
      eventAction: "teaser",
      eventLabel: $(this).attr('href')
    });

	dataLayer.push({
		'event': 'ga4_event' ,
		'eventcategory': 'navigation',
		'eventaction': 'teaser',
		'eventlabel': $(this).attr('href')
	});
  });

};



//tracking image teaser (docType widget_teaser, Ansicht "Bild Teaser")

var trackImageTeaser = function () {
	
	if(!window.IntersectionObserver) return;
	
	var observer= new IntersectionObserver( function(entries){
			entries.forEach(function(entry){
		        if(entry.isIntersecting){
		        	observer.unobserve(entry.target);
					dataLayer.push({
						'event': 'page-track' ,
						'eventcategory': 'Teaser',
						'eventaction': 'display',
						'eventlabel': $("a", entry.target).attr('href'),
						'nonInteractionHit': 'True'
					});
					dataLayer.push({
						'event': 'ga4_event' ,
						'eventcategory': 'Teaser',
						'eventaction': 'display',
						'eventlabel': $("a", entry.target).attr('href')
					});
		        }
		    });
		},
		{root: null, rootMargin: '0px',threshold: 1}
	);
	
	$(".teaser--special a").parents(".teaser--special").each(function(){
		
		var $teaser = $(this);
		var $a = $("a", $teaser);
		
		$a.on("click", function () {
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'Teaser',
				'eventaction': 'click',
				'eventlabel': $(this).attr('href')
			});
			dataLayer.push({
				'event': 'ga4_event' ,
				'eventcategory': 'Teaser',
				'eventaction': 'click',
				'eventlabel': $(this).attr('href')
			});
		});
		
		observer.observe($teaser[0]);
		
	});
};

/*
var trackImageTeaser = function () {
	
	$(".teaser--special a").on("click", function () {
		gaEvent({
			eventCategory: 'Banner',
			eventAction: "Click",
			eventLabel: $(this).attr('href')
		});
	});
};
*/


var trackWeatherBrand = function(){

	if(window.dataLayer){
		$('.weather-brand a').on("click", function () {
			ntvDebug("*** trackWeatherBrand ***");
			dataLayer.push({
				'eventcategory' : 'Wetter',
				'eventaction' : 'click-link',
				'eventlabel' : 'wetter.de-Verlinkung über dem Footer',
				'event' : 'page-track' 
			});
			dataLayer.push({
				'event': 'ga4_event' ,
				'eventcategory': 'Wetter',
				'eventaction': 'click-link',
				'eventlabel': 'wetter.de-Verlinkung über dem Footer'
			});
		});
	}
};


var trackVgwort = function(vgwortKeyUrl){
	
	if(ntv.consents.vgwort){
		
		vgwortKeyUrl = vgwortKeyUrl || ntv.vgwortKeyUrl || "";

		if(vgwortKeyUrl!="" && vgwortKeyUrl!="-1"){
			var vgwort_image = new Image();
			vgwort_image.src = vgwortKeyUrl;
		    ntvDebug("*** trackVgwort  ***");
		    ntvDebug(vgwortKeyUrl);
		}
	}
};



var trackScrollHome = function(){
	
	
	if( !(ntv.pageInfo.url=="https://preview.n-tv.de/" || ntv.pageInfo.url=="https://www.n-tv.de/") || !window.IntersectionObserver) return;
	
	ntvDebug("*** trackScrollHome  ***");
	
	var observer= new IntersectionObserver( function(entries){
			entries.forEach(function(entry){
		        if(entry.isIntersecting){
		        	observer.unobserve(entry.target);
		        	ntvDebug("*** trackScrollHome: " + entry.target.getAttribute("data-treliv") + "  ***");
		        	dataLayer.push({
		        		'event': "ga4_event",
		        		'eventcategory': "scroll",
		        		'eventlabel': getBreakpoint(),
		        		'eventaction': entry.target.getAttribute("data-treliv")
		        	});
		        }
		    });
		},
		{root: null, rootMargin: '0px',threshold: 1}
	);
	
	
	
	
	var elems = [
			
		[$(".teaser--day-red").parents(".teaser--day")[0], "dayteaser_red"],
		[$(".teaser--day-blue").parents(".teaser--day")[0], "dayteaser_blue"],
		[$(".teaser--day-green").parents(".teaser--day")[0], "dayteaser_green"],
		[$("section[data-wid='20183877'] article")[0], "newsteaser_big_1"],
		[$("section[data-wid='20183877'] article")[1], "newsteaser_big_2"],
		[$("section[data-wid='20183877'] article")[2], "newsteaser_big_3"],
		[$("section[data-wid='23159235']")[0], "videos_ukraine"],
		[$("section[data-wid='20001939'] article")[0], "newsteaser_small_1"],
		[$("section[data-wid='20001939'] article")[1], "newsteaser_small_2"],
		[$("section[data-wid='20001939'] article")[2], "newsteaser_small_3"],
		[$("section[data-wid='20006898']")[0], "videos_mostviewed"]
	];
	

	
	$(".liveticker").each(function(){
		if($(".title", $(this)).text().toLowerCase().includes("ukraine")){
			elems.unshift([$(this)[0], "ticker_ukraine"]);
		}
	});
	
	
	
	
	
    for (var i in elems) {
    	
    	if (elems[i][0] && elems[i][1]){
    		elems[i][0].setAttribute("data-treliv", elems[i][1]);
    		observer.observe(elems[i][0]);
    	}
    	
    }


	
};


// track teaser Appletspalte Home
if(ntv.pageInfo && (ntv.pageInfo.url === 'https://www.n-tv.de/' || ntv.pageInfo.url === 'https://preview.n-tv.de/') && (getBreakpoint()=='md' || getBreakpoint()=='lg')){
	$.each($(".sidebar__group article:visible"),function(index){
		var $teaser = $(this);
		$("a", $teaser).on("click", function () {
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'home_teaser_applet-column',
				'eventaction': index+1,
				'eventlabel': $(this).attr("href")
			});
			dataLayer.push({
				'event': 'ga4_event' ,
				'eventcategory': 'home_teaser_applet-column',
				'eventaction': index+1,
				'eventlabel': $(this).attr("href")
			});
		});
	});
}




// track active/inactive browsertab + reload ads
function trackTabSwitch(){
	
	var lastChangeTime = new Date().getTime();


	document.addEventListener('visibilitychange', function (event) {
	
		var changeTime = new Date().getTime();
		
		if (document.visibilityState === 'visible') {
			ntvDebug("*** tab visible ***");
			dataLayer.push({
				'event': 'ga_event' ,
				'eventcategory': 'tab_status_changed',
				'eventaction': 'tab_activated',
				'eventlabel': undefined,
				'tab_activated_time_elapsed': Math.round((changeTime-lastChangeTime)/1000),
				'non_interaction': true
			});
			dataLayer.push({
				'event': 'ga4_event' ,
				'eventcategory': 'tab_status_changed',
				'eventaction': 'tab_activated',
				'tab_activated_time_elapsed': Math.round((changeTime-lastChangeTime)/1000)
			});
			
			dataLayer.push({
				'event': 'page_view_tab_activated'
			});
			
			if (ntv.consents.ga && window.ga) {
				ga('send', 'pageview');
			}
			
		    if (window.IOMm) {

				IOMm('pageview', {
					cp: ntv.trackingParamsInit.agof_code,
					co: ntv.trackingParamsInit.agof_comment
		    	});
		    }
		    
		    
		    if(window.GujAd){
				GujAd.cmd.push(function() {
					GujAd.getClient().reloadAds();
				});
		    }
				
	
			
		} else {
			ntvDebug("*** tab not visible ***");
			dataLayer.push({
				'event': 'ga_event' ,
				'eventcategory': 'tab_status_changed',
				'eventaction': 'tab_inactivated',
				'eventlabel': undefined,
				'tab_inactivated_time_elapsed': Math.round((changeTime-lastChangeTime)/1000),
				'non_interaction': true
			});
			dataLayer.push({
				'event': 'ga4_event' ,
				'eventcategory': 'tab_status_changed',
				'eventaction': 'tab_inactivated',
				'tab_inactivated_time_elapsed': Math.round((changeTime-lastChangeTime)/1000)
			});
			
		}
		
		
		lastChangeTime = changeTime;
	});
	
	
}