'use strict';

var adsDayTeaser = function(){
	

	var readmoreDisplayed = false;
	var slotsToPush = [];

	var handleAds = function(mutationsList, observer) {

		mutationsList.forEach(function(mutation) {
			  
			if(mutation.addedNodes && mutation.addedNodes.length>0){
			    	
			   if( $("#ob-readmore-button").length > 0 ){
			    		
			    	if(!readmoreDisplayed){

					    readmoreDisplayed = true;
					    
					    if( $(".ob-readmore-collapse").length > 0 ){
					    
						    $.each($(".ems-slot--day:visible"),function(){
						    	
						    	if( $(".ob-readmore-collapse").height() - $(this).position().top > 100 ){
						    		
						    		if (window.GujAd && GujAd.cmd) {
						    			
										var slotId = $(".gujAd", this).attr("id");
										
										GujAd.cmd.push(function() {
										    GujAd.push(slotId);
										});
						    		}
						    	}else{
						    		slotsToPush.push($(".gujAd", this).attr("id"));
						    	}
						    });
						    
					    }
			    	}
			    		
					$("#ob-readmore-button").on( 'click', function() {
				    
			    		if (window.GujAd && GujAd.cmd) {
			    			
			    			for (var i = 0; i < slotsToPush.length; i++) {
			    		        GujAd.cmd.push(function() {
			    		        	GujAd.push(slotsToPush[i]);
			    		        });
			    			}
			    		}
					});
					observer.disconnect();
			    }
			}
			  
		});
	};

	var observer = new MutationObserver(handleAds);
	observer.observe( $(".day")[0], {childList: true} );
};