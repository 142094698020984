'use strict';

var timeZones = function () {
	
	if(isValidTimeZone('Europe/Berlin')){    
	    start();	    
	}else{
		$('.header__nav__timezones--hidden').removeClass('header__nav__timezones--hidden');
	}
	
	function start() {
	    var date = new Date();
	    var timer = 61 - date.getSeconds();
	    display(date);
	    setTimeout( function (){
	        start();
	    },timer * 1000)
	}
	
	function display ( date ){
	    var dateString, date = date;
	    var dateOptions = { timeZone: 'Europe/Berlin',weekday: 'long',year:'numeric',month:'long',day:'numeric'};
	    dateString = date.toLocaleString('de-DE', dateOptions) + ' ' 
	    	+ date.toLocaleTimeString('de-DE', { hour: '2-digit', minute:'2-digit'}) + ' Uhr Frankfurt | ' 
	    	+ date.toLocaleTimeString('de-DE', { timeZone : 'Europe/London', hour: '2-digit', minute:'2-digit'}) + ' Uhr London | ' 
	    	+ date.toLocaleTimeString('de-DE', { timeZone : 'America/New_York', hour: '2-digit', minute:'2-digit'}) + ' Uhr New York | ' 
	    	+ date.toLocaleTimeString('de-DE', { timeZone : 'Asia/Tokyo', hour: '2-digit', minute:'2-digit'}) + ' Uhr Tokio ';
	    
	    $('#header__nav__timezones')[0].innerHTML = dateString;
	    $('.header__nav__timezones--hidden').removeClass('header__nav__timezones--hidden');
	}
	
	
	function isValidTimeZone(tz) {
	    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
	        return false;
	    }
	    try {
	        Intl.DateTimeFormat(undefined, {timeZone: tz});
	        return true;
	    }
	    catch (ex) {
	        return false;
	    }
	}
}