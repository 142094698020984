'use strict';
// ms for Media Slider

var bnewsSliders = function() {

  /*
   * BREAKING NEWS SLIDER
   */

	var $bnewsSlides = $( '.bnews__slides' );
	
	
	// begin tracking Home "Das Neueste"
	
	if(ntv.pageInfo.section=="Startseite" && $bnewsSlides.length>0){

		var $firstSlider = $($bnewsSlides[0]);
		var $aMessage;
		var $sliderParent;
		var indexMessage = 1;
		var offsetBottom = $firstSlider.offset().top + $firstSlider.parents(".bnews__slider").height() - $("header.header").height();

	  
		var isSliderInViewport = function(){
			if( offsetBottom > window.pageYOffset ){
				return true;
			}
			return false;
		}
	  
		var trackSlider = function(action, label){

			dataLayer.push({
				'event' : 'page-track',
				'eventcategory' : 'slider',
				'eventaction' : action,
				'eventlabel' : label
			});

			dataLayer.push({
				'event' : 'ga4_event',
				'eventcategory' : 'slider',
				'eventaction' : action,
				'eventlabel' : label
			});
		}
	  
		if( isSliderInViewport() ){
			$aMessage = $($(".bnews__slides__teaser a", $firstSlider)[0]);
			trackSlider('das neueste: displayed message ' + indexMessage, $aMessage.attr("href"));
		}
	 
	
		$firstSlider.on('init', function(slick) {
			$(".slick-arrow", $firstSlider.parents(".bnews")).on('click', function(){
				trackSlider('das neueste: clicked arrow', $(this).hasClass('slick--prev')?'left':'right');
			});
		
		});
		
		$firstSlider.on('swipe', function( evt, slick, direction ) {
				trackSlider('das neueste: swiped', direction);
		});
	
	
		$firstSlider.on('afterChange', function( evt, slick, currentSlide ) {
			if( isSliderInViewport() ){
				indexMessage = currentSlide + 1;
			  	$aMessage = $(".slick-active a", $firstSlider);
			  	trackSlider('das neueste: displayed message ' + indexMessage, $aMessage.attr("href"));
			}
		});
	  
	  
		$("a",$firstSlider).on('click', function() {
			trackSlider('das neueste: clicked message ' + indexMessage, $(this).attr("href"));
		});
	
	}
	
	// end tracking
	
	
  if( $bnewsSlides.length ) {
    $bnewsSlides.slick(
      {
        arrows       : false,
        appendArrows : '.bnews__slider__controls',
        appendDots   : '.bnews__slider__controls',
        autoplay     : true,
        autoplaySpeed: 5000,
        dots         : true,
        mobileFirst  : true,
        rows         : 0,
        responsive   : [
          {
            breakpoint: 767,
            settings  : {
              arrows   : true,
              nextArrow: '<button class="slick--next icon icon__next" aria-label="Next"></button>',
              prevArrow: '<button class="slick--prev icon icon__prev" aria-label="Previous"></button>'
            }
          },
          {
            breakpoint: 1279,
            settings  : {
              arrows   : true,
              nextArrow: '<button class="slick--next icon icon__next" aria-label="Next"></button>',
              prevArrow: '<button class="slick--prev icon icon__prev" aria-label="Previous"></button>',
              swipe: false
            }
          }
        ]
      }
    );
  }
};
