"use strict";

if(ntv.urlParams.token && ntv.pageInfo.article && ntv.pageInfo.article.type=='meldung'){
	if(!ntv.urlParams.isIframe){
		$( '<iframe id="previewMobileFrame" src="' + document.location.href + '&isIframe=true&checkNoAd=true" scrolling="no" frameborder="0" style="width: 390px; max-width: 100%; height: 0; display: block; margin: 0 auto 30px auto;"></iframe>' ).insertAfter( "article.article" );
		$("#previewMobileFrame").iFrameResize();
	}else{
		$("#topbanner-mobile").hide();
		var s = document.createElement("script");
		s.async = true;
		s.src = 'https://www.n-tv.de/stat/iframe-resizer/iframeResizer.contentWindow.min.js';
		(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0] || document.getElementsByTagName('script')[0].parentNode).insertBefore(s,null);	
	}
}