"use strict";



var embedVendors = function(){
	

	ntvDebug("*** embedVendors  ***");

	
	if(!ntv.consents.info.vendorsEmbedded){

		ntv.consents.info.vendorsEmbedded = true;
		
		
		embedSocialVendors();
		
		
		// VG Wort
		trackVgwort();


		// infOnline
		// initial fire only if not video
		/*
		if ( !(ntv.pageInfo.article && (ntv.pageInfo.article.type=='video' || ntv.pageInfo.article.type=='video360' || ntv.pageInfo.article.type=='specialvideo'))) {
			if (window.iom && window.iam_data && ntv.consents.infonline) {
				ntvDebug("*** infOnline after consent ***");
				iom.c(iam_data,1);
			}
		}
		*/
		


		// Google Analytics
	    if(!ntv.disable.ga && ntv.consents.ga && window.ga){
	    	
    	  	ga('set', 'allowAdFeatures', ntv.consents.googleAd?true:false);
    	  	ga('set', 'dimension111', 'na');
    	  	ga('send', 'pageview');
	    	  
	    }
	    


		// Kameleoon
		// actual is embedded in the <head>
		/*
		if(!ntv.disable.kameleoon && ntv.consents.kameleoon){
		  var kameleoonQueue = kameleoonQueue || [];
		  var s = document.createElement("script");
		  s.async = true;
		  s.src = "//j7q313hy22.kameleoon.eu/kameleoon.js";
		  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0] || document.getElementsByTagName('script')[0].parentNode).insertBefore(s,null);
		}
		*/
		
		

		/*
		if(window.kameleoonQueue){// kameleoon is embedded
			if(ntv.consents.kameleoon){
				kameleoonQueue.push(function() {
					Kameleoon.API.Core.enableLegalConsent();  
				});
			}else{
				kameleoonQueue.push(function() {
					Kameleoon.API.Core.disableLegalConsent();
				});				
			}
		}
		*/


		//Chartbeat
		if(!ntv.disable.chartbeat && ntv.consents.chartbeat && ntv.pageInfo){
		    (function() {
		    	
		        /** CONFIGURATION START **/
		        var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});
		        _sf_async_config.uid = 65795;
		        _sf_async_config.domain = 'n-tv.de';
		        //_sf_async_config.useCanonicalDomain = true;
		        //_sf_async_config.useCanonical = true;
		        if(ntv.pageInfo.article && ntv.pageInfo.article.id){
		        	_sf_async_config.path = '/'+ntv.pageInfo.article.id;
		        }else{
	        		_sf_async_config.useCanonical = true;
	        		_sf_async_config.useCanonicalDomain = true;
		        	/*if(ntv.pageInfo.url){
		        		_sf_async_config.path = (ntv.pageInfo.url).replace("https://www.","");
		        	}*/
		        }
		        if(ntv.pageInfo.section){
		        	_sf_async_config.sections = ntv.pageInfo.section;
		        }
		        if(ntv.pageInfo.article && ntv.pageInfo.article.id){
		        	_sf_async_config.authors = ntv.pageInfo.article.id;
		        }
		        if(ntv.pageInfo.article && ntv.pageInfo.article.headline){
		        	_sf_async_config.title = ntv.pageInfo.article.headline;
		        }else{
			        if(ntv.pageInfo.section){
				        _sf_async_config.title = ntv.pageInfo.section;
				    }
		        }
		        _sf_async_config.flickerControl = false;
		        /** CONFIGURATION END **/
		        function loadChartbeat() {
		            var e = document.createElement('script');
		            var n = document.getElementsByTagName('script')[0];
		            e.type = 'text/javascript';
		            e.async = true;
		            e.src = '//static.chartbeat.com/js/chartbeat.js';
		            n.parentNode.insertBefore(e, n);
		            
		            // for testing headlines
		            var e = document.createElement('script');
		            var n = document.getElementsByTagName('script')[0];
		            e.type = 'text/javascript';
		            e.async = true;
		            e.src = '//static.chartbeat.com/js/chartbeat_mab.js';
		            n.parentNode.insertBefore(e, n);
		        }
		        loadChartbeat();
		     })();
		}

		
		
		// Outbrain
		var embedOutbrain = function ($containers) {
			
			$containers.each( function(){
				var $container = $(this);
				var widgetID = $container.data("obid");
				$container.html('<div class="OUTBRAIN" data-src="' + ntv.pageInfo.url + '" data-widget-id="' + widgetID +'"></div>');
				
			});
			
			if(ntv.pageInfo.article && ntv.pageInfo.article.id && $("article.article").length > 0 ){
				
					var trackingDispDone = false;
	
				  var obReadMoreTracking = function(mutationsList, observer) {
	
					  mutationsList.forEach(function(mutation) {
						  
						    if(mutation.addedNodes && mutation.addedNodes.length>0){
						    	
						    	if( $("#ob-readmore-button").length > 0 ){
						    		
						    		if(!trackingDispDone){
									    gaEvent({
									        eventCategory: 'Read-More Button',
									        eventAction: 'Displayed'
									    });

										dataLayer.push({
											'event': 'ga4_event',
											'eventcategory': 'Read-More Button',
											'eventaction': 'Displayed'
										});
							    		trackingDispDone = true;
						    		}
						    		
						    		
									$("#ob-readmore-button").on( 'click', function() {
									    gaEvent({
									        eventCategory: 'Read-More Button',
									        eventAction: 'Click'
									    });

										dataLayer.push({
											'event': 'ga4_event',
											'eventcategory': 'Read-More Button',
											'eventaction': 'Click'
										});
				
									});
									observer.disconnect();
						    	}
						    }
						  
					  });
				};
				
				var observer = new MutationObserver(obReadMoreTracking);
				observer.observe( $("article.article")[0], {childList: true} );
				
			}
	
			
		  var s = document.createElement("script");
		  s.async = true;
		  s.src = "//widgets.outbrain.com/outbrain.js";
		  (document.getElementsByTagName('body')[0] || document.getElementsByTagName('head')[0] || document.getElementsByTagName('script')[0].parentNode).insertBefore(s,null);
		  
		  
		  // Video Intelligence
		  
		  /*
		  if (ntv.pageInfo.article && ntv.pageInfo.article.type=="meldung"){
			
			(function (v,i) {
			    var scp = v.createElement("script"),
			    config = {
			      ChannelID: 'wm5rfm7m9',
			      AdUnitType: '2',
			      PublisherID: '414445714233881',
			      PlacementID: 'pltp4GQRN4my1VT88BZ',
			      DivID: '',
			      IAB_Category: 'IAB1',
			      Keywords: '',
			      Language: 'en-us',
			      BG_Color: '',
			      Text_Color: '',
			      Font: '',
			      FontSize: '',
			    };
			    scp.src='https://s.vi-serve.com/tagLoader.js';
			    scp.type = "text/javascript";
			    scp.async = true;
			    scp.onload = function() {
			      i[btoa('video intelligence start')].init(config);
			    };
			    (v.getElementsByTagName('head')[0] || v.documentElement.appendChild(v.createElement('head'))).appendChild(scp);
			  })(document, window);
			
		  }
		  */
		  	
	
		};
		

	
		var $obContainers = $('.ob_embed:visible'); // container for outbrain-embed
		if ($obContainers.length > 0 && !ntv.pageInfo.pmode) {
		  	  embedOutbrain($obContainers);
		}
		
		
		// Sonderlocke Olympia Liveticker
		if(document.location.href=='https://sportdaten.n-tv.de/olympia/peking-2022/liveticker/' && !ntv.pageInfo.pmode){
			//$('.content--main').append('<div class="ob_embed" data-obid="47"></div>');
			$('.content--main').append('<div class="OUTBRAIN" data-src="https://sportdaten.n-tv.de/olympia/peking-2022/liveticker/" data-widget-id="AR_47"></div>');
			
			  var s = document.createElement("script");
			  s.async = true;
			  s.src = "//widgets.outbrain.com/outbrain.js";
			  (document.getElementsByTagName('body')[0] || document.getElementsByTagName('head')[0] || document.getElementsByTagName('script')[0].parentNode).insertBefore(s,null);	
		
		}
			
			
		// show heroes

		var embedShowHeroes = function ($container) {

			  if(ntv.consents.showheroes){
				
					// Test contentroll der tag, sporttag börsentag
					if( !$container.attr("data-src") ){
						
						
						  window.addEventListener('AC_player_start', function (e) {

							  var url = new URL(document.URL);
							  var domain = function(u) {
							    return u.host.split('.')[1];
							  };
							  //var category = "test_" + domain(url) + "_" + domain(url) + encodeURIComponent("/") + url.pathname.split('/')[1];
							  var category = "ntv_ntv" + encodeURIComponent("/") + url.pathname.split('/')[1];
							  //var keywords = $AC.getSXPKeys();
							  
							  var keywords = window.$AC?$AC.getSXPKeys():'';
							  var gdpr_opt = 'build a function to know if we have consent';
							  //var gdpr_opt = 'true';
							  //var URL_player = "https://content.viralize.tv/display/?zid=AAEs1hyrQZ2mgmtc&u=" + url + "&channel=" + category + "&pub_keywords=" + keywords + "";
							  var URL_player = "https://content.viralize.tv/display/?zid=AAEslpIWoEIO7-95&u=" + url + "&channel=" + category + "&pub_keywords=" + encodeURIComponent(keywords) + "&schain=1.0,1!ad-alliance.de,csid_22395447709,1,,,";
							
							  var script_shg = document.createElement('script');
							  script_shg.setAttribute('type', 'text/javascript');
							  script_shg.setAttribute('src', URL_player);
							  script_shg.setAttribute('data-wid', 'auto');
							  //document.body.appendChild(script_shg);
							  document.getElementById($container.attr("id")).insertBefore(script_shg,null);




						  	// gdpr_opt??????
						  
						  
						  
						   });

						
						
					}else{
					
					  var s = document.createElement("script");
					  s.async = true;
					  s.src = $container.attr("data-src");
					  s.setAttribute("data-wid", "auto");
					  document.getElementById($container.attr("id")).insertBefore(s,null);
				  	}
			  
			  }

		};
		
		
		  var $showheroesContainer = $('#showheroes_embed');
		  if ($showheroesContainer.length > 0) {
			  embedShowHeroes($showheroesContainer);
		  }
		  
		  
			// Video Intelligence
			// consent???
			if ($("#vi_embed").length > 0) {
	
					(function (v,i) {
					    var scp = v.createElement("script"),
					    config = {
					      ChannelID: 'wm5rfm7m9',
					      AdUnitType: '2',
					      PublisherID: '414445714233881',
					      PlacementID: 'pltp4GQRN4my1VT88BZ',
					      DivID: '',
					      IAB_Category: 'IAB1',
					      Keywords: '',
					      Language: 'en-us',
					      BG_Color: '',
					      Text_Color: '',
					      Font: '',
					      FontSize: '',
					    };
					    scp.src='https://s.vi-serve.com/tagLoader.js';
					    scp.type = "text/javascript";
					    scp.async = true;
					    scp.onload = function() {
					      i[btoa('video intelligence start')].init(config);
					    };
					    (v.getElementsByTagName('head')[0] || v.documentElement.appendChild(v.createElement('head'))).appendChild(scp);
					  })(document, window);
			}
		  
		
		/*
		if(ntv.consents.showheroes){

			var embedShowHeroes = function ($container) {
	
				  var s = document.createElement("script");
				  s.async = true;
				  s.src = $container.attr("data-src");
				  s.setAttribute("data-wid", "auto");
				  document.getElementById($container.attr("id")).insertBefore(s,null);
			};
			
			
			
			
			
			var embedVi = function(){
				(function (v,i) {
				    var scp = v.createElement("script"),
				    config = {
				      ChannelID: 'wm5rfm7m9',
				      AdUnitType: '2',
				      PublisherID: '414445714233881',
				      PlacementID: 'pltp4GQRN4my1VT88BZ',
				      DivID: '',
				      IAB_Category: 'IAB1',
				      Keywords: '',
				      Language: 'en-us',
				      BG_Color: '',
				      Text_Color: '',
				      Font: '',
				      FontSize: '',
				    };
				    scp.src='https://s.vi-serve.com/tagLoader.js';
				    scp.type = "text/javascript";
				    scp.async = true;
				    scp.onload = function() {
				      i[btoa('video intelligence start')].init(config);
				    };
				    (v.getElementsByTagName('head')[0] || v.documentElement.appendChild(v.createElement('head'))).appendChild(scp);
				  })(document, window);
			};
				

			
			
			  var $showheroesContainer = $('#showheroes_embed');
			  
			  
			  if ($showheroesContainer.length > 0) {
				
					if(ntv.urlParams.testContentRoll=='true' && ntv.pageInfo && ntv.pageInfo.article && ntv.pageInfo.article.id){
						
						var artId = parseInt(ntv.pageInfo.article.id);
						
						console.log("*** contentRoll ***");
						console.log(artId);
						
						if(artId%2 == 0){
							console.log("showheroes");
							embedShowHeroes($showheroesContainer);
						}else{
							console.log("vi");
							embedVi();
						}
						
						
						
						
					}else{
				  		embedShowHeroes($showheroesContainer);
				  	}
			  }
  
		}
		*/
			  
  
			  
			  
		// technical-service pixel
		  /*
		if(ntv.consents.techservice){
			var tsImg = document.createElement("img");
			tsImg.className = "pixel";
			tsImg.alt = "technical-service";
			tsImg.id = "technical-service";
			tsImg.src = "//technical-service.net/pixel.gif?agf=" + ntv.trackingParamsInit.agof_code + "&amp;d=" + new Date().getTime();
			tsImg.width = "1";
			tsImg.height = "1";
			document.body.appendChild(tsImg);
		}
		*/
	
		

		// adDefend
	 	//if(!ntv.disable.addefend && ntv.consents.addefend) {
	 	if(!ntv.disable.addefend && !ntv.pageInfo.pmode) {
	 		// function is in wireframe adaptive.jsp
	 		embedAddefend();
	 	}

			


	}

};