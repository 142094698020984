'use strict';

var partnerSliders = function() {

  /*
   * Partner SLIDER
   */

  var $partnerSlides = $( '.partner__slides' );
  if( $partnerSlides.length ) {
    $partnerSlides.slick(
      {
        arrows       : false,
        appendArrows : '.partner__slider__controls',
        appendDots   : '.partner__slider__controls',
        autoplay     : true,
        autoplaySpeed: 5000,
        dots         : true,
        mobileFirst  : true,
        responsive   : [
          {
            breakpoint: 767,
            settings  : {
              arrows   : true,
              nextArrow: '<button class="slick--next icon icon__next" aria-label="Next"></button>',
              prevArrow: '<button class="slick--prev icon icon__prev" aria-label="Previous"></button>'
            }
          },
          {
            breakpoint: 1279,
            settings  : {
              //swipe: false
            }
          }
        ]
      }
    );
  }
};
