'use strict';

var embedProdo = function(){


	var prodoDisc = function($prodo){

		var $container = $prodo.parents(".prodo-wrapper");

		var $disc = $(".prodo-disc",$container);

		if($disc.length > 0) {
			$disc.show();
		}else{

			var html = '';
			html += '<div class="prodo-disc" style="position: absolute; bottom: 20px; background: white; padding: 20px; width: 100%; box-shadow: 0 3px 7px -3px rgba(0, 0, 0, 0.7); font-size: 1.3rem; line-height: 1.7rem; z-index: 10000">';
			html += '<span class="prodo-disc-close" style="position: absolute; right: 20px; top: 10px; font-weight: bold; font-size: 1.5rem; cursor: pointer;">X</span>';
			html += '<strong style="display: block; margin-bottom: 5px; font-size: 1.5rem;">Werbehinweise:</strong>';
			html += 'Die Billigung des Basisprospekts durch die Bundesanstalt für Finanzdienstleistungsaufsicht ist nicht als ihre Befürwortung der angebotenen Wertpapiere zu verstehen. Wir empfehlen Interessenten und potenziellen Anlegern den Basisprospekt und die Endgültigen Bedingungen zu lesen, bevor sie eine Anlageentscheidung treffen, um sich möglichst umfassend zu informieren, insbesondere über die potenziellen Risiken und Chancen des Wertpapiers. <strong>Warnhinweise: Sie sind im Begriff, ein Produkt zu erwerben, das nicht einfach ist und schwer zu verstehen sein kann.</strong>';
			html += '</div>';

			$container.prepend(html);
			$(".prodo-disc-close", $container).on('click', function(){
				$(".prodo-disc",$container).hide();
			});
		}
	};
	
	// mapping instrument ID ti ISIN für Indikationen (keine ISIN via API)
	var instIdMap = {
		"510583": "DE0008469008",	// DAX
		"12235003": "DE0008467416",	// MDax
		"843834": "EU0009658145",	// EST
		"849973": "US2605661048",	// Dow
		"12235004": "DE0007203275",	// TDax
		"340566": "US6311011026",	// NasF
		"849976": "US78378X1072",	// S&P
		"849975": "JP9010C00002",	// N225
		// 21072024
		"3561102": "DE0008469008",	// DAX
		"3561101": "DE0008467416",	// MDax
		"3561104": "EU0009658145",	// EST
		"3561106": "US2605661048",	// Dow
		"3561103": "DE0007203275",	// TDax
		//"340566": "US6311011026",	// NasF
		"3561107": "US78378X1072",	// S&P
		"3561105": "JP9010C00002",	// N225
		// ?
		"9451088": "DE0008469008",	// DAX
		"9451122": "DE0008467416",	// MDax
		"9451192": "EU0009658145",	// EST
		"9451139": "US2605661048",	// Dow
		"9451105": "DE0007203275",	// TDax
		"9451178": "US6311011026",	// NasF
		"9451160": "US78378X1072",	// S&P
		"9451212": "JP9010C00002"	// N225
	}
	
	var renderProdo = function(){

		if(window.PRODO){

			if ($("#prodo-ad").length > 0) {

				var prodo = PRODO;
				var isin = '';
				var adSlotId = '';

				if (ntv.pageInfo.url == 'https://www.n-tv.de/boersenkurse/' || ntv.pageInfo.url == 'https://preview.n-tv.de/boersenkurse/'){
					if(ntv.urlParams.service=='webview'){
						adSlotId = '5d2f0f73-13b3-4801-b8cb-078d94c7fd8d';
					}else{
						adSlotId = '8cd1109b-d18c-44d3-b3fd-2ccf9dd97cc2';
					}
					isin = 'DE0008469008';
				}else{
					if(ntv.urlParams.service=='webview'){
						adSlotId = '6e702013-5497-4df1-9a72-39e8acaa9b76';
					}else{
						adSlotId = 'af894e14-7312-4fa1-b523-1a83fc6798ec';
					}
					if (ntv.chartPage){
						if (ntv.chartPage.isin){
							isin = ntv.chartPage.isin;
						}else{
							if (ntv.chartPage.instrumentId && instIdMap[ntv.chartPage.instrumentId]){
								isin = instIdMap[ntv.chartPage.instrumentId];
							}
						}
					}
				}

				prodo.adSlotId(adSlotId);
				prodo.elementId("prodo-ad");
				prodo.removeOuterElementIfEmpty(false);
				prodo.removeOuterDisclaimerIfEmpty(true);
				prodo.impactingIsins([isin]);
				prodo.userInterestDerivativeTypeIds([]);

				prodo.loadAdvertisement(function(){
					var $prodo = $("#prodo-ad");

					$prodo.css("margin-bottom","20px");
					$prodo.wrapAll('<div class="prodo-wrapper" style="position: relative;" />');

					
					if ($prodo[0].shadowRoot){
						
						if ($prodo[0].shadowRoot.querySelector('a[href="#footer"]')){
							$prodo[0].shadowRoot.querySelector('a[href="#footer"]').onclick = function(){
									prodoDisc($prodo);
									return false;
							};
						}
						if ($prodo[0].shadowRoot.querySelector('a[href="#foo"]')){
							$prodo[0].shadowRoot.querySelector('a[href="#foo"]').onclick = function(){
									prodoDisc($prodo);
									return false;
							};
						}
						
					}
					
					/*
					if ($prodo[0].shadowRoot && $prodo[0].shadowRoot.querySelector('.prodo-disclaimer a')){
						$prodo[0].shadowRoot.querySelector('.prodo-disclaimer a').onclick = function(){
								prodoDisc($prodo);
								return false;
						};
					}
					*/
				});
				
				ntvDebug("*** prodo ***");
				ntvDebug(prodo);
			}

			if ($("#prodo-ad2").length > 0) {
				var prodo2 = PRODO;

				//prodo2.adSlotId("8cd1109b-d18c-44d3-b3fd-2ccf9dd97cc2");
				prodo2.adSlotId($("#prodo-ad2").data("slotid"));
				prodo2.elementId("prodo-ad2");
				prodo2.removeOuterElementIfEmpty(false);
				prodo2.removeOuterDisclaimerIfEmpty(true);
				prodo2.impactingIsins([]);
				prodo2.userInterestDerivativeTypeIds([]);

				prodo2.loadAdvertisement(function(){
					var $prodo = $("#prodo-ad2");

					$prodo.css("margin-bottom","20px");
					$prodo.wrapAll('<div class="prodo-wrapper" style="position: relative;" />');
					
					if ($prodo[0].shadowRoot){
						
						if ($prodo[0].shadowRoot.querySelector('a[href="#footer"]')){
							$prodo[0].shadowRoot.querySelector('a[href="#footer"]').onclick = function(){
									prodoDisc($prodo);
									return false;
							};
						}
						if ($prodo[0].shadowRoot.querySelector('a[href="#foo"]')){
							$prodo[0].shadowRoot.querySelector('a[href="#foo"]').onclick = function(){
									prodoDisc($prodo);
									return false;
							};
						}
						
					}

					/*
					if ($prodo[0].shadowRoot && $prodo[0].shadowRoot.querySelector('.prodo-disclaimer a')){

						$prodo[0].shadowRoot.querySelector('.prodo-disclaimer a').onclick = function(){
								prodoDisc($prodo);
								return false;
						};
					}
					*/
				});
				
				ntvDebug("*** prodo2 ***");
				ntvDebug(prodo2);

			}


		}
	};


	if ( (ntv.chartPage && (ntv.chartPage.isin || (ntv.chartPage.instrumentId && instIdMap[ntv.chartPage.instrumentId]))) || ntv.pageInfo.url == 'https://www.n-tv.de/boersenkurse/' || ntv.pageInfo.url == 'https://preview.n-tv.de/boersenkurse/' || ntv.pageInfo.url == 'https://www.n-tv.de/boersenkurse/Auf_einen_Blick/' || ntv.pageInfo.url == 'https://preview.n-tv.de/boersenkurse/Auf_einen_Blick/' ) {
	//if ( ntv.chartPage && ntv.chartPage.isin ) {

		var s = document.createElement("script");
		s.async = true;
		s.src = 'https://prodo-api.ariva-services.de/clientjs/ProdoClient.js?' + Math.random()*10000000000000000000;
		s.onload = function(){
			renderProdo();
		};

		(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0] || document.getElementsByTagName('script')[0].parentNode).insertBefore(s,null);

	}


};