'use strict';

var newsletter = function () {
  if (window.location.href.includes('newsletter')) {
	  
    var newsletterValid = $('.newsletter__valid');
    var $inputfield = newsletterValid.find('input');

    if (sessionStorage.getItem("newsletter-mail") !== null) {
      $inputfield[0].value = sessionStorage.getItem("newsletter-mail");
      sessionStorage.removeItem("newsletter-mail");
    }
    
    
    // tracking für Newsletter verwalten
    if( $(".newsletter__valid button[value='save']").length > 0 && $(".newsletter__valid button[value='unsubscribe']").length > 0 ) {
    	
    	// user ist nach registrierung zurück via Aktivierungslink aus Email
    	// tracking Abo's Erstanmeldung
    	if(ntv.urlParams.regist=="true"){
    		
    		var label = "";
    		$("#channelForm input[name='channel-sendtime']:checked").each(function(index){
    			
    			if(index>0){
    				label += ","
    			}
    			label += $(this).parents(".newsletter__group").find(".newsletter__title").text();
    		});
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'newsletter',
				'eventaction': 'subscription_success',
				'eventlabel': label
			});
			dataLayer.push({
				'event': 'ga4_event' ,
				'eventcategory': 'newsletter',
				'eventaction': 'subscription_success',
				'eventlabel': label
			});
    	}
    	
    	
    	// tracking Änderungen Abo's
    	$(".newsletter__valid button[value='save']").on('click', function (e) {
    		
    		var label = "";
    		$("#channelForm input[name='channel-sendtime']:checked").each(function(index){
    			
    			if(index>0){
    				label += ","
    			}
    			label += $(this).parents(".newsletter__group").find(".newsletter__title").text();
    		});
    		
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'newsletter',
				'eventaction': 'resubscription_success',
				'eventlabel': label
			});
			dataLayer.push({
				'event': 'ga4_event' ,
				'eventcategory': 'newsletter',
				'eventaction': 'resubscription_success',
				'eventlabel': label
			});

    	});
    }
    
	// tracking abonnieren
	$(".newsletter__valid button[value='abo']").on('click', function (e) {
		
		var label = "";
		$("#channelForm input[name='channel-sendtime']:checked").each(function(index){
			
			if(index>0){
				label += ","
			}
			label += $(this).parents(".newsletter__group").find(".newsletter__title").text();
		});
		
		dataLayer.push({
			'event': 'page-track' ,
			'eventcategory': 'newsletter',
			'eventaction': 'subscription_sent',
			'eventlabel': label
		});
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'newsletter',
			'eventaction': 'subscription_sent',
			'eventlabel': label
		});
	});
    
	// tracking Newsletter Abmeldung
	// über verwalten
	$(".newsletter__valid button[value='unsubscribe']").on('click', function (e) {
		dataLayer.push({
			'event': 'page-track' ,
			'eventcategory': 'newsletter',
			'eventaction': 'unsubscription_success'
		});
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'newsletter',
			'eventaction': 'unsubscription_success'
		});
	});
	// über Antwort vom popup-Formular
	 if( $(".newsletter .newsletter__teaser--unsubscribe").length > 0 ){
		dataLayer.push({
			'event': 'page-track' ,
			'eventcategory': 'newsletter',
			'eventaction': 'unsubscription_success'
		});
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'newsletter',
			'eventaction': 'unsubscription_success'
		});
	 }
	 
		// pop-up für newsletter verwalten
		$( '.newsletter' ).on( 'click', '.newsletter__bottom__pref a', function( e ) {
			e.preventDefault();
			
			// tracking click "verwalten"
			dataLayer.push({
				'event': 'page-track' ,
				'eventcategory': 'newsletter',
				'eventaction': 'resubscription_interest'
			});
			dataLayer.push({
				'event': 'ga4_event' ,
				'eventcategory': 'newsletter',
				'eventaction': 'resubscription_interest'
			});
			
			
			$( this ).parents( '.newsletter' )
							 .find( '.popup' )
							 .show();
			$( 'body' ).css( { 'overflow': 'hidden' } )
		} );


		
		// pop-up für newsletter abmelden
		$( '.newsletter' ).on( 'click', '.newsletter__bottom__unsubscribe a', function( e ) {
				e.preventDefault();
				
				// tracking click "abmelden"
				dataLayer.push({
					'event': 'page-track' ,
					'eventcategory': 'newsletter',
					'eventaction': 'unsubscription_interest'
				});
				dataLayer.push({
					'event': 'ga4_event' ,
					'eventcategory': 'newsletter',
					'eventaction': 'unsubscription_interest'
				});
				
				$( this ).parents( '.newsletter' )
								 .find( '.popup__unsubscribe' )
								 .show();
				$( 'body' ).css( { 'overflow': 'hidden' } )
		} );
		
		// close pop-up für newsletter abmelden
		$( '.popup__unsubscribe' ).on( 'click', '.popup__close', function() {
				$( this ).parents( '.popup__unsubscribe' ).hide();
				$( 'body' ).css( { 'overflow': 'auto' } );
		} );
    
  }
  




  
  function storeMail(wrapper) {
    var newsletterForm = $(wrapper);
    var $mail = newsletterForm.find('input')[0].value;

    if ($mail.length > 0) {
      if (window.sessionStorage) {
        sessionStorage.setItem('newsletter-mail', $mail);
      }
    }
    window.location.href = '/newsletter';

  }

  $('.teaser--newsletter').find('button[type="submit"]').on('click', function (e) {
    e.preventDefault();
    // tracking newsletter teaser from sidebar
	dataLayer.push({
		'event': 'page-track' ,
		'eventcategory': 'newsletter',
		'eventaction': 'subscription_interest'
	});
	dataLayer.push({
		'event': 'ga4_event' ,
		'eventcategory': 'newsletter',
		'eventaction': 'subscription_interest'
	});
    storeMail('.teaser--newsletter');
  })

  $('.sitemap__newsletter').find('button[type="submit"]').on('click', function (e) {
    e.preventDefault();
    storeMail('.sitemap__newsletter');
  })


};
