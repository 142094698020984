"use strict";


var ntvSocialVendors = {
		facebookpost: "5ee91b9593fc094b59242e25",
		facebookvideo: "5ee91b9593fc094b59242e25",
		instagram: "5e717c8e69966540e4554f05",
		tiktok: "5e7f6927b8e05c4e491e7380",
		twitter: "5e71760b69966540e4554f01",
		youtube: "5e7ac3fae30e7d1bc1ebf5e8"
};


var ntvSocialVendorsCookie = {
		facebookpost: "_ntv_co_fbp",
		facebookvideo: "_ntv_co_fbv",
		instagram: "_ntv_co_ig",
		tiktok: "_ntv_co_tik",
		twitter: "_ntv_co_tw",
		youtube: "_ntv_co_yt"
};



//Purpose ID "Fremdinhalte anzeigen", "Store/Access information"
var socialPurposeIds = [ '63036358bcd13b04fe719498', '63036358bcd13b04fe719146' ];


var evalConsents = function(consents){
	
	ntvDebug("*** evalConsents  ***");
	ntvDebug(consents);
	
	
	var ntvVendors = {
		ga: "5e542b3a4cd8884eb41b5a72",
		googleAd: "5f1aada6b8e05c306c0597d7",
		chartbeat: "5ea172e36ede87504f7b4590",
		kameleoon: "5f48d229b8e05c60a307ad97",
		infonline: "5efefe25b8e05c065164a2e2",
		showheroes: "5eab3d5ab8e05c1c467dab7b",
		techservice: "5edf9c6c6763dd565df1af9e",
		addefend: "5e98e7f1b8e05c48537f6114",
		nielsen: "5f9be0a9a228636148510755",
		vgwort: "5ef4bd8facb29b0b89038406"
	};
	

	if(consents === undefined) {
	
	    for(var key in ntvVendors) {
    		ntv.consents[key]=false;
	    }
	    
	    for(var key in ntvSocialVendors) {
	    	if(ntv.pageInfo.pmode && getCookie(ntvSocialVendorsCookie[key])=="true"){
	    		//cookie wird im PUR Modus als sessioncookie in renderExtContent.js -> storeSocialConsent() geschrieben
	    		ntv.consents[key]=true;
	    	}else{
	    		ntv.consents[key]=false;
	    	}
	    }
		
	}else{
	
		var grants = consents.grants;
		
	    for(var key in ntvVendors) {
	    	
	    	if( grants[ntvVendors[key]] && grants[ntvVendors[key]].vendorGrant){
	    		ntv.consents[key]=true;
	    	}else{
	    		ntv.consents[key]=false;
	    	}
	    }
	    
	    for(var key in ntvSocialVendors) {
	    	
	    	ntv.consents[key] = true;
	    	
	    	if( grants[ntvSocialVendors[key]] && grants[ntvSocialVendors[key]].vendorGrant){
	    		
	    		socialPurposeIds.forEach(function(id){
	    			if(!grants[ntvSocialVendors[key]].purposeGrants[id]){
	    				ntv.consents[key]=false;
	    			}
	    			
	    		});
	    	}else{
	    		ntv.consents[key]=false;
	    	}
	    }
	    
	    
	    ntv.consents.info.grants = grants;
    
	}
    
    window.dispatchEvent( new CustomEvent('ntvConsentReady') );
	

};
