'use strict';

/**** Sport Ticker Update ****/

var widgetIntervalIds = [];

function refreshListener(e) {
  e.preventDefault();
  var element = $(this);
  var elementId = e.currentTarget.getAttribute('id');

  if (elementId == undefined) {
    elementId = btoa('NewHash' + e.timeStamp)
    e.currentTarget.setAttribute('id', elementId);
  }

  if (widgetIntervalIds[elementId] != undefined) {
    window.clearInterval(widgetIntervalIds[elementId]);
    widgetIntervalIds[elementId] = undefined;
    element.removeClass('active');
  } else {
    element.addClass('active');
    widgetIntervalIds[elementId] = window.setInterval(function () {
      reloadSportTable(element)
    }, 60000);
    reloadSportTable(element);
  }
}

function reloadSportTable(element) {
  var sport = element.closest('.sport');
  var url = sport.data('href');

  $.ajax({
    type: 'GET',
    url: url,
    success: function (data) {
      var newElement = $('<div>');
      newElement.html(data);
      var n2 = newElement.find('.sport__content');
      sport.find('.sport__content').replaceWith(n2);
    }
  });
}

$('.sport .sport__update').on("click", refreshListener);

/**** BWIN TICKER UPDATE ****/

var handleBwin = function ($container) {
  gaEvent({
    eventCategory: 'bwin',
    eventAction: "startpage widget view",
    nonInteraction: true
  });
	dataLayer.push({
		'event': 'ga4_event' ,
		'eventcategory': 'bwin',
		'eventaction': 'startpage widget view'
	});

  var updateBwin = function () {
    var element = $('.sport.bwin');
    var url = element.data('href');

    $.ajax({
      type: 'GET',
      url: url,
      success: function (data) {
        element.replaceWith(data)
      }
    });
  };

  setInterval(updateBwin, 30000);
};
