'use strict';

var clipboardHandler = function( targetClass ) {

	var clipboard = new Clipboard( targetClass );
	
	/*
	$( '.article__share img[src*="link"]' ).on( 'click', function( evt ) {
		evt.preventDefault();
	} );
	*/
	
	$(targetClass).on( 'click', function( evt ) {
		evt.preventDefault();
	} );

	clipboard.on( 'success', function( e ) {
		var $elem = $( e.trigger ).parent(); // e.trigger is the img element - $elem targets the a tag

		// We check if we already have the message,
		// if it exists, we don't want to add it again
		if( $elem.find( 'span.article__share--clicked' ).length < 1 ) {
			// We create the span displaying the message
			$elem.prepend( '<span class="article__share--clicked">Link Kopiert!</span>' )
					 .on( 'mouseleave', function() {
						 // We remove the message when the user leaves it
						 $( this ).find( 'span.article__share--clicked' )
											.animate( { 'width': 0, 'left': 0 }, 100, function() {
												$( this ).remove();
											} );
					 } );

			e.clearSelection();
		}
	} );

};
