'use strict';

var poll = function() {
	var $forms = $( '.poll__form' );
//	var $pollElements = $( '.poll__element' );

	// Fetch the results of the poll, and replace the answers by the results
	var fetchPoll = function( url, $form, pollValue ) {
		var data = !!pollValue ? { poll: pollValue } : {};
		$.ajax(
			{
				url : url,
				type: 'POST',
				data: data
			} )
		 .done( function( data ) {
			 $form.replaceWith( data );
		 } );
	};

	var initPolls = function() {

		$( '.poll' ).find( 'input' ).prop( 'disabled', false );
		// Dummy localStorage value for testing
//		localStorage.setItem( 'ntv-poll-voted', '[ "12345", "123456", "12359" ]' );

		// We fetch the local storage item
//		var localStorageValue = localStorage.getItem( 'ntv-poll-voted' );
		// We check if there is our localStorage set
		// If yes, we parse its value, otherwise we just define an empty array
//		var localData = !!localStorageValue ? JSON.parse( localStorageValue ) : [];
//		console.log( 'localStorage ids: ', localData );

		// We fetch the ids of all the polls actually displayed
//		var pollsIdsList = [];
//		$pollElements.each( function() {
//			pollsIdsList.push( $( this ).attr( 'id' ) );
//		} );
//		console.log( 'displayed polls ids: ', pollsIdsList );

		// We check first if the listed voted polls are still displayed, otherwise we remove them from the list.
		// We clone the array first as we will remove the non displayed ids from it
//		var localDataClone = [];
//		var localDataLength = localData.length;
//		while( localDataLength-- ) {
//			localDataClone[ localDataLength ] = localData[ localDataLength ];
//		}
//		localDataClone.forEach( function( localId ) {
//			if( pollsIdsList.indexOf( localId ) ) {
//				localData.splice( localData.indexOf( localId ), 1 );
//			}
//		} );
//		console.log( 'localStorage values cleaned: ', localData );

		// We iterate through the ids of the polls displayed
		// And see if its id is in our localStorage (already voted for it)
		// If not, we will remove this value from the localStorage list, and activate the poll
//		pollsIdsList.forEach( function( pollId ) {
//			var pollVoted = localData.some( function( localId ) {
//				return pollId === localId;
//			} );
//
//			// User has never voted for this poll, we enable it
//			if( !pollVoted ) {
//				$( '#' + pollId ).find( 'input' ).prop( 'disabled', false );
//			}
//		} );
//		console.log( localData );
//		localStorage.setItem( 'ntv-poll-voted', JSON.stringify( localData ) );

		// User submits its vote
		$forms.submit( function( e ) {
			e.preventDefault();
			var $form = $( e.currentTarget );
			var url = $form.attr( 'action' );

			var $inputChecked = $form.find( 'input[name=poll]:checked' );
//			console.log( $inputChecked );

			if( $inputChecked.length > 0 ) {
				// We have an answer checked, so fetch the results of the poll
				fetchPoll( url, $form, $inputChecked.val() );

				// We fetch the id of the poll
				var id = $form.parents( '.poll__element' ).attr( 'id' );

				// We push the id on the list of already voted polls
//				localData.push( id );
//				console.log( localData );

				// We store the voted polls' ids in the local storage
//				localStorage.setItem( 'ntv-poll-voted', JSON.stringify( localData ) );
			}
			else {
				// No answer selected, we just display the results
				// We send false because there is no poll id here, so nothing to send
				fetchPoll( url, $form, false );
			}

		} ); // end $( '.poll__form' )
	};

	// We initialize the poll
	initPolls();

};
