'use strict';


var moderatorsSliders = function() {

	  var $msModerators = $( '.slider__slides--moderator' );
	  
   if( $msModerators.length ) {
	  
	  var $msModeratorsOptions = {
	    appendArrows: '.slider--moderator .slider__nav',
	    appendDots  : '.slider--moderator .slider__nav',
	    arrows      : true,
	    dots        : true,
	    infinite    : false,
	    mobileFirst : true,
	    nextArrow   : '<button class="slick--next icon icon__next" aria-label="Next"></button>',
	    prevArrow   : '<button class="slick--prev icon icon__prev" aria-label="Previous"></button>',
	    responsive  : [
	      {
	        breakpoint: 3,
	        settings  : {
	          arrows        : false,
	          slidesToShow  : 3,
	          slidesToScroll: 3
	        }
	      },
	      {
	        breakpoint: 767,
	        settings  : {
	          slidesToShow  : 6,
	          slidesToScroll: 6
	        }
	      },
	      {
	        breakpoint: 1279,
	        settings  : {
	          slidesToShow  : 3,
	          slidesToScroll: 3,
	          swipe         : false
	        }
	      }
	    ]
	  };


	  $msModerators.slick( $msModeratorsOptions );
  }
};
