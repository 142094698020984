'use strict';

var weather = function ($weather) {
  var renderWeatherData = function (id) {
    // var url = 'https://wetter.n-tv.de/das_wetter/data/adaptive/header/' + id + '/?format=json'; //OLD
    var url = 'https://wetter.n-tv.de/weather-api/v3/adaptive/header/' + id;
    $.ajax({
      dataType: 'json',
      url: url,
      success: function (res) {
        var data = res.content.city;
        // location update
        $weather.find('.weather__location').text(data['@n']);

        // actual temperature update
        $weather.find('.weather__now__temp').text(data.days.day[0]['@t']);

        $weather.each(function (index, widget) {
          var $widget = $(widget);
          // images update
          $widget.find('img').each(function (i, img) {
            $(img).attr('src', data.days.day[i]['@s'])
              .attr('alt', data.days.day[i].alt);
          });

          // next days temperatures update
          $widget.find('.weather__next__item')
            .each(function (i, elem) {
              $(elem).find('.max').text(data.days.day[i + 1]['@tmax']);
              $(elem).find('.min').text(data.days.day[i + 1]['@tmin']);
            });
        });
      }
    });
  };

  if (localStorage.getItem('weatherLocation')) {
    const location = JSON.parse(localStorage.getItem('weatherLocation'));
    renderWeatherData(location.id);
  }
};

// Testing value for dev (just copy / paste in the console):
// localStorage.setItem('weatherLocation', JSON.stringify({ name: '"München"', id: 18225562 }));
