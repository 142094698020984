'use strict';



// we have to handle sliders onResize
window.addEventListener('resizeReady',function(e){
	sliderHandlerOnResize();
});


var initDefaultSlider = function($slider) {

	   var $sliderContainer = $slider.parents( '.slider' );
	   var sliderId = $slider.data('slider_id');
	   
	   $sliderContainer.attr( 'data-slider_container_id', sliderId );
	   
	   
	   if( !$slider.hasClass( 'slick-initialized' ) ) {

		   // Slider in a content group must have one less slide shown
		   var slidesToRemove = $slider.parents( '.content' ).length ? 1 : 0;
		   
		   $slider.slick(
		     {
		       appendArrows: '[data-slider_container_id="' + sliderId + '"] .slider__nav',
		       appendDots  : '[data-slider_container_id="' + sliderId + '"] .slider__nav',
		       dots        : true,
		       infinite    : false,
		       mobileFirst : true,
		       nextArrow   : '<button class="slick--next icon icon__next" aria-label="Next"></button>',
					 prevArrow   : '<button class="slick--prev icon icon__prev" aria-label="Previous"></button>',
		       responsive  : [
		         {
		           breakpoint: 1,
		           settings  : {
		             arrows      : false,
		             centerMode  : true,
		             slidesToShow: 1
		           }
		         },
		         {
		           breakpoint: 767,
		           settings  : {
		             slidesToShow  : 3 - slidesToRemove,
		             slidesToScroll: 3 - slidesToRemove
		           }
		         },
		         {
		           breakpoint: 1279,
		           settings  : {
		             slidesToShow  : 4 - slidesToRemove,
		             slidesToScroll: 4 - slidesToRemove,
		             swipe         : false
		           }
		         }
		       ]
		     } );
	   
	   }
};



var initSidebarSlider = function($slider) {

	if( getBreakpoint()=='sm' && !$slider.hasClass( 'slick-initialized' ) ){
	   $slider.slick(
	     {
	    	    arrows     : false,
	    	    dots       : true,
	    	    infinite   : false,
	    	    mobileFirst: true
	     } );
	}
};



//Generates sidebar slider on SM
//Destroys sidebar slider on all other breakpoints
var sliderHandlerOnResize = function(){
	
	$( '.sidebar__group--slider .sidebar__group__wrapper' ).each(function(){
		
		var $this = $(this);
		
		if( getBreakpoint()=='sm' ){
			initSidebarSlider($this);
		}else{
	      if( $this.hasClass( 'slick-initialized' ) ) {
	          $this.slick( 'unslick' );
	      }
		}

	});
};